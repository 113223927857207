import React, { Component } from "react";
import settings from "../../settings.json";
import "./admin.scss";
import { Redirect, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.loginInfo = { email: "", psw: "" };
    this.axios = require("axios");
    this.state = {
      loading: false,
    };
  }

  loginForm(e, define) {
    if (define === "uname") {
      this.loginInfo.email = e.target.value;
    }
    if (define === "psw") {
      this.loginInfo.psw = e.target.value;
    }
  }

  login() {
    this.setState({ loading: true });
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/login.php",
        this.loginInfo,
        {}
      )
      .then((response) => {
        var login = [];
        if (response.data[3] === 'admin') {
          login = [{ id: response.data[0], role: response.data[3] }];
          sessionStorage.setItem("userLogin", JSON.stringify(login));
          window.location.reload();
        } else if (typeof response.data === "object") {
          response.data.forEach((value)=>{
            login.push({
              id: value.id,
              role: "user",
              cabinId: value.cabinId,
            });
          })
          sessionStorage.setItem("userLogin", JSON.stringify(login));
          window.location.reload();
        } else {
          this.setState({ loading: false });
          alert("Email or password is incorrect");
        }
      });
  }

  render() {
    if (
      this.props.location.pathname.includes("/admin") &&
      sessionStorage.getItem("userLogin")
    ) {
      return <Redirect push to="/admin/overview/" />;
    }
    return (
      <div id="admin">
        <div className="imgcontainer">
          <img
            src={
              this.settings.protocol +
              this.settings.domain +
              "/static/media/logod.png"
            }
            alt="Avatar"
            className="avatar"
          />
        </div>
        <div className="wrap-container">
          <div className="container">
            <label>
              <b>Email</b>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="uname"
              onChange={(e) => {
                this.loginForm(e, "uname");
              }}
              required
            />
            <label>
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="psw"
              onChange={(e) => {
                this.loginForm(e, "psw");
              }}
              required
            />
            <button
              onClick={() => {
                this.login();
              }}
              type="submit"
            >
              {this.state.loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
