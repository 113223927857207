import React, { Component } from "react";
import settings from "../../../settings.json";

import "./calendar.scss";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import { uuid } from "uuidv4";

class AdminCalendar extends Component {
  constructor(props) {
    super(props);
    console.log();
    this.groups = [];
    this.items = [
      {
        id: 1,
        group: 1,
        title: "item 1",
        start_time: moment(),
        end_time: moment().add(1, "days"),
      },
      {
        id: 2,
        group: 2,
        title: "item 2",
        start_time: moment().add(-0.5, "days"),
        end_time: moment().add(0.5, "days"),
      },
      {
        id: 3,
        group: 1,
        title: "item 3",
        start_time: moment().add(2, "days"),
        end_time: moment().add(3, "days"),
      },
    ];
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };
    this.userLogin = sessionStorage.getItem("userLogin")
      ? JSON.parse(sessionStorage.getItem("userLogin"))
      : false;
    this.state = {
      updateState: false,
      selectedDate: null,
    };
    this.axios = require("axios");
    this.bookedDates = [];
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.mapedCabins = [];
    this.guestsSelecedDate = [];
    this.cabinExists = true;
    this.cabins = [];
    this.calendar();
    // const xmlString =
    //   "<request<username>utsynid@gmail.com</username> <password>73tranni</password><hotel_id>2815909</hotel_id></request>";
    // const config = {
    //   headers: { "Content-Type": "text/xml" }
    // };
    // this.axios
    //   .post(
    //     "https://secure-supply-xml.booking.com/hotels/xml/reservationssummary",
    //     xmlString,
    //     config
    //   )
    //   .then(response => {})
    //   .catch(error => {
    //     console.log(error);
    //   });
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  calendar() {
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCabins.php"
      )
      .then((response) => {
        if (response.data !== "") {
          this.mapedCabins = response.data.map((cabin) => {
            return cabin;
          });

          this.mapedCabins.forEach((value) => {
            this.placeholderInvalidDates.push({
              cabin: value.cabinName,
              dates: [],
            });
            this.groups.push({ id: value.id, title: value.cabinName });
            this.cabins.push(value.cabinName);
          });
        }

        this.getBooked().then((value) => {
          this.bookedDates = value === "" ? [""] : value;
          this.bookedDates.forEach((value, i) => {
            if (
              value.paymentStatus === "Paid" ||
              value.paymentStatus === "Faktura"
            ) {
              var checkOut = new Date(value.checkOut.replace(",", ""));
              checkOut.setDate(checkOut.getDate() - 1);
              var dateArray = this.getDates(
                new Date(value.checkIn.replace(",", "")),
                checkOut
              );

              dateArray.forEach((dateArr) => {
                this.placeholderInvalidDates.map((info) => {
                  if (JSON.parse(value.cabins).includes(info.cabin)) {
                    if (!info.dates.includes(dateArr)) {
                      return info.dates.push(dateArr);
                    }
                  }
                  return null;
                });

                var includes = true;

                for (var p = 0; p < this.placeholderInvalidDates.length; p++) {
                  if (
                    this.placeholderInvalidDates[p].dates.includes(dateArr) &&
                    includes !== false
                  ) {
                    includes = true;
                  } else {
                    includes = false;
                  }
                }

                if (includes) {
                  dateArr = new Date(dateArr);
                  this.invalidDate.push(
                    dateArr.getFullYear() +
                      "/" +
                      (dateArr.getMonth() + 1) +
                      "/" +
                      dateArr.getDate()
                  );
                }
              });
            }
          });
          this.setState({ updateState: this.state.updateState ? false : true });
        });
      });
  }
  arraysEqual(a, b) {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  checkValidCabin(a) {
    var valueTrue = true;
    a.forEach((valueA) => {
      if (
        this.cabins.some((x) => {
          return x === valueA;
        })
      ) {
      } else {
        valueTrue = false;
      }
    });
    return valueTrue;
  }

  updateBookings(e, i, define) {
    var index = this.bookedDates.findIndex((x) => {
      return x.id === this.guestsSelecedDate[i].id;
    });
    if (define === "cabins") {
      if (
        this.checkValidCabin(
          e.currentTarget.innerText.replace(/\s/g, "").split(",")
        )
      ) {
        this.cabinExists = true;
      } else {
        this.cabinExists = false;
      }
      if (
        !this.arraysEqual(
          JSON.parse(this.bookedDates[index][define]),
          e.currentTarget.innerText.replace(/\s/g, "").split(",")
        )
      ) {
        this.guestsSelecedDate[i][define] = JSON.stringify(
          e.currentTarget.innerText.split(",").map(function (item) {
            return item.charAt(0) === " " ? item.substr(1) : item;
          })
        );
        this.guestsSelecedDate[i]["changed"] = true;
        this.bookedDates[index][define] = this.guestsSelecedDate[i][define];
        this.save();
      }
    } else if (define === "checkIn" || define === "checkOut") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      this.guestsSelecedDate[i]["changed"] = true;
      var date = new Date(e.startDate._d);

      this.guestsSelecedDate[i][define] =
        days[date.getDay()] +
        " " +
        date.getDate() +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear();
      this.bookedDates[index][define] = this.guestsSelecedDate[i][define];
    } else {
      this.guestsSelecedDate[i]["changed"] = true;
      this.guestsSelecedDate[i][define] = e.currentTarget.innerText;
      this.bookedDates[index][define] = this.guestsSelecedDate[i][define];
    }
    if (define !== "cabins") {
      this.save();
    }
  }

  getBooked() {
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getBooking.php"
      )
      .then(function (response) {
        // handle success
        var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
        if (userLogin.some((x) => x.role === "user")) {
          return response.data.filter((a) =>
            JSON.parse(a.cabinId).some((b) =>
              userLogin.some((c) => c.cabinId === b)
            )
          );
        } else {
          return response.data;
        }
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }
  deleteBooking(i) {
    var r = window.confirm(
      "Sure you wan't to delete item with payment id " +
        this.guestsSelecedDate[i].paymentId
    );
    if (r === true) {
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/deleteBooking.php",
          this.guestsSelecedDate[i]
        )
        .then((response) => {
          if (response.data) {
            this.bookedDates = this.bookedDates.filter(
              (x) => x.id !== this.guestsSelecedDate[i].id
            );
            this.guestsSelecedDate.splice(i, 1);
            this.setState({ bookings: this.state.updateState ? false : true });
            alert("Data was deleted successfully!");
          } else {
            alert("Deletion failed");
          }
        });
    }
  }
  sendPinInfo(i) {
    var r = window.confirm(
      "Sure you wan't to send pin info to " + this.guestsSelecedDate[i].email
    );
    if (r === true) {
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/getCabinSettings.php",
          { id: JSON.parse(this.guestsSelecedDate[i].cabinId) }
        )
        .then((response) => {
          this.guestsSelecedDate[i].info = response.data;
          this.guestsSelecedDate[i].companyName = this.settings.companyName;
          this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
          this.guestsSelecedDate[i].domain = this.settings.domain;
          this.guestsSelecedDate[i].protocol = this.settings.protocol;
          this.axios
            .post(
              "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/sendPin.php",
              this.guestsSelecedDate[i]
            )
            .then((response) => {
              if (response.data) {
                alert("Pin was successfully send!");
              } else {
                alert("Pin failed to send");
              }
            });
        });
    }
  }
  sendBooking(i) {
    var r = window.confirm(
      "Sure you wan't to send booking for this payment id " +
        this.guestsSelecedDate[i].paymentId
    );
    if (r === true) {
      this.guestsSelecedDate[i].companyName = this.settings.companyName;
      this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
      this.guestsSelecedDate[i].domain = this.settings.domain;
      this.guestsSelecedDate[i].protocol = this.settings.protocol;
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/sendBooking.php",
          this.guestsSelecedDate[i]
        )
        .then((response) => {
          if (response.data) {
            alert("Booking was successfully send!");
          } else {
            alert("Booking failed to send");
          }
        });
    }
  }

  refundBooking(i) {
    var r = window.confirm(
      "Sure you wan't to refund this payment id " +
        this.guestsSelecedDate[i].paymentId
    );
    if (r === true) {
      this.guestsSelecedDate[i].companyName = this.settings.companyName;
      this.guestsSelecedDate[i].companyEmail = this.settings.companyEmail;
      this.guestsSelecedDate[i].domain = this.settings.domain;
      this.guestsSelecedDate[i].protocol = this.settings.protocol;
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/quickpay/cancel.php",
          this.guestsSelecedDate[i]
        )
        .then((response) => {
          if (response.data) {
            this.guestsSelecedDate[i]["changed"] = true;
            this.guestsSelecedDate[i].paymentStatus = "Refunded";
            this.save(true);
          } else {
            alert("Refund failed");
          }
        });
    }
  }
  save(refund) {
    if (this.cabinExists) {
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/updateBookings.php",
          this.guestsSelecedDate
        )
        .then((response) => {
          if (response.data) {
            if (!refund) {
              this.calendar();
              alert("Data saved successfully!");
            } else {
              this.setState({
                bookings: this.state.updateState ? false : true,
              });
              alert("Refund was successfully!");
            }
          } else {
            alert("Saved failed");
          }
        });
    } else {
      alert("Changed cabin name, is not a valid cabin");
    }
  }
  resize(itemId, time, edge) {
    this.items = this.items.map((x) => {
      if (x.id === itemId) {
        x.end_time = moment(time);
      }
      return x;
    });
    this.setState({});
  }
  dragTime(itemId, time, newGroupOrder) {
    this.items = this.items.map((x) => {
      if (x.id === itemId) {
        x.start_time = moment(time);
        x.group = this.groups[newGroupOrder].id;
      }
      return x;
    });
    this.setState({});
  }
  newItem(groupId, time, e) {
    var newItem = {
      id: uuid(),
      group: groupId,
      title: "New item",
      start_time: moment(time),
      end_time: moment(time).add(3, "days"),
    };
    this.items.push(newItem);
    this.setState({});
  }
  render() {
    return (
      <content id="calendar">
        <div className="wrap-timeline">

        <Timeline
          sidebarContent={<div>Vehicle</div>}
          stackItems
          key={new Date().valueOf()}
          groups={this.groups}
          items={this.items}
          itemHeightRatio={0.75}
          lineHeight={40}
          showCursorLine
          onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
    
          }}
          // itemRenderer={console.log('test')}
          // onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas)=>{console.log(visibleTimeStart, visibleTimeEnd, updateScrollCanvas)}}
          // onTimeChange={(event)=>{console.log(event)}}
          onItemResize={(itemId, time, edge) => {
            this.resize(itemId, time, edge);
          }}
          onItemMove={(itemId, dragTime, newGroupOrder) => {
            this.dragTime(itemId, dragTime, newGroupOrder);
          }}
          onCanvasClick={(groupId, time, e) => {
            this.newItem(groupId, time, e);
          }}
          // onItemDrag={(event)=>{console.log(event)}}
          // onTimeChange={(event)=>{console.log(event)}}
          defaultTimeStart={moment().add(-4, "days")}
          defaultTimeEnd={moment().add(10, "days")}
        >
          <TimelineHeaders>
            <SidebarHeader>
              {({ getRootProps }) => {
                return <div className="sidebarHeader" {...getRootProps()}>Houses</div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
        </div>
      </content>
    );
    //   this.guestsSelecedDate = [];
    //   this.bookedDates.forEach((value, i) => {
    //     if (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura") {
    //       var checkOut = new Date(value.checkOut.replace(",", ""));
    //       checkOut.setDate(checkOut.getDate() - 1);
    //       var dateArray = this.getDates(
    //         new Date(value.checkIn.replace(",", "")),
    //         checkOut
    //       );
    //       dateArray.forEach((date) => {
    //         if (date.valueOf() === new Date(this.state.selectedDate).valueOf()) {
    //           this.guestsSelecedDate.push(value);
    //         }
    //       });
    //     }
    //   });
    //   var guests = null;
    //   if (this.guestsSelecedDate.length > 0) {
    //     guests = this.guestsSelecedDate.map((value, i) => {
    //       var checkOutDate = new Date(value.checkOut.replace(",", ""));
    //       var checkInDate = new Date(value.checkIn.replace(",", ""));

    //       var checkIn =
    //         checkInDate.getDate() +
    //         "/" +
    //         (checkInDate.getMonth() + 1) +
    //         "/" +
    //         checkInDate.getFullYear();
    //       var checkOut =
    //         checkOutDate.getDate() +
    //         "/" +
    //         (checkOutDate.getMonth() + 1) +
    //         "/" +
    //         checkOutDate.getFullYear();
    //       var cabins = JSON.parse(value.cabins).map((element, i) => {
    //         var comma = "";
    //         if (JSON.parse(value.cabins).length > i + 1) {
    //           comma = ", ";
    //         }
    //         return element + comma;
    //       });
    //       return (
    //         <tr key={i}>
    //           <td>{value.reg_date}</td>
    //           <td>{value.paymentId}</td>
    //           <td>
    //             <div
    //               suppressContentEditableWarning={true}
    //               contentEditable="true"
    //               onBlur={(e) => {
    //                 this.updateBookings(e, i, "cabins");
    //               }}
    //             >
    //               {" "}
    //               {cabins}
    //             </div>
    //           </td>
    //           <td>{checkIn}</td>
    //           <td>{checkOut}</td>
    //           <td>
    //             <div
    //             // suppressContentEditableWarning={true}
    //             // contentEditable="true"
    //             // onBlur={e => {
    //             //   this.updateBookings(e, i, "guests");
    //             // }}
    //             >
    //               {" "}
    //               {value.guests}
    //             </div>
    //           </td>
    //           <td>
    //             <div>{value.firstName}</div>
    //           </td>
    //           <td>
    //             <div>{value.lastName}</div>
    //           </td>
    //           <td>
    //             <div>{value.email}</div>
    //           </td>

    //           <td>
    //             <div>{value.costs}</div>
    //           </td>
    //           <td>
    //             <div>{value.paymentStatus}</div>
    //           </td>

    //           <td>
    //             <div>{value.comment}</div>
    //           </td>
    //           <td>
    //             <div
    //               className="action-btn"
    //               onClick={() => {
    //                 this.sendBooking(i);
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faPaperPlane} />
    //               <p>Send booking</p>
    //             </div>

    //             {/* <div
    //               className="action-btn"
    //               onClick={() => {
    //                 this.refundBooking(i);
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faCreditCard} />
    //               <p>Refund</p>
    //             </div> */}
    //             <div
    //               className="action-btn"
    //               onClick={() => {
    //                 this.deleteBooking(i);
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faTrashAlt} />
    //               <p>Delete</p>
    //             </div>
    //             {/* <div
    //               className="action-btn"
    //               onClick={() => {
    //                 this.sendPinInfo(i);
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faUnlockAlt} />
    //               <p>Send pin</p>
    //             </div> */}
    //           </td>
    //         </tr>
    //       );
    //     });
    //   }

    //   return (
    //     <content id="calendar">
    //       <div className="wrap-content">
    //         <div className="front-page-content">
    //           <div className="headline">
    //             <h3>Calendar Cabins</h3>
    //           </div>
    //           <div className="calendar">
    //             <Calendar
    //               onClickDay={(e) => {
    //                 this.setState({ selectedDate: e });
    //               }}
    //               tileContent={(e) => {
    //                 const d = new Date(e.date);
    //                 var countSoldout = 0;

    //                 this.placeholderInvalidDates.forEach((value) => {
    //                   value.dates.forEach((date) => {
    //                     if (d.valueOf() === date.valueOf()) {
    //                       countSoldout += 1;
    //                     }
    //                   });
    //                 });
    //                 if (
    //                   this.invalidDate.includes(
    //                     d.getFullYear() +
    //                       "/" +
    //                       (d.getMonth() + 1) +
    //                       "/" +
    //                       d.getDate()
    //                   )
    //                 ) {
    //                   return (
    //                     <div className="small-calendar-text sold-out">
    //                       <MobileView>full</MobileView>
    //                       <BrowserView>full booked</BrowserView>
    //                     </div>
    //                   );
    //                 } else {
    //                   if (countSoldout > 0) {
    //                     return (
    //                       <div className="small-calendar-text some-booked">
    //                         <MobileView>{countSoldout}</MobileView>
    //                         <BrowserView>
    //                           {countSoldout}&nbsp;cabins&nbsp;booked
    //                         </BrowserView>
    //                       </div>
    //                     );
    //                   } else {
    //                     return (
    //                       <div className="small-calendar-text">
    //                         <MobileView>{countSoldout}</MobileView>
    //                         <BrowserView>
    //                           {countSoldout}&nbsp;cabin&nbsp;booked
    //                         </BrowserView>
    //                       </div>
    //                     );
    //                   }
    //                 }
    //               }}
    //             />
    //           </div>
    //           <table>
    //             <tbody>
    //               <tr>
    //                 <th>Register date</th>
    //                 <th>Payment id</th>
    //                 <th>Cabins</th>
    //                 <th>Check in</th>
    //                 <th>Check out</th>
    //                 <th>Guests</th>
    //                 <th>First name</th>
    //                 <th>Last name</th>
    //                 <th>Email</th>

    //                 <th>Costs</th>
    //                 <th>Payment status</th>

    //                 <th>Comment</th>
    //                 <th>Action</th>
    //               </tr>
    //               {guests}
    //             </tbody>
    //           </table>
    //         </div>
    //       </div>
    //     </content>
    //   );
  }
}

export default AdminCalendar;
