import React, { Component } from "react";
import settings from "../../settings.json";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faUserFriends,
  faCheck,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-bootstrap-daterangepicker";
import "./campingAvailability.scss";
import ReactHtmlParser from "react-html-parser";
import translate from "../../translate.json";

class CampingAvailability extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.queryString = require("query-string");
    this.urlParameters = this.queryString.parse(this.props.location.search);
    this.bookedDates = [];
    this.invalidDate = [];
    this.invalidCabinDate = [];
    this.camping = [];
    this.util = require("util");
    this.clientInfo = sessionStorage.getItem("clientInfo")
      ? JSON.parse(sessionStorage.getItem("clientInfo"))
      : { startDate: "", guests: 1 };
    var startDate = new Date(this.clientInfo ? this.clientInfo.startDate : "");
    this.startRange = new Date();
    this.endRange = new Date();
    this.state = {
      availability: [],
      dateRange: false,
      fixedBookedBtn: React.createRef(),
      fixedTable: React.createRef(),
      bookBtn: false,
      table: false,
      camping: [],
      startDate: isNaN(startDate.getTime())
        ? ""
        : startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear(),
      startDateFormat: new Date(
        this.clientInfo.startDate !== ""
          ? this.clientInfo.startDate
          : new Date()
      ),
      endDate: isNaN(startDate.getTime())
        ? ""
        : startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear(),
      endDateFormat: new Date(
        this.clientInfo.startDate !== ""
          ? this.clientInfo.startDate
          : new Date()
      ),
      guests: this.clientInfo ? JSON.parse(this.clientInfo.guests) : 2,
      amountCamping: 0,
      checkAvailability: false,
      startDateNotSelected: true,
      readyForBooking: false,
      minDate: isNaN(startDate.getTime()) ? new Date("1200/01/01") : startDate,
    };

    this.axios = require("axios");

    this.getCamping();
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  handleScroll = () => {
    var widthPerc = (window.innerWidth / 100) * 37.2;
    if (this.state.fixedBookedBtn.current !== null) {
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc <=
        window.pageYOffset &&
        this.state.bookBtn === false
      ) {
        this.setState({ bookBtn: true });
      }
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc >=
        window.pageYOffset &&
        this.state.bookBtn === true
      ) {
        this.setState({ bookBtn: false });
      }
    }
    var widthPer = (window.innerWidth / 100) * 1.8;
    if (this.state.fixedTable.current !== null) {
      if (
        this.state.fixedTable.current.offsetTop - widthPer <=
        window.pageYOffset &&
        this.state.table === false
      ) {
        this.setState({ table: true });
      }
      if (
        this.state.fixedTable.current.offsetTop - widthPer >=
        window.pageYOffset &&
        this.state.table === true
      ) {
        this.setState({ table: false });
      }
    }
  };
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    end = new Date(end);

    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  getCamping() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCamping.php"
      )
      .then((response) => {
        const mapedCamping = response.data.map((campingOffer, i) => {
          const img =
            "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/uploads/medium_" +
            campingOffer.mainImage;
          this.camping.push({
            name: campingOffer.campingName,
            text: campingOffer.textShort,
            id: campingOffer.id,
            available: true,
            selected: false,
            img: img,
            weekDays: JSON.parse(campingOffer.weekDays),
            maxGuests: campingOffer.maxGuests,
            dateRange: campingOffer.dateRange,
            price: campingOffer.price,
          });

          return campingOffer;
        });
        var placeholderInvalidDates = [];
        mapedCamping.forEach((value) => {
          placeholderInvalidDates.push({
            campingName: value.campingName,
            dates: [],
          });
        });

        this.setState({ mapedCamping }, () => {
          this.getBooked().then((value) => {
            if (value.length > 0) {
              this.bookedDates = value === "" ? [""] : value;
              this.bookedDates.forEach((value, i) => {
                if (value.paymentStatus === "Faktura") {
                }
              });
            }
          });
        });
        this.checkAvailability();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBooked() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBookedCamping.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }

  handleStartDate = (event, picker) => {
    this.setState({ readyForBooking: false, infoChange: true });
    var startDate = new Date(picker.startDate._d);
    this.setState({ startDateNotSelected: true });
    if (this.state.endDate === "") {
      this.setState({
        endDate:
          startDate.getDate() +
          "." +
          (startDate.getMonth() + 1) +
          "." +
          startDate.getFullYear(),
        endDateFormat: startDate,
      });
    }
    this.setState({
      startDate:
        startDate.getDate() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getFullYear(),
      startDateFormat: startDate,
      minDate: startDate,
    });
    this.clientInfo.startDate =
      startDate.getFullYear() +
      "/" +
      (startDate.getMonth() + 1) +
      "/" +
      startDate.getDate();
    sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
  };
  handleEndDate = (event, picker) => {
    this.setState({ readyForBooking: false, infoChange: true });
    var endDate = new Date(picker.endDate._d);
    this.setState({ endDateNotSelected: true });
    this.setState({
      endDate:
        endDate.getDate() +
        "." +
        (endDate.getMonth() + 1) +
        "." +
        endDate.getFullYear(),
      endDateFormat: endDate,
    });
    this.clientInfo.endDate =
      endDate.getFullYear() +
      "/" +
      (endDate.getMonth() + 1) +
      "/" +
      endDate.getDate();
    sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
  };

  addPeople = (amount) => {
    var maxGuests = 0;
    this.state.mapedCamping.forEach((value) => {
      maxGuests = maxGuests < value.maxGuests ? value.maxGuests : maxGuests;
    });
    this.setState({ readyForBooking: false, infoChange: true });
    var camping = this.state.camping.map((campingOffer) => {
      campingOffer.selected = false;
      return campingOffer;
    });
    this.setState({ camping });
    if (
      this.state.guests + amount <= this.camping.length * maxGuests &&
      this.state.guests + amount >= 1
    ) {
      this.setState({ guests: this.state.guests + amount });
      this.clientInfo.guests = this.state.guests + amount;
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
      for (var i = this.camping.length; i >= 1; i--) {
        if (amount + this.state.guests <= i * maxGuests) {
          this.setState({ amountCamping: i });
        }
      }
    }
  };

  checkAvailability = () => {
    var campingArray = [];
    var campingAvailability = [];
    var endDate =
      this.state.endDate === ""
        ? this.state.startDateFormat
        : this.state.endDateFormat;
    this.setState({ availability: [] });
    this.camping.forEach((campingOffer) => {
      const weekdayIndex = campingOffer.weekDays.findIndex(
        (x) =>
          JSON.parse(x.day) === new Date(this.state.startDateFormat).getDay()
      );

      campingArray.push({
        id: campingOffer.id,
        name: campingOffer.name,
        available: campingOffer.weekDays[weekdayIndex].checked,
        selected: false,
        price: campingOffer.price,
        maxGuests: JSON.parse(campingOffer.maxGuests),
        weekDays: campingOffer.weekDays,
        left: JSON.parse(campingOffer.maxGuests),
      });
      this.getDates(this.state.startDateFormat, endDate).forEach((element) => {
        element = new Date(element);
        var friendlyDate =
          element.getDate() +
          "." +
          (element.getMonth() + 1) +
          "." +
          element.getFullYear();
        campingAvailability.push({
          campingId: campingOffer.id,
          date: friendlyDate,
          dateValueOf: element.valueOf(),
          name: campingOffer.name,
          totalGuests: 0,
        });
      });
    });

    this.getBooked().then((value) => {
      this.bookedDates = value === "" ? [""] : value;
      this.bookedDates.forEach((value, i) => {
        if (
          new Date(value.date).valueOf() >=
          new Date(this.state.startDateFormat).valueOf() &&
          new Date(value.date).valueOf() <= new Date(endDate).valueOf() &&
          (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura")
        ) {
          const index = campingAvailability.findIndex(
            (x) =>
              x.campingId === value.campingId &&
              x.dateValueOf === new Date(value.date).valueOf()
          );

          if (index > -1) {
            campingAvailability[index].totalGuests =
              campingAvailability[index].totalGuests + JSON.parse(value.guests);
          }
        }
      });

      var availability = [];
      campingAvailability.forEach((value) => {
        const index = campingArray.findIndex((x) => x.id === value.campingId);
        if (index !== -1) {
          if (
            value.totalGuests + JSON.parse(this.state.guests) <=
            campingArray[index].maxGuests &&
            campingArray[index].available === true
          ) {
            campingArray[index].available = true;
          } else {
            campingArray[index].available = false;
          }
          availability.push({
            campingId: value.campingId,
            date: value.date,
            left: campingArray[index].maxGuests - value.totalGuests,
          });
        }
      });
      this.setState({
        camping: campingArray,
        infoChange: false,
        availability,
      });
    });

    if (
      this.state.startDate === "" ||
      this.state.startDate === null ||
      this.state.startDate === undefined
    ) {
      campingArray.map((value) => {
        return (value.available = false);
      });
      this.setState({ startDateNotSelected: false, camping: campingArray });
    }
  };

  selectCamping(index) {
    var camping = this.state.camping.map((value, i) => {
      if (index === i) {
        value.selected
          ? (value.selected = false)
          : this.state.readyForBooking === false
            ? (value.selected = true)
            : (value.selected = false);
      }
      return value;
    });
    this.setState({ camping }, () => {
      var state = {};
      for (var key in this.state) {
        if (key !== "fixedBookedBtn") {
          if (key !== "fixedTable") {
            state[key] = this.state[key];
          }
        }
      }
      sessionStorage.setItem("currentState", JSON.stringify(state));
    });

    var selectedGuestsAmount = 0;
    this.camping.forEach((value, i) => {
      if (this.state.camping[i].selected) {
        selectedGuestsAmount =
          selectedGuestsAmount + JSON.parse(value.maxGuests);
      }
    });
    if (selectedGuestsAmount !== 0) {
      this.setState({ missingCamping: true });
    } else {
      this.setState({ missingCamping: false });
    }
    if (this.state.guests <= selectedGuestsAmount) {
      this.setState({ readyForBooking: true });
    } else {
      this.setState({ readyForBooking: false });
    }
  }
  scrollToFu = () => {
    var widthPerc = window.innerWidth;

    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };
  setDateRange(value) {
    if (value === false) {
      this.setState(
        {
          endDate: this.state.startDate,
          endDateFormat: this.state.startDateFormat,
          dateRange: value,
        },
        () => {
          this.checkAvailability();
        }
      );
      this.clientInfo.endDate = this.state.startDate;
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
    } else {
      this.setState({ dateRange: value });
    }
  }

  render() {
    var camping = this.camping.map((campingOffer, i) => {
      var startRange = new Date(campingOffer.dateRange.split(' - ')[0])
      startRange.setHours(0)
      this.startRange = this.startRange.valueOf() < startRange ? startRange : this.startRange; 
      var endRange = new Date(campingOffer.dateRange.split(' - ')[1])
      endRange.setHours(0)
      this.endRange = this.endRange.valueOf() < endRange ? endRange : this.endRange; 
      if (this.state.startDate) {
        var availableBtn = this.state.camping.map((value, i) => {
          return value.id === campingOffer.id ? (
            <div key={i} className="select-cabin">
              <h2 className="cabin-price">DKK {campingOffer.price},-</h2>
              <div className="p-div">
                {value.weekDays.map((info) => {
                  let day = "";
                  if (info.day === "0") {
                    day = "Sun";
                  }
                  if (info.day === "1") {
                    day = "Mon";
                  }
                  if (info.day === "2") {
                    day = "Tue";
                  }
                  if (info.day === "3") {
                    day = "Wed";
                  }
                  if (info.day === "4") {
                    day = "Thu";
                  }
                  if (info.day === "5") {
                    day = "Fri";
                  }
                  if (info.day === "6") {
                    day = "Sat";
                  }
                  return (
                    <div
                      key={day}
                      className={info.checked ? "day" : "day notAvailable"}
                    >
                      {day}
                    </div>
                  );
                })}
              </div>
              <div
                onClick={() => {
                  this.state.camping[i].available
                    ? this.selectCamping(i)
                    : this.selectCamping(-1);
                }}
                className={
                  this.state.camping[i].available
                    ? this.state.camping[i].selected
                      ? "all-btn selected-btn"
                      : "all-btn"
                    : "all-btn disable-btn"
                }
              >
                <div>
                  {this.state.camping[i].selected ? (
                    <FontAwesomeIcon
                      className={
                        this.state.startDateNotSelected ? "" : "missing"
                      }
                      icon={faCheck}
                    />
                  ) : (
                    ""
                  )}{" "}
                  {this.state.camping[i].selected
                    ? translate.deselect[settings.lang]
                    : translate.select[settings.lang]}
                </div>
              </div>
            </div>
          ) : null;
        });


        var availablity = this.state.availability.map((element, index) => {
          if (campingOffer.id === element.campingId) {
            return (
              <div key={index}>
                <div>{element.date}</div>{" "}
                <div>
                  {element.left} {translate.left[settings.lang]}
                </div>
              </div>
            );
          } else {
            return null;
          }
        });
        var cabinInfo = <div className="cabin-info">
          <h2>{campingOffer.name}</h2>
          {ReactHtmlParser(campingOffer.text)}
          <div className="availability">
            <strong>{translate.availability[settings.lang]}: </strong>
            {availablity}
          </div>
        </div>
   
     

        if (this.state.startDateFormat.valueOf() < startRange.valueOf() || this.state.startDateFormat.valueOf() > endRange.valueOf()) {
          availableBtn = <div className="select-cabin">
            <h2>Season</h2>
            <table>
              <tbody>
                <tr>
                  <th>From</th>
                  <th>To</th>
                </tr>
                <tr>
                  <td>{startRange.getDate() +
                    "." +
                    (startRange.getMonth() + 1) +
                    "." +
                    startRange.getFullYear()}</td>
                  <td>{endRange.getDate() +
                    "." +
                    (endRange.getMonth() + 1) +
                    "." +
                    endRange.getFullYear()}</td>
                </tr>
              </tbody>
            </table>
          </div>

          cabinInfo = <div className="cabin-info">
            <h2>{campingOffer.name}</h2>
            {ReactHtmlParser(campingOffer.text)}
          </div>
        }
        return (
          <div key={i} className="cabin">
            <Link
              className="cabin-link"
              key={i}
              to={"camping?id=" + campingOffer.id}
            >
              <div className="cabin-image">
                <img alt="" src={campingOffer.img} />
              </div>
              {cabinInfo}
            </Link>
            {availableBtn}
          </div>
        );
      } else {
        return null;
      }
    });

    return (
      <content id="availability">
        <div className="headline">
          <h1>{translate.beginAd[settings.lang]} …</h1>
        </div>
        <div className="date-type">
          <div
            onClick={() => {
              this.setDateRange(false);
            }}
            className={
              this.state.dateRange
                ? "date-button"
                : "date-button date-button-selected"
            }
          >
            <div>
              {translate.single[settings.lang]} {translate.date[settings.lang]}
            </div>
          </div>
          <div
            onClick={() => {
              this.setDateRange(true);
            }}
            className={
              !this.state.dateRange
                ? "date-button"
                : "date-button date-button-selected"
            }
          >
            <div>
              {translate.date[settings.lang]} {""}{" "}
              {translate.range[settings.lang]}
            </div>
          </div>
        </div>
        <div
          ref={this.state.fixedTable}
          className={this.state.table ? "table" : "table"}
        >
          <div className={this.state.table ? "table-fixed" : ""}>
            <div className="available-shadow"></div>

            <div className="input-headline">
              <div>{translate.date[settings.lang]}</div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="input">
              <div className="th">
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
                <FontAwesomeIcon
                  className={this.state.startDateNotSelected ? "" : "missing"}
                  icon={faCalendarAlt}
                />

                <DatePicker
                  className="startDate"
                  singleDatePicker={true}
                  autoApply={true}
                  startDate={this.startRange}
                  minDate={moment(this.startRange)}
                  maxDate={moment(this.endRange)}
                  locale={{ format: "D.M.YYYY" }}
                  disabledDates={["02/25/2015"]}
                  onApply={this.handleStartDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.startDate.length > 0
                        ? this.state.startDate
                        : ""
                    }
                  />
                </DatePicker>
              </div>
              <div className={this.state.dateRange ? "th" : "th disabled"}>
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
                <FontAwesomeIcon
                  className={this.state.endDateNotSelected ? "" : "missing"}
                  icon={faCalendarAlt}
                />
                <DatePicker
                  autoApply={true}
                  singleDatePicker={true}
                  locale={{ format: "D.M.YYYY" }}
                  minDate={moment(this.state.minDate)}
                  maxDate={moment(this.endRange)}
                  startDate={this.state.startDate ? this.state.startDate : this.startRange}
                  onApply={this.handleEndDate}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.startDateFormat.valueOf() > this.state.endDateFormat.valueOf() ? this.state.startDate :
                        this.state.endDate.length > 0 ? this.state.endDate : ""
                    }
                  />
                </DatePicker>
              </div>
              <div className="th">
                <FontAwesomeIcon icon={faUserFriends} />
                <div
                  className="add-people"
                  onClick={() => {
                    this.addPeople(+1);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div
                  className="remove-people"
                  onClick={() => {
                    this.addPeople(-1);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </div>
                <div readOnly className="select-people select" type="text">
                  <div className="amount-people">
                    {this.state.guests === 1
                      ? this.state.guests + " " + translate.spot[settings.lang]
                      : this.state.guests +
                      " " +
                      translate.spots[settings.lang]}
                  </div>
                </div>
              </div>
              <div ref={this.state.fixedBookedBtn} className="th">
                <div
                  className={
                    this.state.bookBtn && this.state.checkAvailability
                      ? "book-btn"
                      : ""
                  }
                >
                  {this.state.checkAvailability ? (
                    <div className="btn-shadow"></div>
                  ) : (
                    ""
                  )}
                  {this.state.readyForBooking ? (
                    <Link
                      onClick={() => {
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 100);
                      }}
                      to={"bookingdetails"}
                    >
                      <div className="read-book-btn all-btn">
                        <div>{translate.book[settings.lang]}</div>
                      </div>
                    </Link>
                  ) : this.state.missingCabin ? (
                    <div className={"all-btn disable-btn"}>
                      <div>{translate.book[settings.lang]}</div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.checkAvailability();
                      }}
                      className={"all-btn check-avail-btn"}
                    >
                      <div>{translate.checkAvail[settings.lang]}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cabin-list">
          <div className="wrap-cabin-list">
            {this.state.infoChange ? "" : camping}
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(CampingAvailability);
