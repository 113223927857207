import React, { Component } from "react";
import settings from "../../settings.json";
import payment from "../../assets/dk-logobar-web-dark-bkg.png";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ReactHtmlParser from "react-html-parser";
import translate from "../../translate.json";
import "./footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.state = {
      customersWord: { comment: "" },
      companyEmail: "",
      companyName: "",
      companyAddress: "",
      companyCity: "",
      companyVat: "",
      companyPhone: "",
      companyFacebook: "",
      companyInstagram: "",
      companyBooking: "",
      companyAirbnb: "",
    };
    this.axios = require("axios");
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            companyEmail: response.data[0].email,
            companyName: response.data[0].name,
            companyAddress: response.data[0].adress,
            companyCity: response.data[0].city,
            companyPhone: response.data[0].phone,
            companyVat: response.data[0].vatnr,
            companyFacebook: response.data[0].facebook,
            companyInstagram: response.data[0].instagram,
            companyBooking: response.data[0].booking_com,
            companyAirbnb: response.data[0].airbnb_com,
          });
        }
      });
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getFrontPage.php"
      )
      .then((response) => {
        this.setState({
          customersWord: JSON.parse(response.data.customersWord),
        });
        return response.data;
      })
      .catch((error) => {
        // handle error
        return error;
      });
  }

  render() {
    return (
      <footer
        className={
          this.props.location.pathname.includes("/admin") ? "hide-footer" : ""
        }
      >
        <div className="testimonials">
          <div className="wrap-testimonials-text">
            <h1>{ReactHtmlParser(this.state.customersWord.comment)}</h1>
            <div className="testimonials-line"></div>
            <h2>{this.state.customersWord.customer}</h2>
          </div>
        </div>
        <table>
          <tbody>
            <tr className="wrap-contact-info">
              <td className="contact-info">
                <p className="headline-info">
                  {translate.contact[settings.lang]}
                </p>
                <p>
                  {this.state.companyName}
                  <br />
                  {this.state.companyAddress}
                  <br />
                  {this.state.companyCity}
                </p>
              </td>
              <td className="contact-info">
                <p className="headline-info empty">
                  <br />
                </p>
                <p>
                  {this.state.companyPhone}
                  <br />
                  {this.state.companyEmail}
                  <br />
                  V-tal: {this.state.companyVat}
                </p>
              </td>
              <td className="follow-us">
                <p className="headline-info">
                  {translate.followus[settings.lang]}
                </p>
                <div className="social">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.companyFacebook}
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.companyInstagram}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </td>
              <td className="terms">
                <p className="headline-info">
                  {translate.terms[settings.lang]}
                </p>
                <div>
                  <p className="terms">
                    <Link to={"policy"}>{translate.policy[settings.lang]}</Link>
                  </p>
                </div>
                <p className="terms">
                  <Link to={"terms"}>{translate.readTerms[settings.lang]}</Link>
                </p>
                <div>
                  <img src={payment} alt="payment" />
                </div>
              </td>
              {/* <td className="booking">
                <p className="headline-info">OTHER BOOKINGS</p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.companyBooking}
                  >
                    www.booking.com
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.state.companyAirbnb}
                  >
                    www.airbnb.com
                  </a>
                </p>
              </td> */}
            </tr>
          </tbody>
        </table>
        <div className="copy-right">
          <p>© {' '}
            {translate.copyright[settings.lang]} {new Date().getFullYear()} -{" "}
            {this.state.companyName}
          </p>
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);
