export const background = require('./background.png')
export const cabin = require('./cabin.jpg')
export const comment = require('./comment.png')
export const wildlife = require('./wildlife.jpg')
export const logo = require('./logo.png')
export const image1 = require('./1.png')
export const image2 = require('./2.png')
export const image3 = require('./3.png')
export const image4 = require('./4.png')
export const image5 = require('./5.png')
export const image6 = require('./6.png')
export const image7 = require('./7.png')
export const image8 = require('./8.png')
export const image9 = require('./9.png')
export const image10 = require('./10.png')
export const image11 = require('./11.png')
export const image12 = require('./12.png')
export const image13 = require('./13.png')
export const image14 = require('./14.png')
