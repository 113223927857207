import React, { Component } from "react";
import settings from "../../../settings.json";
import "./bookedCamping.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-bootstrap-daterangepicker";
import {
  faTrashAlt,
  faCreditCard,
  faPaperPlane,
  faFilter,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSearch,
  faSpinner,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";

class BookedCamping extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };
    this.axios = require("axios");
    this.getBooked();
    this.bookings = [];
    this.placeHolderBookings = [];
    this.camping = [];
    this.weekDays = [];
    this.getCamping();
    this.state = {
      radio: "fullAmount",
      refundInfo: {
        id: "",
        open: false,
        paymentId: "",
        fullAmount: "",
      },
      refundAmount: "",
      showMore: 10,
      define: "registerDate",
      registerDate: {
        type: "desc",
      },
      paymentId: {
        type: "desc",
      },
      camping: {
        type: "desc",
      },
      date: {
        type: "desc",
      },
      guests: {
        type: "desc",
      },
      firstName: {
        type: "desc",
      },
      lastName: {
        type: "desc",
      },
      email: {
        type: "desc",
      },
      costs: {
        type: "desc",
      },
      paymentStatus: {
        type: "desc",
      },
      comment: {
        type: "desc",
      },
      bookings: false,
      loaded: false,
    };
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.dateArray = [];
    this.getSettings();
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        if(response.data.length > 0){
          this.setState({
            companyEmail: response.data[0].email,
            companyName: response.data[0].name,
            companyAddress: response.data[0].adress,
            companyCity: response.data[0].city,
            companyPhone: response.data[0].phone,
            companyVat: response.data[0].vatnr,
            companyBooking: response.data[0].booking_com,
            companyAirbnb: response.data[0].airbnb_com,
          });
        }
      });
  }
  getBooked() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getBookedCamping.php"
      )
      .then((response) => {
        this.bookings = response.data;
        this.placeHolderBookings = response.data;
        this.setState({
          bookings: this.state.bookings ? false : true,
          loaded: true,
        });
        this.getAvailablity();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  sortByProperty(propertyName, ascDesc) {
    return this.bookings.sort((a, b) => {
      if (!isNaN(a[propertyName]) && a[propertyName] !== "") {
        a[propertyName] = JSON.parse(a[propertyName]);
        a[propertyName] = JSON.parse(a[propertyName]);
      }
      if (ascDesc === "asc") {
        if (a[propertyName] < b[propertyName]) {
          return -1;
        }
        if (a[propertyName] > b[propertyName]) {
          return 1;
        }
      } else {
        if (a[propertyName] > b[propertyName]) {
          return -1;
        }
        if (a[propertyName] < b[propertyName]) {
          return 1;
        }
      }
      return 0;
    });
  }

  sort(define) {
    if (define === "registerDate") {
      this.sortByProperty(
        "reg_date",
        this.state.registerDate.type === "desc" ? "asc" : "desc"
      );
      this.setState({
        registerDate: {
          type: this.state.registerDate.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "paymentId") {
      this.sortByProperty(
        "paymentId",
        this.state.paymentId.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        paymentId: {
          type: this.state.paymentId.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "camping") {
      this.sortByProperty(
        "camping",
        this.state.camping.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        camping: {
          type: this.state.camping.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }

    if (define === "date") {
      this.sortByProperty(
        "date",
        this.state.date.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        date: {
          type: this.state.date.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "guests") {
      this.sortByProperty(
        "guests",
        this.state.guests.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        guests: {
          type: this.state.guests.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "firstName") {
      this.sortByProperty(
        "firstName",
        this.state.firstName.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        firstName: {
          type: this.state.firstName.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "lastName") {
      this.sortByProperty(
        "lastName",
        this.state.lastName.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        lastName: {
          type: this.state.lastName.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "email") {
      this.sortByProperty(
        "email",
        this.state.email.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        email: {
          type: this.state.email.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "costs") {
      this.sortByProperty(
        "costs",
        this.state.costs.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        costs: {
          type: this.state.costs.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "paymentStatus") {
      this.sortByProperty(
        "paymentStatus",
        this.state.paymentStatus.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        paymentStatus: {
          type: this.state.paymentStatus.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "comment") {
      this.sortByProperty(
        "comment",
        this.state.comment.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        comment: {
          type: this.state.comment.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
  }

  updateBookings(e, i, define) {
    if (define === "camping") {
      this.guestsSelecedDate[i][define] = JSON.stringify(
        e.currentTarget.innerText.split(",").map(function (item) {
          return item.charAt(0) === " " ? item.substr(1) : item;
        })
      );
      this.bookings[i]["changed"] = true;
    } else if (define === "date") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      this.bookings[i]["changed"] = true;
      var date = new Date(e.startDate._d);

      this.bookings[i][define] =
        days[date.getDay()] +
        " " +
        date.getDate() +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear();
      this.setState({ bookings: this.state.bookings ? false : true });
    } else {
      this.bookings[i]["changed"] = true;
      this.bookings[i][define] = e.currentTarget.innerText;
    }
  }
  deleteBooking(i) {
    var r = window.confirm(
      "Sure you wan't to delete item with payment id " +
        this.bookings[i].paymentId
    );

    if (r === true) {
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/deleteBookedCamping.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            this.bookings.splice(i, 1);
            this.setState({ bookings: this.state.bookings ? false : true });
            alert("Data was deleted successfully!");
          } else {
            alert("Deletion failed");
          }
        });
    }
  }
  sendBooking(i) {
    var r = window.confirm(
      "Sure you wan't to send booking for this payment id " +
        this.bookings[i].paymentId
    );
    if (r === true) {
      this.bookings[i].companyEmail = this.settings.companyEmail;
      this.bookings[i].companyName = this.state.companyName;
      this.bookings[i].companyAddress = this.state.companyAddress;
      this.bookings[i].companyCity = this.state.companyCity;
      this.bookings[i].companyVat = this.state.companyVat;
      this.bookings[i].companyPhone = this.state.companyPhone;
      this.bookings[i].companyFacebook = this.state.companyFacebook;
      this.bookings[i].companyInstagram = this.state.companyInstagram;
      this.bookings[i].companyBooking = this.state.companyBooking;
      this.bookings[i].companyAirbnb = this.state.companyAirbnb;
      this.bookings[i].protocol = this.settings.protocol;
      this.bookings[i].domain = this.settings.domain;
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/sendBooking.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            alert("Booking was successfully send!");
          } else {
            alert("Booking failed to send");
          }
        });
    }
  }

  refundBooking() {
    const i = this.state.refundInfo.id;
    var r = window.confirm(
      "Sure you wan't to refund this payment id " + this.bookings[i].paymentId
    );
    if (r === true) {
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/quickpay/cancel.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            this.bookings[i]["changed"] = true;
            if (this.bookings[i].costs === this.bookings[i].refundAmount) {
              this.bookings[i].paymentStatus = "Refunded";
            } 
            this.bookings[i].costs =
              this.bookings[i].costs - this.bookings[i].refundAmount;
            this.axios
              .post(
                "" +
                  this.settings.protocol +
                  this.settings.domain +
                  "/static/php/sendRefund.php",
                this.bookings[i]
              )
              .then((response) => {
                this.setState({ refundInfo: { open: false } });
                this.save(true);
              });
          } else {
            alert("Refund failed");
          }
        });
    }
  }

  save(refund) {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateBookedCamping.php",
        this.bookings
      )
      .then((response) => {
        if (response.data) {
          if (!refund) {
            alert("Data saved successfully!");
          } else {
            this.setState({ bookings: this.state.bookings ? false : true });
            alert("Refund was successfully!");
          }
        } else {
          alert("Saved failed");
        }
      });
  }
  search(e) {
    var searchBooking = this.placeHolderBookings;

    searchBooking = searchBooking
      .filter((element) => {
        return Object.keys(element).some((k) => {
          return element[k]
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
      })
      .map((element) => {
        return element;
      });
    this.bookings = searchBooking;
    this.setState({ bookings: this.state.bookings ? false : true });
  }
  showMore() {
    this.setState({ showMore: this.state.showMore + 10 });
  }
  radio(e) {
    this.setState({
      radio: e.currentTarget.value,
    });
    if (e.currentTarget.value === "fullAmount") {
      this.bookings[this.state.refundInfo.id].refundAmount = this.bookings[
        this.state.refundInfo.id
      ].costs;
    }
  }
  openRefund(i) {
    this.setState({
      refundInfo: {
        id: i,
        open: true,
        paymentId: this.bookings[i].paymentId,
        fullAmount: this.bookings[i].costs,
      },
    });
    this.bookings[i].refundAmount = this.bookings[i].costs;
  }
  closeRefund() {
    this.setState({ refundInfo: { open: false } });
  }
  onChangeRefund(e) {
    this.bookings[this.state.refundInfo.id].refundAmount =
      e.currentTarget.value;
  }
  validNumber(evt) {
    var theEvent = evt || window.event;
    var key = "";
    // Handle paste
    if (theEvent.type === "paste") {
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  getAvailablity() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getBookedCamping.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((value) => {
            this.placeholderInvalidDates.push({
              camping: value.campingName,
              dates: [],
            });
          });
        }
      });
  }
  getCamping() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCamping.php"
      )
      .then((response) => {
        const mapedCamping = response.data.map((campingOffer, i) => {
          this.camping.push({
            name: campingOffer.campingName,
            text: campingOffer.textShort,
            available: true,
            selected: false,
            weekDays: JSON.parse(campingOffer.weekDays),
            maxGuests: campingOffer.maxGuests,
            dateRange: campingOffer.dateRange,
            price: campingOffer.price,
          });
          return campingOffer;
        });
        var placeholderInvalidDates = [];
        mapedCamping.forEach((value) => {
          placeholderInvalidDates.push({
            campingName: value.campingName,
            dates: [],
          });
        });
        this.setState({ mapedCamping }, () => {
          if (this.bookings.length > 0) {
            this.bookings.forEach((value, i) => {
              if  (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura") {
              }
            });
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkAvailablity(index) {
    // let campingIndex = this.camping.findIndex(x=> x.name === this.bookings[index].camping);
    // this.weekDays = this.camping[campingIndex].weekDays;
    // let maxGuests = this.camping[campingIndex].maxGuests;
    // (weekDays, maxGuests);
    // weekDays.forEach((value)=>{
    //   if(value.checked === false){
    //   }
    // })
    // this.invalidDate = [];
    // var placeholderInvalidDates = [];
    // const findIndex = this.placeholderInvalidDates.findIndex(
    //   x => x.cabin === this.bookings[index].camping
    // );
    // if (
    //   placeholderInvalidDates.includes(
    //     this.placeholderInvalidDates[findIndex]
    //   ) === false
    // ) {
    //   placeholderInvalidDates.push(this.placeholderInvalidDates[findIndex]);
    // }
    // this.bookings.forEach((value, i) => {
    //   if (value.paymentStatus === "Paid") {
    //     if (this.bookings[index].id !== value.id) {
    //       this.dateArray = this.getDates(
    //         new Date(value.checkIn),
    //         new Date(value.checkOut)
    //       );
    //     }
    //     this.dateArray.forEach(dateArr => {
    //       placeholderInvalidDates.map(info => {
    //         if (JSON.parse(value.cabins).includes(info.cabin)) {
    //           if (!info.dates.includes(dateArr)) {
    //             return info.dates.push(dateArr);
    //           }
    //         }
    //         return null;
    //       });
    //       const newDateArr = new Date(dateArr);
    //       for (var p = 0; p < placeholderInvalidDates.length; p++) {
    //         if (placeholderInvalidDates[p].dates.includes(dateArr)) {
    //           if (
    //             this.invalidDate.includes(
    //               newDateArr.getFullYear() +
    //                 "/" +
    //                 (newDateArr.getMonth() + 1) +
    //                 "/" +
    //                 newDateArr.getDate()
    //             ) === false
    //           ) {
    //             this.invalidDate.push(
    //               newDateArr.getFullYear() +
    //                 "/" +
    //                 (newDateArr.getMonth() + 1) +
    //                 "/" +
    //                 newDateArr.getDate()
    //             );
    //           }
    //         }
    //       }
    //     });
    //   }
    // });
  }

  render() {
    var booked = [];
    if (this.bookings.length > 0) {
      booked = this.bookings
        .filter((value, i) => {
          return this.state.showMore >= i ? value : false;
        })
        .map((value, i) => {
          var date =
            new Date(value.date).getDate() +
            "/" +
            (new Date(value.date).getMonth() + 1) +
            "/" +
            new Date(value.date).getFullYear();

          return (
            <tr key={i}>
              <td><a
                  className="pdf"
                  rel="noopener noreferrer"
                  href={
                    this.settings.protocol +
                    this.settings.domain +
                    "/static/booking/booking-" +
                    value.paymentId +
                    ".pdf"
                  }
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a></td>
              <td>{value.reg_date}</td>
              <td>{value.paymentId}</td>
              <td>
                <div
                // suppressContentEditableWarning={true}
                // contentEditable="true"
                // onInput={e => {
                //   this.updateBookings(e, i, "cabins");
                // }}
                >
                  {" "}
                  {value.camping}
                </div>
              </td>
              <td>
                <DatePicker
                  singleDatePicker={true}
                  autoApply={true}
                  startDate={new Date(value.date)}
                  locale={{ format: "D.M.YYYY" }}
                  disabledDates={["02/25/2015"]}
                  isInvalidDate={(date) => {
                    let campingIndex = this.camping.findIndex(
                      (x) => x.name === this.bookings[i].camping
                    );
                    let weekDays = this.camping[campingIndex].weekDays;
                    let maxGuests = this.camping[campingIndex].maxGuests;
                    let d = new Date(date);
                    let index = weekDays.findIndex(
                      (x) => JSON.parse(x.day) === d.getDay()
                    );
                    if (weekDays[index].checked === false) {
                      return true;
                    } else {
                      let guests = 0;
                      this.bookings.forEach((value) => {
                        if (
                          d.toLocaleString() ===
                          new Date(value.date).toLocaleString()
                        ) {
                          guests = guests + JSON.parse(value.guests);
                        }
                      });
                      if (guests >= maxGuests) {
                        return true;
                      }
                    }
                  }}
                  onApply={(e, p) => {
                    this.updateBookings(p, i, "date");
                  }}
                >
                  {date}
                </DatePicker>
              </td>

              <td>
                <div
                // suppressContentEditableWarning={true}
                // contentEditable="true"
                // onInput={e => {
                //   this.updateBookings(e, i, "guests");
                // }}
                >
                  {" "}
                  {value.guests}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "firstName");
                  }}
                >
                  {" "}
                  {value.firstName}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "lastName");
                  }}
                >
                  {" "}
                  {value.lastName}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "email");
                  }}
                >
                  {" "}
                  {value.email}
                </div>
              </td>

              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "costs");
                  }}
                >
                  {" "}
                  {value.costs}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "paymentStatus");
                  }}
                >
                  {" "}
                  {value.paymentStatus}
                </div>
              </td>

              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "comment");
                  }}
                >
                  {" "}
                  {value.comment}
                </div>
              </td>
              <td>
                <div
                  className="action-btn"
                  onClick={() => {
                    this.sendBooking(i);
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <p>Send booking</p>
                </div>
                <div
                  onClick={() => {
                    this.openRefund(i);
                  }}
                  className="action-btn"
                >
                  <FontAwesomeIcon icon={faCreditCard} />
                  <p>Refund</p>
                </div>
                <div
                  className="action-btn"
                  onClick={() => {
                    this.deleteBooking(i);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <p>Delete</p>
                </div>
              </td>
            </tr>
          );
        });
    }
    return (
      <content id="booked">
        <div className="wrap-content">
          <div
            onClick={() => {
              this.save();
            }}
            className="save-btn"
          >
            <div>Save</div>
          </div>
          <div className="front-page-content">
            <div className="headline">
              <h3>Booked camping</h3>
              <div className="search">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  onChange={(e) => {
                    this.search(e);
                  }}
                />
              </div>
            </div>
            {this.state.refundInfo.open ? (
              <div className="refund-ammount">
                <div>Payment ID {this.state.refundInfo.paymentId}</div>
                <div className="full-amount">
                  <input
                    type="radio"
                    value="fullAmount"
                    checked={this.state.radio === "fullAmount"}
                    onChange={(e) => {
                      this.radio(e);
                    }}
                  />{" "}
                  <div className="text">
                    Full refund (DKK {this.state.refundInfo.fullAmount})
                  </div>
                </div>
                <div className="partial-ammount">
                  <input
                    type="radio"
                    value="partialAmount"
                    checked={this.state.radio === "partialAmount"}
                    onChange={(e) => {
                      this.radio(e);
                    }}
                  />{" "}
                  <div className="text"> Parital refund</div>
                  {this.state.radio === "partialAmount" ? (
                    <span>
                      <div className="text amount">Amount*</div>
                      <input
                        type="text"
                        onPaste={(e) => {
                          this.validNumber(e);
                        }}
                        onKeyPress={(e) => {
                          this.validNumber(e);
                        }}
                        onChange={(e) => {
                          this.onChangeRefund(e);
                        }}
                        placeholder="0"
                      />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div
                  className="close-btn"
                  onClick={() => {
                    this.closeRefund();
                  }}
                >
                  CLOSE
                </div>
                <div
                  className="refund-btn "
                  onClick={() => {
                    this.refundBooking();
                  }}
                >
                  REFUND
                </div>
              </div>
            ) : (
              ""
            )}
            <table>
              <tbody>
                <tr>
                  <th>PDF</th>
                  <th
                    onClick={() => {
                      this.sort("registerDate");
                    }}
                  >
                    Register date
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "registerDate"
                          ? this.state.registerDate.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("paymentId");
                    }}
                  >
                    Payment id
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "paymentId"
                          ? this.state.paymentId.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("cabins");
                    }}
                  >
                    Camping
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "camping"
                          ? this.state.camping.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("date");
                    }}
                  >
                    Date
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "date"
                          ? this.state.date.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("guests");
                    }}
                  >
                    Guests
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "guests"
                          ? this.state.guests.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("firstName");
                    }}
                  >
                    First name
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "firstName"
                          ? this.state.firstName.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("lastName");
                    }}
                  >
                    Last name
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "lastName"
                          ? this.state.lastName.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("email");
                    }}
                  >
                    Email
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "email"
                          ? this.state.email.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>

                  <th
                    onClick={() => {
                      this.sort("costs");
                    }}
                  >
                    Costs
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "costs"
                          ? this.state.costs.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("paymentStatus");
                    }}
                  >
                    Payment status
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "paymentStatus"
                          ? this.state.paymentStatus.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>

                  <th
                    onClick={() => {
                      this.sort("comment");
                    }}
                  >
                    Comment
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "comment"
                          ? this.state.comment.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th>Action</th>
                </tr>
                {booked}
              </tbody>
            </table>
            <div className={this.state.loaded ? "disableSpinner" : ""}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
            <div
              onClick={() => {
                this.showMore();
              }}
              className="showMore"
            >
              Show more
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default BookedCamping;
