import React, { Component } from "react";
import settings from "../../settings.json";
import "./confirmation.scss";
import { withRouter } from "react-router-dom";
import translate from "../../translate.json";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.axios = require("axios");
    this.queryString = require("query-string");
    this.urlParameters = this.queryString.parse(this.props.location.search);
    if (sessionStorage.getItem(this.urlParameters.paymentId) !== null) {
      this.urlParameters = JSON.parse(
        sessionStorage.getItem(this.urlParameters.paymentId)
      );

      this.getSettings();
    } else {
      this.urlParameters = { cabins: [], offers: [], startDate: [] }
      // window.location.href = "/";
    }
    if (Object.keys(this.urlParameters).length === 0) {
      this.urlParameters.firstName = "";
      this.urlParameters.startDate = "";
      this.urlParameters.endDate = "";
      this.urlParameters.totalNight = "";
      this.urlParameters.email = "";
      this.urlParameters.paymentId = "";
      this.urlParameters.cabins = [
        { name: "", price: "0" },
        { name: "", price: "0" },
      ];
      this.urlParameters.costs = 0;
      this.urlParameters.cabins.forEach((value) => {
        this.urlParameters.costs += Number(value.price);
      });
    }

  }
  getSettings() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getSettings.php"
      )
      .then((response) => {
        this.setState({
          email: response.data[0].email,
          name: response.data[0].name,
        });
      });
  }
  print() {
    window.open(
      "" +
      this.settings.protocol +
      this.settings.domain +
      "/static/booking/booking-" +
      this.urlParameters.paymentId +
      ".pdf"
    );
  }

  render() {
    const cabinsName = this.urlParameters.cabins.map((value, i) => {
      return (
        <span key={i}>
          {value.name}
          <br />

          <span className="price">
            DKK {value.price},- <span className="per-night">{translate.prNight[settings.lang]}</span>
          </span>
          <br />
        </span>
      );
    });
    const cabinOffersSummary = this.urlParameters.offers.map((value, i) => {
      return (
        <span key={i}>
          <span className="cabin-name">{value.name}</span>
          <span className="price">
            DKK{" "}
            {value.unit === "0" ? value.price * this.urlParameters.guests : ''}
            {value.unit === "1" ? value.price * value.pcs : ''}
            {value.unit === "2" ? (value.price * this.urlParameters.guests) * this.urlParameters.totalNight : ''}
            {value.unit === "3" ? value.price * this.urlParameters.totalNight : ''}
            ,-
          </span>

          <br />
        </span>
      );
    });

    const cabinsNamePrice = this.urlParameters.cabins.map((value, i) => {
      var pricePerDay = [];
      for (var s = 0; s < this.days; s++) {
        pricePerDay.push({ name: value.name, price: value.price });
      }

      return (
        <span key={i}>
          <span className="cabin-name">{value.name}</span>
          <span className="price">DKK {value.price * this.urlParameters.totalNight},-</span>
          {value.cleaning !== "0" ? (
            <span className="cleaning">
              {translate.prClean[settings.lang]}
              <span className="price">DKK {value.cleaning},- </span>
            </span>
          ) : (
            ""
          )}
          <br />
          <br />
        </span>
      );

    });

    const packageName = (
      <span>
        {this.urlParameters.package}
        <br />
      </span>
    );

    return (
      <content id="confirmation">
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>{translate.thanks[settings.lang]} {this.urlParameters.firstName}</h1>
            </div>
            {translate.confirmBook[settings.lang]}
            {" "}
            <span className="second-color">{this.urlParameters.email}</span>.{" "}
            <br />
            <br />
            {translate.confirmRef[settings.lang]}
            {" "}
            <span className="second-color">{this.urlParameters.paymentId}</span>
            .<br />
            <br />
            {translate.confirmFuter[settings.lang]}{" "}
            <span className="second-color">{this.state.email}</span> <br />
            <br />
            {translate.confirmThankChoose[settings.lang]}{this.state.name}. {translate.confirmForSee[settings.lang]}.
            <div
              onClick={() => {
                this.print();
              }}
              className="all-btn"
            >
              <div>Print</div>
            </div>
          </div>
          {this.urlParameters.cabins.length > 0 ? (
            <div className="booking-details">
              <h3>{translate.confirmDetails[settings.lang]}</h3>
              <p className="detail-headline">{translate.checkIn[settings.lang]}</p>
              <p>{this.urlParameters.startDate}</p>
              <p className="detail-headline">{translate.checkOut[settings.lang]}</p>
              <p>{this.urlParameters.endDate}</p>
              <p className="detail-headline">{translate.confirmLength[settings.lang]}</p>
              <p>{this.urlParameters.totalNight} {translate.night[settings.lang]}</p>
              <p className="detail-headline">{translate.ySelcted[settings.lang]}</p>
              <p>{cabinsName}</p>
              <h3>{translate.yPrice[settings.lang]}</h3>
              <p>{cabinsNamePrice}</p>
              {cabinOffersSummary.length > 0 ? (
                <span>
                  <strong>{translate.extra[settings.lang]}:</strong>
                  <p>{cabinOffersSummary}</p>
                </span>
              ) : (
                ""
              )}
              <p className="detail-headline">
                {translate.total[settings.lang]}{" "}
                <span className="price">DKK {this.urlParameters.costs},-</span>
              </p>
            </div>
          ) : (
            <div className="booking-details">
              <h3>  {translate.confirmDetails[settings.lang]}</h3>
              <p className="detail-headline"> {translate.date[settings.lang]}</p>
              <div>
                {Array.isArray(this.urlParameters.startDate) ? this.urlParameters.startDate.map((element, index) => {
                  return <div key={index}>{element}</div>;
                }) : this.urlParameters.startDate}
              </div>
              <p className="detail-headline">{translate.guests[settings.lang]}</p>
              <p>{this.urlParameters.guests}</p>
              <p className="detail-headline">{translate.ySelcted[settings.lang]}</p>
              <p>{packageName}</p>
              <h3>{translate.yPrice[settings.lang]}</h3>
              <p className="detail-headline">
                {translate.total[settings.lang]}{" "}
                <span className="price">DKK {this.urlParameters.costs},-</span>
              </p>
            </div>
          )}
        </div>
      </content>
    );
  }
}

export default withRouter(Confirmation);
