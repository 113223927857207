import React, { Component } from "react";
import settings from "../../settings.json";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faUserFriends,
  faCheck,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-bootstrap-daterangepicker";
import "./packageAvailability.scss";
import ReactHtmlParser from "react-html-parser";
import translate from "../../translate.json";

class PackageAvailability extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.queryString = require("query-string");
    this.urlParameters = this.queryString.parse(this.props.location.search);
    this.bookedDates = [];
    this.invalidDate = [];
    this.invalidCabinDate = [];
    this.packages = [];
    this.util = require("util");
    this.clientInfo = sessionStorage.getItem("clientInfo")
      ? JSON.parse(sessionStorage.getItem("clientInfo"))
      : { startDate: "", guests: 2, children: 0, adults: 2 };
    var startDate = new Date(this.clientInfo ? this.clientInfo.startDate : "");
    var endDate = new Date(this.clientInfo ? this.clientInfo.endDate : "");

    this.state = {
      children: this.clientInfo ? JSON.parse(this.clientInfo.children) : 0,
      adults: this.clientInfo ? JSON.parse(this.clientInfo.adults) : 2,
      availability: [],
      dateRange: false,
      fixedBookedBtn: React.createRef(),
      fixedTable: React.createRef(),
      bookBtn: false,
      table: false,
      packages: [],
      startPackageDate: new Date("1.1.2000"),
      endPackageDate: new Date("1.1.2200"),
      startDate: isNaN(startDate.getTime())
        ? ""
        : startDate.getFullYear() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getDate(),
      startDateFormat: new Date(
        this.clientInfo.startDate !== ""
          ? this.clientInfo.startDate
          : new Date()
      ),
      endDate: isNaN(endDate.getTime())
        ? ""
        : endDate.getFullYear() +
        "." +
        (endDate.getMonth() + 1) +
        "." +
        endDate.getDate(),
      endDateFormat: new Date(
        this.clientInfo.endDate !== "" ? this.clientInfo.endDate : new Date()
      ),
      guests: this.clientInfo ? JSON.parse(this.clientInfo.guests) : 2,
      amountPackages: 0,
      checkAvailability: false,
      startDateNotSelected: true,
      endDateNotSelected: true,
      readyForBooking: false,
      packageId: this.queryString.parse(this.props.location.search).id,
      minDate: isNaN(startDate.getTime()) ? new Date("1200/01/01") : startDate,
    };

    this.axios = require("axios");

    this.getPackages();
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  handleScroll = () => {
    var widthPerc = (window.innerWidth / 100) * 37.2;
    if (this.state.fixedBookedBtn.current !== null) {
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc <=
        window.pageYOffset &&
        this.state.bookBtn === false
      ) {
        this.setState({ bookBtn: true });
      }
      if (
        this.state.fixedBookedBtn.current.offsetTop + widthPerc >=
        window.pageYOffset &&
        this.state.bookBtn === true
      ) {
        this.setState({ bookBtn: false });
      }
    }
    var widthPer = (window.innerWidth / 100) * 1.8;
    if (this.state.fixedTable.current !== null) {
      if (
        this.state.fixedTable.current.offsetTop - widthPer <=
        window.pageYOffset &&
        this.state.table === false
      ) {
        this.setState({ table: true });
      }
      if (
        this.state.fixedTable.current.offsetTop - widthPer >=
        window.pageYOffset &&
        this.state.table === true
      ) {
        this.setState({ table: false });
      }
    }
  };
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    end = new Date(end);

    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  getPackages() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getPackages.php"
      )
      .then((resp) => {
        var resp = resp.data;

        if (this.state.packageId) {
          resp = resp.filter((x) => x.id === this.state.packageId);
        }
        this.axios
          .post(
            "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/getBindPackages.php"
          ).then((response) => {

            const mapedPackages = resp.map((packageOffer, i) => {
              const img =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/medium_" +
                packageOffer.mainImage;
              if (response.data !== "" && packageOffer.seletctDatePref === "0") {
                console.log(response)
                var bindPackages = response.data.filter(x => x.packageId === packageOffer.id);
                bindPackages.forEach((value) => {
                  this.packages.push({
                    paymentMethod: packageOffer.paymentMethod,
                    dateIndex: i,
                    date: value.dateRange,
                    start: value.start,
                    end: value.end,
                    name: packageOffer.packageName,
                    text: packageOffer.textShort,
                    extraInfo: packageOffer.extraInfo,
                    id: packageOffer.id,
                    seletctDatePref: packageOffer.seletctDatePref,
                    available: true,
                    selected: false,
                    weekDays: [],
                    img: img,
                    maxGuests: packageOffer.maxGuests,
                    // dateRange: bindPackages,
                    price: packageOffer.price,
                    cPrice: packageOffer.cPrice,
                  });
                })

              } else {
                packageOffer.dateRange = packageOffer.dateRange.split('-');

                var week = JSON.parse(packageOffer.weekDays)
                var filterDays = this.getDates(packageOffer.dateRange[0], packageOffer.dateRange[1]).filter((x) => {
                  var d = new Date(x);
                  return week.find(x => JSON.parse(x.day) === d.getDay()).checked
                })
                filterDays.forEach(
                  (date, i) => {
                    this.packages.push({
                      paymentMethod: packageOffer.paymentMethod,
                      dateIndex: i,
                      date,
                      start: "",
                      end: "",
                      faktura: packageOffer.faktura,
                      name: packageOffer.packageName,
                      text: packageOffer.textShort,
                      extraInfo: packageOffer.extraInfo,
                      id: packageOffer.id,
                      available: true,
                      selected: false,
                      img: img,
                      weekDays: JSON.parse(packageOffer.weekDays),
                      maxGuests: packageOffer.maxGuests,
                      dateRange: packageOffer.dateRange,
                      price: packageOffer.price,
                      cPrice: packageOffer.cPrice,
                    });
                  })
              }
              return packageOffer;
            });

            var placeholderInvalidDates = [];
            mapedPackages.forEach((value) => {
              placeholderInvalidDates.push({
                packageName: value.packageName,
                dates: [],
              });
            });
            // var startDate = "";
            // var endDate = "";
            // this.packages.forEach((element) => {
            //   console.log(element)
            //   var packageStartDate = new Date(element.dateRange.split("-")[0]);
            //   var packageEndDate = new Date(element.dateRange.split("-")[1]);
            //   packageStartDate.setHours(0, 0, 0, 0);
            //   if (startDate === "") {
            //     startDate = packageStartDate.valueOf();
            //   } else {
            //     if (startDate > packageStartDate) {
            //       startDate = packageStartDate.valueOf();
            //     }
            //   }
            //   if (endDate === "") {
            //     endDate = packageEndDate.valueOf();
            //   } else {
            //     if (endDate < packageEndDate) {
            //       endDate = packageEndDate.valueOf();
            //     }
            //   }
            // });
            // this.setState({ startPackageDate: new Date(startDate) });
            // this.setState({ endPackageDate: new Date(endDate) });

            this.setState({ mapedPackages }, () => {
              this.getBooked().then((value) => {
                if (value.length > 0) {
                  this.bookedDates = value === "" ? [""] : value;
                  this.bookedDates.forEach((value, i) => {
                    if (value.paymentStatus === "Faktura") {
                    }
                  });
                }
              });
            });

            this.checkAvailability();
          })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getBooked() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBookedPackages.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }

  handleStartDate = (event, picker) => {
    this.setState({ readyForBooking: false, infoChange: true });
    var startDate = new Date(picker.startDate._d);
    this.setState({ startDateNotSelected: false });
    this.setState({
      startDate:
        startDate.getFullYear() +
        "." +
        (startDate.getMonth() + 1) +
        "." +
        startDate.getDate(),
      startDateFormat: startDate,
      endDateFormat: startDate,
      minDate: startDate,
    });
    this.clientInfo.startDate =
      startDate.getFullYear() +
      "/" +
      (startDate.getMonth() + 1) +
      "/" +
      startDate.getDate();
    sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
  };
  handleEndDate = (event, picker) => {
    this.setState({ readyForBooking: false, infoChange: true });
    var endDate = new Date(picker.endDate._d);
    this.setState({ endDateNotSelected: false });
    this.setState({
      endDate:
        endDate.getFullYear() +
        "." +
        (endDate.getMonth() + 1) +
        "." +
        endDate.getDate(),
      endDateFormat: endDate,
    });

    this.clientInfo.endDate =
      endDate.getFullYear() +
      "/" +
      (endDate.getMonth() + 1) +
      "/" +
      endDate.getDate();
    sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
  };

  addPeople = (amount, define) => {
    var maxGuests = 0;

    this.state.mapedPackages.forEach((value) => {
      maxGuests = maxGuests < value.maxGuests ? value.maxGuests : maxGuests;
    });
    this.setState({ readyForBooking: false, infoChange: true });
    this.packages = this.packages.map((packageOffer) => {
      packageOffer.selected = false;
      return packageOffer;
    });
    console.log(amount)
    if (
      this.state.guests + amount <= this.packages.length * maxGuests &&
      this.state.guests + amount >= 1
    ) {
      var children = define === 'child' ? this.state.children + amount : this.state.children;
      var adults = define === 'adult' ? this.state.adults + amount : this.state.adults;
      this.setState({ guests: this.state.guests + amount, children, adults });
      this.clientInfo.guests = this.state.guests + amount;
      this.clientInfo.adults = adults;
      this.clientInfo.children = children;
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
      for (var i = this.packages.length; i >= 1; i--) {
        if (amount + this.state.guests <= i * maxGuests) {
          this.setState({ amountPackages: i });
        }
      }
    }
  };

  checkAvailability = () => {
    var packageArray = [];
    var packageAvailability = [];
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var endDate =
      this.state.endDate === ""
        ? new Date(date.setMonth(date.getMonth() + 1))
        : this.state.endDateFormat;
    this.setState({ availability: [] });

    this.packages.forEach((packageOffer) => {
      const weekdayIndex =
        packageOffer.seletctDatePref === "1"
          ? packageOffer.weekDays.findIndex(
            (x) =>
              JSON.parse(x.day) ===
              new Date(this.state.startDateFormat).getDay()
          )
          : [];
      packageOffer.maxGuests = packageOffer.maxGuests === "" ? "0" : packageOffer.maxGuests
      packageArray.push({
        id: packageOffer.id,
        name: packageOffer.name,
        extraInfo: packageOffer.extraInfo,
        available:
          packageOffer.seletctDatePref === "1"
            ? packageOffer.weekDays[weekdayIndex].checked
            : [],
        selected: false,
        price: packageOffer.price,
        maxGuests: JSON.parse(packageOffer.maxGuests),
        weekDays: packageOffer.weekDays,
        left: JSON.parse(packageOffer.maxGuests),
      });
      // this.getDates(this.state.startDateFormat, endDate).forEach((element) => {
      var element = new Date(packageOffer.date);
      var friendlyDate =
        element.getDate() +
        "." +
        (element.getMonth() + 1) +
        "." +
        element.getFullYear();

      if (
        new Date(element).valueOf() >=
        new Date(this.state.startDateFormat).valueOf() &&
        new Date(element).valueOf() <= new Date(endDate).valueOf()
      ) {

        packageAvailability.push({
          packageId: packageOffer.id,
          date: friendlyDate,
          dateValueOf: element.valueOf(),
          name: packageOffer.name,
          totalGuests: 0,
        });
      } else if (this.state.startDateNotSelected && this.state.endDateNotSelected) {
        packageAvailability.push({
          packageId: packageOffer.id,
          date: friendlyDate,
          dateValueOf: element.valueOf(),
          name: packageOffer.name,
          totalGuests: 0,
        });
      }
    });

    // });

    this.getBooked().then((value) => {
      this.bookedDates = value === "" ? [""] : value;
      this.bookedDates.forEach((value, i) => {
        if (
          new Date(value.date).valueOf() >=
          new Date(this.state.startDateFormat).valueOf() &&
          new Date(value.date).valueOf() <= new Date(endDate).valueOf() &&
          (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura")
        ) {
          const index = packageAvailability.findIndex(
            (x) =>
              x.packageId === value.packageId &&
              x.dateValueOf === new Date(value.date).valueOf()
          );

          if (index > -1) {
            packageAvailability[index].totalGuests =
              packageAvailability[index].totalGuests + JSON.parse(value.guests);
          }
        }


        else if (this.state.startDateNotSelected && this.state.endDateNotSelected) {
          const index = packageAvailability.findIndex(
            (x) =>
              x.packageId === value.packageId &&
              x.dateValueOf === new Date(value.date).valueOf()
          );

          if (index > -1) {
            packageAvailability[index].totalGuests =
              packageAvailability[index].totalGuests + JSON.parse(value.guests);
          }
        }
      });

      var availability = [];
      packageAvailability.forEach((value) => {
        const index = packageArray.findIndex((x) => x.id === value.packageId);
        if (index !== -1) {
          if (
            value.totalGuests + JSON.parse(this.state.guests) <=
            packageArray[index].maxGuests &&
            packageArray[index].available === true
          ) {
            packageArray[index].available = true;
          } else {
            packageArray[index].available = false;
          }

          availability.push({
            packageId: value.packageId,
            date: value.date,
            dateValueOf: value.dateValueOf,
            left: packageArray[index].maxGuests - value.totalGuests,
          });
        }
      });

      this.setState({
        packages: packageArray,
        infoChange: false,
        availability,
      });
    });

    if (
      this.state.startDate === "" ||
      this.state.startDate === null ||
      this.state.startDate === undefined
    ) {
      packageArray.map((value) => {
        return (value.available = false);
      });
      this.setState({ packages: packageArray });
    }
  };

  selectPackages(index) {

    this.packages = this.packages.map((value, i) => {
      if (index === i) {
        value.selected
          ? (value.selected = false)
          : (value.selected = true);
      }
      return value;
    });

    this.setState({ packages: this.packages }, () => {
      var state = {};
      for (var key in this.state) {
        if (key !== "fixedBookedBtn") {
          if (key !== "fixedTable") {
            state[key] = this.state[key];
          }
        }
      }
      sessionStorage.setItem("currentState", JSON.stringify(state));
    });

    var selectedGuestsAmount = 0;
    this.packages.forEach((value, i) => {
      if (value.selected) {
        selectedGuestsAmount =
          selectedGuestsAmount + JSON.parse(value.maxGuests);
      }
    });
    if (selectedGuestsAmount !== 0) {
      this.setState({ missingPackage: true });
    } else {
      this.setState({ missingPackage: false });
    }
    if (this.state.guests <= selectedGuestsAmount) {
      this.setState({ readyForBooking: true });
    } else {
      this.setState({ readyForBooking: false });
    }
  }
  scrollToFu = () => {
    var widthPerc = window.innerWidth;

    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };
  setDateRange(value) {
    if (value === false) {
      this.setState(
        {
          endDate: this.state.startDate,
          endDateFormat: this.state.startDateFormat,
          dateRange: value,
        },
        () => {
          this.checkAvailability();
        }
      );
      this.clientInfo.endDate = this.state.startDate;
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
    } else {
      this.setState({ dateRange: value });
    }
  }
  clearDate() {
    this.setState({ startDate: "", endDate: "", endDateNotSelected: true, startDateNotSelected: true });
    this.checkAvailability();
  }
  getMapedPackages(packageOffer, i) {
    var packageDate = new Date(packageOffer.date);
    var availableBtn = (
      <div key={packageOffer.date} className="select-cabin">
        <h2 className="cabin-price">
          <p>
            <span>{translate.adults[settings.lang]}</span>
            DKK {packageOffer.price},-
          </p>

          <p style={{ 'display': packageOffer.cPrice !== "" ? "block" : "none" }}>
            <span>{translate.children[settings.lang]}</span>
            DKK {packageOffer.cPrice},-
          </p>
        </h2>
        <div className="p-div">
          {packageOffer.start !== "" ? <span><b>{translate.start[settings.lang]}:</b> {packageOffer.start} </span> : ""}
          {packageOffer.end !== "" ? <span><b>{translate.end[settings.lang]}:</b> {packageOffer.end} </span> : ""}
        </div>

        <div
          onClick={() => {
            packageOffer.available
              ? this.selectPackages(i)
              : this.selectPackages(-1);
          }}
          className={
            packageOffer.available
              ? packageOffer.selected
                ? "all-btn selected-btn"
                : "all-btn"
              : "all-btn disable-btn"
          }
        >
          <div>
            {packageOffer.selected ? (
              <FontAwesomeIcon
                className={this.state.startDateNotSelected ? "" : "missing"}
                icon={faCheck}
              />
            ) : (
              ""
            )}{" "}
            {packageOffer.selected ? "Deselect" : "Select"}
          </div>
        </div>
      </div>
    );

    var availablity = this.state.availability.map((element, index) => {
      if (packageOffer.id === element.packageId && packageDate.valueOf() === element.dateValueOf) {
        return (
          <div key={index}>
            <div>
              <h2>{translate.date[settings.lang]} </h2>
              <p> {element.date}</p>
            </div>
            <div>
              <h2>{translate.left[settings.lang]} </h2>
              <p>
                {element.left}
              </p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
    return (
      <div key={i} className="cabin">
        <Link
          className="cabin-link"
          key={i}
          to={"package?package=" + packageOffer.name + "&id=" + packageOffer.id}
        >
          <div className="cabin-image">
            <img alt="" src={packageOffer.img} />
          </div>
          <div className="cabin-info">
            <div>

              <h2>{packageOffer.name}</h2>
              {ReactHtmlParser(packageOffer.text)}
            </div>
            <div className="availDate">
              {availablity}
            </div>
          </div>
        </Link>
        {availableBtn}
      </div>
    );
  }
  render() {

    var packages = this.packages.map((packageOffer, i) => {
      var startDate = this.state.startDate.replaceAll(".", "/");
      var d = new Date();
      d.setHours(0, 0, 0, 0);
      if (this.state.endDate === "") {
        if (
          new Date(packageOffer.date).valueOf() ===
          new Date(startDate).valueOf()
        ) {
          return this.getMapedPackages(packageOffer, i);
        } else if (this.state.endDateNotSelected && this.state.startDateNotSelected) {
          if (
            new Date(packageOffer.date).valueOf() >=
            d.valueOf()
          ) {
            return this.getMapedPackages(packageOffer, i);
          }
        }
      } else {
        if (
          new Date(packageOffer.date).valueOf() >=
          new Date(startDate).valueOf() &&
          new Date(packageOffer.date).valueOf() <=
          new Date(this.state.endDateFormat).valueOf()
        ) {
          return this.getMapedPackages(packageOffer, i);
        }
      }
      // var dateRange = packageOffer.dateRange;
      // var startDate = this.state.startDate.replaceAll(".", "/");
      // var d = new Date();
      // console.log(this.state.packages)
      // if (
      //   (new Date(dateRange[0]).valueOf() <= new Date(startDate).valueOf() &&
      //     new Date(dateRange[1]).valueOf() >= new Date(startDate).valueOf()) ||
      //   (dateRange[0] === "" && d.valueOf() < new Date(startDate).valueOf())
      // ) {
      //   var availableBtn = this.state.packages.map((value, i) => {
      //     return value.id === packageOffer.id ? (
      //       <div key={i} className="select-cabin">
      //         <h2 className="cabin-price">DKK {packageOffer.price},-</h2>
      //         <div className="p-div">
      //           {console.log(value.weekDays)}
      //           {value.weekDays.map((info) => {
      //             let day = "";
      //             if (info.day === "0") {
      //               day = "Sun";
      //             }
      //             if (info.day === "1") {
      //               day = "Mon";
      //             }
      //             if (info.day === "2") {
      //               day = "Tue";
      //             }
      //             if (info.day === "3") {
      //               day = "Wed";
      //             }
      //             if (info.day === "4") {
      //               day = "Thu";
      //             }
      //             if (info.day === "5") {
      //               day = "Fri";
      //             }
      //             if (info.day === "6") {
      //               day = "Sat";
      //             }
      //             return (
      //               <div
      //                 key={day}
      //                 className={info.checked ? "day" : "day notAvailable"}
      //               >
      //                 {day}
      //               </div>
      //             );
      //           })}
      //         </div>
      //         <div
      //           onClick={() => {
      //             this.state.packages[i].available
      //               ? this.selectPackages(i)
      //               : this.selectPackages(-1);
      //           }}
      //           className={
      //             this.state.packages[i].available
      //               ? this.state.packages[i].selected
      //                 ? "all-btn selected-btn"
      //                 : "all-btn"
      //               : "all-btn disable-btn"
      //           }
      //         >
      //           <div>
      //             {this.state.packages[i].selected ? (
      //               <FontAwesomeIcon
      //                 className={
      //                   this.state.startDateNotSelected ? "" : "missing"
      //                 }
      //                 icon={faCheck}
      //               />
      //             ) : (
      //               ""
      //             )}{" "}
      //             {this.state.packages[i].selected ? "Deselect" : "Select"}
      //           </div>
      //         </div>
      //       </div>
      //     ) : null;
      //   });

      //   var availablity = this.state.availability.map((element, index) => {
      //     if (packageOffer.id === element.packageId) {
      //       return (
      //         <div key={index}>
      //           <div>{element.date}</div> <div>{element.left} left</div>
      //         </div>
      //       );
      //     } else {
      //       return null;
      //     }
      //   });
      //   return (
      //     <div key={i} className="cabin">
      //       <Link
      //         className="cabin-link"
      //         key={i}
      //         to={
      //           "package?package=" +
      //           packageOffer.name +
      //           "&id=" +
      //           packageOffer.id
      //         }
      //       >
      //         <div className="cabin-image">
      //           <img alt="" src={packageOffer.img} />
      //         </div>
      //         <div className="cabin-info">
      //           <h2>{packageOffer.name}</h2>
      //           {ReactHtmlParser(packageOffer.text)}
      //           <div className="availability">
      //             <strong>Availability: </strong>
      //             {availablity}
      //           </div>
      //         </div>
      //       </Link>
      //       {availableBtn}
      //     </div>
      //   );
      // } else {
      //   return null;
      // }
    });
    return (
      <content id="packageAvailability">
        <div className="headline">
          <h1>{translate.beginAd[settings.lang]} …</h1>
        </div>
        <div className="date-type">
          <div
            onClick={() => {
              this.setDateRange(false);
            }}
            className={
              this.state.dateRange
                ? "date-button"
                : "date-button date-button-selected"
            }
          >
            <div>{translate.singleDate[settings.lang]}</div>
          </div>
          <div
            onClick={() => {
              this.setDateRange(true);
            }}
            className={
              !this.state.dateRange
                ? "date-button"
                : "date-button date-button-selected"
            }
          >
            <div>{translate.rangeDate[settings.lang]}</div>
          </div>
          <div
            onClick={() => {
              this.clearDate();
            }}
            className={
              "clear"
            }
          >
            <div>{translate.clearDate[settings.lang]}</div>
          </div>
        </div>
        <div className="filter">
          <div className="input">
            <div className="singleDate">
              <label>Start Date</label>
              <div>
                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}
                <FontAwesomeIcon
                  className={this.state.startDateNotSelected ? "" : "missing"}
                  icon={faCalendarAlt}
                />
                <DatePicker
                  className="startDate"
                  singleDatePicker={true}
                  autoApply={true}
                  locale={{ format: "D.M.YYYY" }}
                  minDate={moment(this.state.startPackageDate)}
                  maxDate={moment(this.state.endPackageDate)}
                  disabledDates={["02/25/2015"]}
                  isInvalidDate={(date) => { }}
                  onApply={this.handleStartDate}
                >

                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.startDate.length > 0
                        ? this.state.startDate
                        : ""
                    }
                  />
                </DatePicker>
              </div>
            </div>
            <div style={{ display: this.state.dateRange ? 'block' : 'none' }} className="dateRange">
              <label>End Date</label>
              <div>

                {this.state.occupied ? (
                  <div className="occupied">{this.state.occupied}</div>
                ) : (
                  ""
                )}

                <DatePicker
                  autoApply={true}
                  singleDatePicker={true}
                  locale={{ format: "D.M.YYYY" }}
                  minDate={moment(this.state.minDate)}
                  maxDate={moment(this.state.endPackageDate)}
                  startDate={this.state.startDate}
                  onApply={this.handleEndDate}
                >
                  <FontAwesomeIcon
                    className={this.state.endDateNotSelected ? "" : "missing"}
                    icon={faCalendarAlt}
                  />

                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={
                      this.state.endDate.length > 0 ? this.state.endDate : ""
                    }
                  />
                </DatePicker>

              </div>
            </div>
            <div className="adults">
              <label>{translate.adults[settings.lang]}</label>
              <div>

                <input value={this.state.adults + " " + translate.pcs[settings.lang]} />
                <div className="plus-minus">
                  <div
                    className="add-people"
                    onClick={() => {
                      this.addPeople(+1, 'adult');
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <div
                    className="remove-people"
                    onClick={() => {
                      this.addPeople(-1, 'adult');
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </div>
              </div>
            </div>
            <div className="children">
              <label>{translate.children[settings.lang]}</label>
              <div>
                <input value={this.state.children + " " + translate.pcs[settings.lang]} />
                <div className="plus-minus">
                  <div
                    className="add-people"
                    onClick={() => {
                      this.addPeople(+1, 'child');
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <div
                    className="remove-people"
                    onClick={() => {
                      this.addPeople(-1, 'child');
                    }}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkAvailability">
            <div ref={this.state.fixedBookedBtn} className="th">
              <div
                className={
                  this.state.bookBtn && this.state.checkAvailability
                    ? "book-btn"
                    : ""
                }
              >
                {this.state.checkAvailability ? (
                  <div className="btn-shadow"></div>
                ) : (
                  ""
                )}
                {this.state.readyForBooking ? (
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 100);
                    }}
                    to={"bookingdetails"}
                  >
                    <div className="read-book-btn all-btn">
                      <div>{translate.book[settings.lang]}</div>
                    </div>
                  </Link>
                ) : this.state.missingCabin ? (
                  <div className={"all-btn disable-btn"}>
                    <div>{translate.book[settings.lang]}</div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      this.checkAvailability();
                    }}
                    className={"all-btn check-avail-btn"}
                  >
                    <div>{translate.checkAvail[settings.lang]}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="cabin-list">
          <div className="wrap-cabin-list">
            {this.state.infoChange ? "" : packages}
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(PackageAvailability);
