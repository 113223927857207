import React, { Component } from "react";
import settings from "../../../settings.json";
import ImageUploader from "react-images-upload";
import "./camping.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faTrashAlt,
  faSpinner,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-bootstrap-daterangepicker";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";

class AdminCamping extends Component {
  uuidv4 = uuidv4;
  constructor(props) {
    super(props);

    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };
    this.campingOffers = null;
    this.axios = require("axios");
    this.getCampingOffers();
    this.state = {
      campingOffers: [],
      mainImage: new Date().getTime(),
      activeList: -1,
      imageLoadGallery: false,
      imageLoadMain: false,
      campingOfferImages: [{ src: "", index: -1, imageCount: -1 }],
    };
    this.uploadMainImage = this.uploadMainImage.bind(this);
  }
  uploadMainImage(picture, index) {
    this.setState({ imageLoadMain: true });
    var campingOffers = this.state.campingOffers.map((value, s) => {
      if (index === s) {
        value.mainImage = index + "_camping_mainImage.png";
      }
      return value;
    });
    this.setState({ campingOffers });
    this.upload(
      picture[picture.length - 1],
      index + "_camping_mainImage.png"
    ).then((res) => {
      this.setState({ mainImage: new Date().getTime() });
    });
  }

  uploadGallery(picture, index) {
    this.setState({ imageLoadGallery: true });
    var newCampingOfferImages = -1;
    var campingOfferImage = [];

    this.state.campingOfferImages.forEach((value, i) => {
      if (value.index === index) {
        newCampingOfferImages = JSON.parse(value.src.slice(0, 1));
        campingOfferImage.push({ src: value.src, index: value.index });
      } else {
        campingOfferImage.push({ src: value.src, index: value.index });
      }
    });

    var campingOffers = this.state.campingOffers.map((value, s) => {
      if (s === index) {
        var gallery = JSON.parse(value.gallery);
        picture.forEach((value, i) => {
          i = i + 1;
          gallery.push(
            newCampingOfferImages + i + "_camping_gallery_" + index + ".png"
          );
        });
        value.gallery = JSON.stringify(gallery);
        return value;
      } else {
        return value;
      }
    });
    picture.forEach((value, i) => {
      i = i + 1;
      campingOfferImage.push({
        src: newCampingOfferImages + i + "_camping_gallery_" + index + ".png",
        index,
      });
    });
    this.setState({ campingOffers });
    this.upload(picture, "camping_gallery", index, newCampingOfferImages).then(
      () => {
        this.setState({ campingOfferImages: campingOfferImage });
      }
    );
  }

  upload(picture, define, index, startNumber) {
    var formData = new FormData();
    if (define === "camping_gallery") {
      picture.forEach((element, i) => {
        formData.append(
          "fileToUpload[]",
          element,
          startNumber + 1 + i + "_" + define + "_" + index + ".png"
        );
      });
    } else {
      formData.append("fileToUpload", picture, define);
    }
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/uploadImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.setState({
          imageLoadGallery: false,
          imageLoadMain: false,
          fileKey: new Date().getTime(),
        });
        this.setState({});
        if (res.data === 1) {
          return res;
        } else {
          alert(res.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  getCampingOffers() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCamping.php"
      )
      .then((response) => {
        var newGallery = [];
        response.data.forEach((data, index) => {
          data.weekDays = JSON.parse(data.weekDays);
          var gallery = JSON.parse(data.gallery);
          gallery.forEach((image) => {
            newGallery.push({ src: image, index });
          });
        });

        this.setState({
          campingOffers: response.data,
          campingOfferImages: newGallery,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  save() {
    this.state.campingOffers.map((value) => {
      return (value.weekDays = JSON.stringify(value.weekDays));
    });

    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateCamping.php",
        this.state.campingOffers
      )
      .then((response) => {
        this.state.campingOffers.map((value) => {
          return (value.weekDays = JSON.parse(value.weekDays));
        });
        this.state.campingOffers.map((value) => {
          return (value.new = false);
        });
        this.setState({ campingOffers: this.state.campingOffers });
        if (response.data) {
          alert("Data saved successfully!");
        } else {
          alert("Saved failed" + response.data);
        }
      });
  }
  openList(index) {
    this.state.activeList !== index
      ? this.setState({ activeList: index })
      : this.setState({ activeList: -1 });
  }
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  inputChange(event, define, index) {
    if (define === "campingOfferName") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.campingName = event.target.value;
          } else {
            value.campingNameFo = event.target.value;
          }
        }
        return value;
      });
    }
    if (define === "price") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          value.price = event.target.value;
        }
        return value;
      });
    }
    if (define === "maxGuests") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          value.maxGuests = event.target.value;
        }
        return value;
      });
    }
    if (define === "dateRange") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          value.dateRange = event.startDate + " - " + event.endDate;
        }
        return value;
      });
    }

    if (define === "days") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          const findIndex = value.weekDays.findIndex((x) => {
            return x.day === event.target.value;
          });
          value.weekDays[findIndex].checked = event.target.checked;
        }
        return value;
      });
    }
    if (define === "textShort") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.textShort = event;
          } else {
            value.textShortFo = event;
          }
        }
        return value;
      });
    }
    if (define === "textLong") {
      this.campingOffers = this.state.campingOffers.map((value, s) => {
        if (index === s) {
          if (settings.lang === "en") {
            value.textLong = event;
          } else {
            value.textLongFo = event;
          }
        }
        return value;
      });
    }
  }
  onBlur() {
    if (this.campingOffers !== null) {
      setTimeout(()=>{
      this.setState({ campingOffers: this.campingOffers });
    }, 100)
    }
  }
  removeCampingOfferImage(src, index) {
    var campingOffers = this.state.campingOffers.map((value, s) => {
      if (index === s) {
        var gallery = JSON.parse(value.gallery);
        gallery.splice(gallery.indexOf(src), 1);
        value.gallery = JSON.stringify(gallery);
      }
      return value;
    });

    var campingOfferImages = this.state.campingOfferImages.filter((value) => {
      return value.src !== src;
    });
    this.setState({ campingOffers, campingOfferImages });
  }

  addCampingOffer() {
    var newCampingOffer = {
      campingName: "New Camping",
      price: "",
      maxGuests: "",
      dateRange: "",
      weekDays: [
        { day: "0", checked: true },
        { day: "1", checked: true },
        { day: "2", checked: true },
        { day: "3", checked: true },
        { day: "4", checked: true },
        { day: "5", checked: true },
        { day: "6", checked: true },
      ],
      textShort: "",
      textLong: "",
      mainImage: "",
      gallery: "[]",
      new: true,
    };
    this.state.campingOffers.push(newCampingOffer);
    this.setState({ campingOffers: this.state.campingOffers });
  }
  deleteCampingOffer(id) {
    var r = window.confirm("Sure you wan't to delete this campingOffer ");
    if (r === true) {
      const index = this.state.campingOffers.findIndex((x) => {
        return x.id === id;
      });
      this.state.campingOffers.splice(index, 1);
      this.setState({
        campingOffers: this.state.campingOffers,
        activeList: -1,
      });
      this.axios
        .post(
          "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/deleteCamping.php",
          { id }
        )
        .then((response) => {
          if (response.data) {
            alert("CampingOffer deleted successfully!");
          } else {
            alert("Deleted failed");
          }
        });
    }
  }
  render() {
    var campingOffers = this.state.campingOffers.map((campingOffer, index) => {
      return (
        <li key={index}>
          <div
            className="list"
            onClick={() => {
              this.openList(index);
            }}
          >
            
            {campingOffer.campingName}
            <div className="list-icon">
              <FontAwesomeIcon
                icon={this.state.activeList === index ? faSortUp : faSortDown}
              />
            </div>
          </div>
          <div
            className={
              this.state.activeList === index
                ? "active-list list-content"
                : "list-content"
            }
          >
            <div className="campingOffer-name">
              <div>
                <p>
                  Camping/Tent area <i>{settings.lang}</i>
                </p>
                <input
                  defaultValue={
                    settings.lang === "en"
                      ? campingOffer.campingName
                      : campingOffer.campingNameFo
                  }
                  onChange={(e) => {
                    this.inputChange(e, "campingOfferName", index);
                  }}
              
                  
                />
              </div>
              <div>
                <p>Price</p>
                <input
                  defaultValue={campingOffer.price}
                  onChange={(e) => {
                    this.inputChange(e, "price", index);
                  }}
             
                  
                />
              </div>
              <div>
                <p>Max Camping/Tent spots</p>
                <input
                  defaultValue={campingOffer.maxGuests}
                  onChange={(e) => {
                    this.inputChange(e, "maxGuests", index);
                  }}
                  
                />
              </div>
              <div>
                <p>Select date range</p>

                <DatePicker
                  autoApply={true}
                  startDate={new Date()}
                  locale={{ format: "D.M.YYYY" }}
                  onApply={(e, p) => {
                    const startDate =
                      p.startDate._d.getFullYear() +
                      "/" +
                      (p.startDate._d.getMonth() + 1) +
                      "/" +
                      p.startDate._d.getDate();
                    const endDate =
                      p.endDate._d.getFullYear() +
                      "/" +
                      (p.endDate._d.getMonth() + 1) +
                      "/" +
                      p.endDate._d.getDate();
                    this.inputChange(
                      { startDate, endDate },
                      "dateRange",
                      index
                    );
                  }}
                >
                  <input
                    key={new Date()}
                    defaultValue={campingOffer.dateRange}
                    
                  />
                </DatePicker>
              </div>
              <div>
                <div className="campingOffer-week-days">
                  <div className="days">
                    <p>Sun</p>

                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                      }}
                      value="0"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "0") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Mon</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="1"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "1") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Tue</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="2"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "2") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Wed</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="3"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "3") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Thu</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="4"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "4") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Fri</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="5"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "5") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="days">
                    <p>Sat</p>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "days", index);
                        }}
                      value="6"
                      type="checkbox"
                      checked={
                        campingOffer.weekDays.findIndex((x) => {
                          return (x.day === "6") & (x.checked === true);
                        }) !== -1
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="campingOffer-text">
              <div className="editor">
                <div className="text-short">
                  <p>
                    Edit short text <i>{settings.lang}</i>
                  </p>
                  <ReactQuill
                    value={
                      settings.lang === "en"
                        ? campingOffer.textShort
                        : campingOffer.textShortFo
                    }
                    
                    onChange={(event) => {
                      this.inputChange(event, "textShort", index);
                    }}
                  />
                </div>
                <div className="text-long">
                  <p>
                    Edit long text <i>{settings.lang}</i>
                  </p>
                  <ReactQuill
                    value={
                      settings.lang === "en"
                        ? campingOffer.textLong
                        : campingOffer.textLongFo
                    }
                    
                    onChange={(event) => {
                      this.inputChange(event, "textLong", index);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="images add-images">
              <p>
                Main image
                <span
                  className={
                    this.state.imageLoadMain ? "image-spinner" : "hide-spinner"
                  }
                >
                  <FontAwesomeIcon icon={faSpinner} spin />
                </span>
              </p>
              <div className="main-image">
                <img
                  alt=""
                  src={
                    campingOffer.mainImage.length > 0
                      ? "" +
                        this.settings.protocol +
                        this.settings.domain +
                        "/static/uploads/thumbnail_" +
                        campingOffer.mainImage +
                        "?" +
                        new Date().getTime() +
                        this.state.mainImage
                      : ""
                  }
                />
              </div>
              <ImageUploader
                accept="PNG|JPEG|JPG"
                singleImage={true}
                withIcon={true}
                buttonText="Choose images"
                onChange={(e) => {
                  this.uploadMainImage(e, index);
                }}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={50000000000000}
              />
              <p>
                Gallery Images
                <span
                  className={
                    this.state.imageLoadGallery
                      ? "image-spinner"
                      : "hide-spinner"
                  }
                >
                  <FontAwesomeIcon icon={faSpinner} spin />
                </span>
              </p>
              <div className="gallery">
                {this.state.campingOfferImages.map((value, i) => {
                  if (value.index === index) {
                    return (
                      <div key={i} className="images">
                        <FontAwesomeIcon
                          onClick={() => {
                            this.removeCampingOfferImage(value.src, index);
                          }}
                          icon={faTrashAlt}
                        />
                        <img
                          alt=""
                          src={
                            "" +
                            this.settings.protocol +
                            this.settings.domain +
                            "/static/uploads/thumbnail_" +
                            value.src +
                            "?" +
                            new Date().getTime()
                          }
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}

                <ImageUploader
                  accept="PNG|JPEG|JPG"
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={(file) => {
                    this.uploadGallery(file, index);
                  }}
                  key={this.state.fileKey || ""}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={50000000000000}
                />
              </div>
              <div
                onClick={() => {
                  this.deleteCampingOffer(campingOffer.id);
                }}
                className="delete-campingOffer"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </div>
            </div>
          </div>
        </li>
      );
    });

    return (
      <content id="admin-campingOffers">
        <div className="wrap-content">
          <div className="front-page-content">
            <div className="wrap-head-lang">
              <h3>Camping</h3>
              <div>
                <div>
                  <p
                    onClick={() => {
                      settings.lang = "fo";
                      this.setState({}, () => {
                        localStorage.setItem("lang", "fo");
                      });
                    }}
                    className={settings.lang === "fo" ? "active" : ""}
                  >
                    FO
                  </p>
                  <p
                    onClick={() => {
                      settings.lang = "en";
                      this.setState({}, () => {
                        localStorage.setItem("lang", "en");
                      });
                    }}
                    className={settings.lang === "en" ? "active" : ""}
                  >
                    EN
                  </p>
                </div>
                <div
                  className="add"
                  onClick={() => {
                    this.addCampingOffer();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Add camping</span>
                </div>
                <div
                  onClick={() => {
                    this.save();
                  }}
                  className="save-btn"
                >
                  <div>Save</div>
                </div>
              </div>
            </div>

            <ul key={this.uuidv4()}>
              {campingOffers}
            </ul>
          </div>
        </div>
      </content>
    );
  }
}

export default AdminCamping;
