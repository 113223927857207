import React, { Component } from "react";
import settings from "../../settings.json";
import "./terms.scss";
import { withRouter } from "react-router-dom";
class Terms extends Component {
  constructor(props) {
    super(props);
    this.axios = require("axios");
    this.state = {
      email: "",
      name: "",
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.getSettings();
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        this.setState({
          email: response.data[0].email,
          name: response.data[0].name,
        });
      });
  }
  render() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return (
      <content id="confirmation">
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>Terms and conditions</h1>
              <h2>Payment</h2>

              <p>
                Payment for goods and services on this site are made with a
                payment card and all payment are in advance.
              </p>
              <p>
                We accept Matercard, VISA, Diners Club, Discover, JCB, Union Pay, American Express and VISA DK.
              </p>

              <h2>Receipt</h2>

              <p>Receipt will be sent to the customer via e-mail.</p>

              <h2>Cancellations and refund policy</h2>

              <p>
                If you contact us regarding cancelation. We will refund
                according to below "Refund policy". Transaction costs are not
                included in refund.
              </p>
              <p>Refund policy</p>
              <ul>
                <li>30 days, or more, before arrival full amount</li>
                <li>Between 14 and 29 days before arrival half amount</li>
                <li>Less than 14 days before arrival no refund</li>
              </ul>

              <h2>Responsibility</h2>

              <p>All use of {this.state.name} is your own responsibility.</p>

              <h2>Security</h2>

              <p>
                It is safe to buy on {this.state.name}. All payments are made
                using encryption, to protect your payment card information.
              </p>

              <h2>Registration policy</h2>

              <p>
                {this.state.name} will not sell your contact details to 3rd
                parties.
              </p>
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(Terms);
