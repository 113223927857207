import React, { Component } from "react";
import settings from "../../settings.json";
import { comment } from "../../assets";
import { Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ReactHtmlParser from "react-html-parser";
import "./camping.scss";
import Carousel, { Modal, ModalGateway } from "react-images";
import translate from "../../translate.json";

class Camping extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.queryString = require("query-string");
    this.bookedDates = [];
    this.invalidDate = [];

    this.state = {
      startDate: "",
      endDate: "",
      modalIsOpen: false,
      customersWord: [],
      selectedIndex: 0,
      campingName: this.queryString.parse(this.props.location.search).camping,
      campingNameFo: this.queryString.parse(this.props.location.search).camping,
      campingId: this.queryString.parse(this.props.location.search).id,
      mapedCamping: [],
      mainImage: "",
    };

    this.axios = require("axios");
    this.getCamping();
    this.getFrontPage();
  }
  scrollToFu = () => {
    var widthPerc = window.innerWidth;
    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  getBooked() {
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getBookedCamping.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }

  getFrontPage() {
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getFrontPage.php"
      )
      .then((response) => {
        this.setState({
          customersWord: JSON.parse(response.data.customersWord),
        });
      });
  }

  getCamping() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCamping.php"
      )
      .then((response) => {
        var campingImage = [];
        var campingLargeImage = [];
        response.data.forEach((campingOffer, i) => {
          if (campingOffer.id === this.state.campingId) {
            this.setState({
              maxGuests: campingOffer.maxGuests,
              dateRange: campingOffer.dateRange,
              mainImage: campingOffer.mainImage,
            });
            
            JSON.parse(campingOffer.gallery).forEach((value, index) => {
              const img =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/" +
                value;
                const imgThumb =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/medium_" +
                value;
              campingLargeImage.push({ src: img });
              campingImage.push(
                <div
                  key={index}
                  onClick={() => this.toggleModal(index)}
                  className="cabin-image"
                >
                  <img alt="gallery" src={imgThumb} />
                </div>
              );
            });
          }
        });
        this.setState({
          campingImage,
          campingLargeImage,
        });
        var placeholderInvalidDates = [];
        const mapedCamping = response.data.map((campingOffer, i) => {
          placeholderInvalidDates.push({
            campingName: campingOffer.campingName,
            dates: [],
          });
          if (this.state.campingId === campingOffer.id) {
            campingOffer.selected = true;
          }
          return campingOffer;
        });
        var campingInfo = "";
        response.data.forEach((campingOffer) => {
          if (campingOffer.id === this.state.campingId) {
            campingInfo = (
              <span>
                <h1>{ReactHtmlParser(settings.lang === "en" ? campingOffer.campingName: campingOffer.campingNameFo)}</h1>
                {ReactHtmlParser(settings.lang === "en" ? campingOffer.textLong : campingOffer.textLongFo)}
              </span>
            );
          }
        });

        this.setState({ mapedCamping, campingInfo });
        sessionStorage.setItem(
          "currentState",
          JSON.stringify(this.state.mapedCamping)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }

  toggleModal = (index) => {
    this.setState(() => ({
      modalIsOpen: !this.state.modalIsOpen,
      selectedIndex: index,
    }));
  };

  render() {
    const { modalIsOpen } = this.state;
    return (
      <content id="cabins">
        <div className="carousel">
          <img
            alt="img"
            src={
              this.state.mainImage !== ""
                ? "" +
                  this.settings.protocol +
                  this.settings.domain +
                  "/static/uploads/full_" +
                  this.state.mainImage
                : ""
            }
          />
        </div>
        <div className="about">
          <div className="about-content1">
            {this.state.campingInfo}
            <div className="all-btn">
              <Link className="book-now" to={"campingAvailability"}>
                <div>{translate.startBooking[settings.lang]}</div>
              </Link>
            </div>
          </div>
          <div className="about-content2">
            <div>
              <div className="comment">
                <img alt="img" src={comment} />
                <p>{translate.fewWord[settings.lang]}:</p>
                {ReactHtmlParser(this.state.customersWord.comment)}
              </div>
            </div>
          </div>
        </div>
        <div className="cabin-gallery">
          <div className="wrap-cabin-gallery">
            <h1>{translate.gallery[settings.lang]}</h1>
            {this.state.campingImage}
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={this.toggleModal}>
                  <Carousel
                    frameProps={{ autoSize: "height" }}
                    currentIndex={this.state.selectedIndex}
                    views={this.state.campingLargeImage}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(Camping);
