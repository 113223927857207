import React, { Component } from "react";
import settings from "../../../settings.json";
import "./booked.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-bootstrap-daterangepicker";
import {
  faTrashAlt,
  faCreditCard,
  faPaperPlane,
  faFilter,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSearch,
  faSpinner,
  faFilePdf,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

class Booked extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };

    this.axios = require("axios");
    this.getBooked();
    this.cabinOffers = [];
    this.cabinSettings = [];
    this.bookings = [];
    this.placeHolderBookings = [];
    this.state = {
      radio: "fullAmount",
      refundInfo: {
        id: "",
        open: false,
        paymentId: "",
        fullAmount: "",
      },
      refundAmount: "",
      showMore: 10,
      define: "registerDate",
      registerDate: {
        type: "desc",
      },
      paymentId: {
        type: "desc",
      },
      cabins: {
        type: "desc",
      },
      checkIn: {
        type: "desc",
      },
      checkOut: {
        type: "desc",
      },
      actualCheckIn: {
        type: "desc",
      },
      actualCheckOut: {
        type: "desc",
      },
      guests: {
        type: "desc",
      },
      firstName: {
        type: "desc",
      },
      lastName: {
        type: "desc",
      },
      email: {
        type: "desc",
      },
      costs: {
        type: "desc",
      },
      paymentStatus: {
        type: "desc",
      },
      comment: {
        type: "desc",
      },
      bookings: false,
      loaded: false,
      companyEmail: "",
      companyName: "",
      companyAddress: "",
      companyCity: "",
      companyVat: "",
      companyPhone: "",
      companyFacebook: "",
      companyInstagram: "",
      companyBooking: "",
      companyAirbnb: "",
    };
    this.invalidDate = [];
    this.placeholderInvalidDates = [];
    this.dateArray = [];
    this.cabins = [];
    this.cabinsInfo = [];
    this.cabinExists = true;
    this.getSettings();
    this.getCabinOffers();
    this.getCabinSettings();
  }
  getSettings() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            companyEmail: response.data[0].email,
            companyName: response.data[0].name,
            companyAddress: response.data[0].adress,
            companyCity: response.data[0].city,
            companyPhone: response.data[0].phone,
            companyVat: response.data[0].vatnr,
            companyBooking: response.data[0].booking_com,
            companyAirbnb: response.data[0].airbnb_com,
            paymentMethod: response.data[0].payment,
          });
        }
      });
  }
  getCabinSettings() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabinSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.cabinSettings = response.data;
        }
      });
  }
  getCabinOffers() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabinOffers.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.cabinOffers = response.data;
        }
      });
  }
  getBooked() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBooking.php"
      )
      .then((response) => {
        var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
        var data = [];
        if (userLogin.some((x) => x.role === "user")) {
          data = response.data.filter((a) =>
            JSON.parse(a.cabinId).some((b) =>
              userLogin.some((c) => c.cabinId === b)
            )
          );
        } else {
          data = response.data;
        }
        this.bookings = data;
        this.placeHolderBookings = data;
        this.setState({
          bookings: this.state.bookings ? false : true,
          loaded: true,
        });
        this.getAvailablity();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  sortByProperty(propertyName, ascDesc) {
    return this.bookings.sort((a, b) => {
      var sortA = a[propertyName];
      var sortB = b[propertyName];
      if (propertyName === "checkIn" || propertyName === "checkOut") {
        sortA = new Date(sortA).valueOf();
        sortB = new Date(sortB).valueOf();
      }
      if (!isNaN(sortA) && sortA !== "") {
        sortA = JSON.parse(sortA);
        sortA = JSON.parse(sortA);
      }
      if (ascDesc === "asc") {
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }
      } else {
        if (sortA > sortB) {
          return -1;
        }
        if (sortA < sortB) {
          return 1;
        }
      }
      return 0;
    });
  }

  sort(define) {
    if (define === "registerDate") {
      this.sortByProperty(
        "reg_date",
        this.state.registerDate.type === "desc" ? "asc" : "desc"
      );
      this.setState({
        registerDate: {
          type: this.state.registerDate.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "paymentId") {
      this.sortByProperty(
        "paymentId",
        this.state.paymentId.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        paymentId: {
          type: this.state.paymentId.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "cabins") {
      this.sortByProperty(
        "cabins",
        this.state.cabins.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        cabins: {
          type: this.state.cabins.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }

    if (define === "checkIn") {
      this.sortByProperty(
        "checkIn",
        this.state.checkIn.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        checkIn: {
          type: this.state.checkIn.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "checkOut") {
      this.sortByProperty(
        "checkOut",
        this.state.checkOut.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        checkOut: {
          type: this.state.checkOut.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "guests") {
      this.sortByProperty(
        "guests",
        this.state.guests.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        guests: {
          type: this.state.guests.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "firstName") {
      this.sortByProperty(
        "firstName",
        this.state.firstName.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        firstName: {
          type: this.state.firstName.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "lastName") {
      this.sortByProperty(
        "lastName",
        this.state.lastName.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        lastName: {
          type: this.state.lastName.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "email") {
      this.sortByProperty(
        "email",
        this.state.email.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        email: {
          type: this.state.email.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "costs") {
      this.sortByProperty(
        "costs",
        this.state.costs.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        costs: {
          type: this.state.costs.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "paymentStatus") {
      this.sortByProperty(
        "paymentStatus",
        this.state.paymentStatus.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        paymentStatus: {
          type: this.state.paymentStatus.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
    if (define === "comment") {
      this.sortByProperty(
        "comment",
        this.state.comment.type === "asc" ? "desc" : "asc"
      );
      this.setState({
        comment: {
          type: this.state.comment.type === "asc" ? "desc" : "asc",
        },
        define,
      });
    }
  }
  arraysEqual(a, b) {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  checkValidCabin(a) {
    var valueTrue = true;
    a.forEach((valueA) => {
      if (
        this.cabins.some((x) => {
          return x === valueA;
        })
      ) {
      } else {
        valueTrue = false;
      }
    });
    return valueTrue;
  }

  updateBookings(e, i, define) {
    var currentCabin = this.cabinsInfo.find(x => JSON.parse(this.bookings[i].cabinId).some(z => z === x.id));
   
    var index = this.bookings.findIndex((x) => {
      return x.id === this.bookings[i].id;
    });
    if (define === "cabins") {
      if (
        this.checkValidCabin(
          e.currentTarget.innerText.replace(/\s/g, "").split(",")
        )
      ) {
        this.cabinExists = true;
      } else {
        this.cabinExists = false;
      }
      if (
        !this.arraysEqual(
          JSON.parse(this.bookings[index][define]),
          e.currentTarget.innerText.replace(/\s/g, "").split(",")
        )
      ) {
        this.bookings[i][define] = JSON.stringify(
          e.currentTarget.innerText.split(",").map(function (item) {
            return item.charAt(0) === " " ? item.substr(1) : item;
          })
        );
        this.bookings[i]["changed"] = true;
      }
    } else if (define === "actualCheckIn" || define === "actualCheckOut") {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      this.bookings[i]["changed"] = true;
      var date = new Date(e.startDate._d);

      this.bookings[i][define] =
        days[date.getDay()] +
        " " +
        date.getDate() +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear();

        if (define === "actualCheckIn") {
        date.setDate(date.getDate() - JSON.parse(currentCabin.blockDays));
        this.bookings[i].checkIn =
        days[date.getDay()] +
        " " +
        date.getDate() +
        ", " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear();
      }
      if (define === "actualCheckOut") {
        date.setDate(date.getDate() + JSON.parse(currentCabin.blockDays));
        this.bookings[i].checkOut =
          days[date.getDay()] +
          " " +
          date.getDate() +
          ", " +
          monthNames[date.getMonth()] +
          " " +
          date.getFullYear();
      }
      this.setState({ bookings: this.state.bookings ? false : true });
    } else {
      this.bookings[i]["changed"] = true;
      this.bookings[i][define] = e.currentTarget.innerText;
    }
  }
  deleteBooking(i) {
    var r = window.confirm(
      "Sure you wan't to delete item with payment id " +
      this.bookings[i].paymentId
    );
    if (r === true) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteBooking.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            this.bookings.splice(i, 1);
            this.setState({ bookings: this.state.bookings ? false : true });
            alert("Data was deleted successfully!");
          } else {
            alert("Deletion failed");
          }
        });
    }
  }
  onRequest(i) {
    var r = window.confirm(
      "Sure you wan't to approve booking for this payment id " +
      this.bookings[i].paymentId
    );
    if (r === true) {
      var emailList = [];
      this.cabinOffers
        .filter((x) => this.bookings[i].cabinId.includes(x.cabinId))
        .forEach((value) => {
          if (value.email !== "" && value.email !== null) {
            ;
            emailList.push(value.email);
          }
        });

      this.cabinSettings
        .filter((x) => this.bookings[i].cabinId.includes(x.cabinId))
        .forEach((value) => {
          if (value.emailUser !== "" && value.emailUser !== null) {
            emailList.push(value.emailUser);
          }
          if (value.emailCleaning !== "" && value.emailCleaning !== null) {
            emailList.push(value.emailCleaning);
          }
        });
      var paymentId = this.bookings[i].paymentId;
      var hostname = window.location.host;

      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/requestApproval.php?approve&paymentId=" +
          paymentId +
          "&emailList=" +
          JSON.stringify(emailList).replace(/\s/g, "") +
          "&hostname=" +
          hostname +
          "&companyEmail=" +
          this.state.companyEmail +
          "&companyName=" +
          this.state.companyName.replace(/\s/g, "") +
          "&companyFromEmail=" +
          this.settings.companyEmail +
          "&paymentMethod=" +
          this.state.paymentMethod +
          "&noRedirect=true"
        )
        .then((response) => {
          alert("Booking was successfully send!");
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  sendBooking(i) {
    var r = window.confirm(
      "Sure you wan't to send booking for this payment id " +
      this.bookings[i].paymentId
    );
    if (r === true) {
      this.bookings[i].companyEmail = this.state.companyEmail;
      this.bookings[i].companyName = this.state.companyName;
      this.bookings[i].companyAddress = this.state.companyAddress;
      this.bookings[i].companyCity = this.state.companyCity;
      this.bookings[i].companyVat = this.state.companyVat;
      this.bookings[i].companyPhone = this.state.companyPhone;
      this.bookings[i].companyFacebook = this.state.companyFacebook;
      this.bookings[i].companyInstagram = this.state.companyInstagram;
      this.bookings[i].companyBooking = this.state.companyBooking;
      this.bookings[i].companyAirbnb = this.state.companyAirbnb;
      this.bookings[i].protocol = this.settings.protocol;
      this.bookings[i].domain = this.settings.domain;

      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/sendBooking.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            alert("Booking was successfully send!");
          } else {
            alert("Booking failed to send");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  sendPinInfo(i) {
    var r = window.confirm(
      "Sure you wan't to send pin info to " + this.bookings[i].email
    );
    if (r === true) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCabinSettings.php",
          { id: JSON.parse(this.bookings[i].cabinId) }
        )
        .then((response) => {
          this.bookings[i].info = response.data;
          this.bookings[i].companyName = this.settings.companyName;
          this.bookings[i].companyEmail = this.settings.companyEmail;
          this.bookings[i].domain = this.settings.domain;
          this.bookings[i].protocol = this.settings.protocol;
          this.axios
            .post(
              "" +
              this.settings.protocol +
              this.settings.domain +
              "/static/php/sendPin.php",
              this.bookings[i]
            )
            .then((response) => {
              if (response.data) {
                alert("Pin was successfully send!");
              } else {
                alert("Pin failed to send");
              }
            });
        });
    }
  }
  refundBooking() {
    const i = this.state.refundInfo.id;
    var r = window.confirm(
      "Sure you wan't to refund this payment id " + this.bookings[i].paymentId
    );
    if (r === true) {
      this.bookings[i].companyEmail = this.state.companyEmail;
      this.bookings[i].companyName = this.state.companyName;
      this.bookings[i].companyAddress = this.state.companyAddress;
      this.bookings[i].companyCity = this.state.companyCity;
      this.bookings[i].companyVat = this.state.companyVat;
      this.bookings[i].companyPhone = this.state.companyPhone;
      this.bookings[i].companyFacebook = this.state.companyFacebook;
      this.bookings[i].companyInstagram = this.state.companyInstagram;
      this.bookings[i].companyBooking = this.state.companyBooking;
      this.bookings[i].companyAirbnb = this.state.companyAirbnb;
      this.bookings[i].protocol = this.settings.protocol;
      this.bookings[i].domain = this.settings.domain;
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/quickpay/cancel.php",
          this.bookings[i]
        )
        .then((response) => {
          if (response.data) {
            this.bookings[i]["changed"] = true;
            if (this.bookings[i].costs === this.bookings[i].refundAmount) {
              this.bookings[i].paymentStatus = "Refunded";
            }
            this.bookings[i].costs =
              this.bookings[i].costs - this.bookings[i].refundAmount;
            this.axios
              .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/sendRefund.php",
                this.bookings[i]
              )
              .then((response) => {
                this.setState({ refundInfo: { open: false } });
                this.save(true);
              });
          } else {
            alert("Refund failed");
          }
        });
    }
  }

  save(refund) {

    if (this.cabinExists) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateBookings.php",
          this.bookings
        )
        .then((response) => {
          if (response.data) {
            if (!refund) {
              alert("Data saved successfully!");
            } else {
              this.setState({ bookings: this.state.bookings ? false : true });
              alert("Refund was successfully!");
            }
          } else {
            alert("Saved failed");
          }
        });
    } else {
      alert("Changed cabin name, is not a valid cabin");
    }
  }
  search(e) {
    var searchBooking = this.placeHolderBookings;

    searchBooking = searchBooking
      .filter((element) => {
        return Object.keys(element).some((k) => {
          return element[k]
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
      })
      .map((element) => {
        return element;
      });
    this.bookings = searchBooking;
    this.setState({ bookings: this.state.bookings ? false : true });
  }
  showMore() {
    this.setState({ showMore: this.state.showMore + 10 });
  }
  radio(e) {
    this.setState({
      radio: e.currentTarget.value,
    });
    if (e.currentTarget.value === "fullAmount") {
      this.bookings[this.state.refundInfo.id].refundAmount = this.bookings[
        this.state.refundInfo.id
      ].costs;
    }
  }
  openRefund(i) {
    this.setState({
      refundInfo: {
        id: i,
        open: true,
        paymentId: this.bookings[i].paymentId,
        fullAmount: this.bookings[i].costs,
      },
    });
    this.bookings[i].refundAmount = this.bookings[i].costs;
  }
  closeRefund() {
    this.setState({ refundInfo: { open: false } });
  }
  onChangeRefund(e) {
    this.bookings[this.state.refundInfo.id].refundAmount =
      e.currentTarget.value;
  }
  validNumber(evt) {
    var theEvent = evt || window.event;
    var key = "";
    // Handle paste
    if (theEvent.type === "paste") {
      key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  getAvailablity() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((value) => {
            this.placeholderInvalidDates.push({
              cabin: value.cabinName,
              dates: [],
            });
            this.cabinsInfo.push(value);
            this.cabins.push(value.cabinName);
          });
        }
      });
  }

  checkAvailablity(index) {
    this.invalidDate = [];
    var placeholderInvalidDates = [];
    JSON.parse(this.bookings[index].cabins).forEach((value) => {
      const findIndex = this.placeholderInvalidDates.findIndex(
        (x) => x.cabin === value
      );
      if (
        placeholderInvalidDates.includes(
          this.placeholderInvalidDates[findIndex]
        ) === false
      ) {
        placeholderInvalidDates.push(this.placeholderInvalidDates[findIndex]);
      }
    });

    this.bookings.forEach((value, i) => {
      if (value.paymentStatus === "Paid" || value.paymentStatus === "Faktura") {
        if (this.bookings[index].id !== value.id) {
          var checkOut = new Date(value.checkOut.replace(",", ""));
          checkOut.setDate(checkOut.getDate() - 1);
          var checkIn = new Date(value.checkIn.replace(",", ""));
          checkIn.setDate(checkIn.getDate() + 1);
          this.dateArray = this.getDates(checkIn, checkOut);
        }

        this.dateArray.forEach((dateArr) => {
          placeholderInvalidDates.map((info) => {
            if (JSON.parse(value.cabins).includes(info.cabin)) {
              if (!info.dates.includes(dateArr)) {
                return info.dates.push(dateArr);
              }
            }
            return null;
          });
          const newDateArr = new Date(dateArr);
          for (var p = 0; p < placeholderInvalidDates.length; p++) {
            if (placeholderInvalidDates[p].dates.includes(dateArr)) {
              if (
                this.invalidDate.includes(
                  newDateArr.getFullYear() +
                  "/" +
                  (newDateArr.getMonth() + 1) +
                  "/" +
                  newDateArr.getDate()
                ) === false
              ) {
                this.invalidDate.push(
                  newDateArr.getFullYear() +
                  "/" +
                  (newDateArr.getMonth() + 1) +
                  "/" +
                  newDateArr.getDate()
                );
              }
            }
          }
        });
      }
    });
  }

  render() {
    var booked = [];
    if (this.bookings.length > 0) {
      booked = this.bookings
        .filter((value, i) => {
          return this.state.showMore >= i ? value : false;
        })
        .map((value, i) => {
          var checkIn =
            new Date(value.checkIn.replace(",", "")).getDate() +
            "/" +
            (new Date(value.checkIn.replace(",", "")).getMonth() + 1) +
            "/" +
            new Date(value.checkIn.replace(",", "")).getFullYear();
          var checkOut =
            new Date(value.checkOut.replace(",", "")).getDate() +
            "/" +
            (new Date(value.checkOut.replace(",", "")).getMonth() + 1) +
            "/" +
            new Date(value.checkOut.replace(",", "")).getFullYear();

          let actualCheckInDate = value.actualCheckIn.length > 0 ? new Date(value.actualCheckIn.replace(",", "")) : "No Date";
          var actualCheckIn;
          if (actualCheckInDate instanceof Date) {
            actualCheckIn =
              actualCheckInDate.getDate() +
              "/" +
              (actualCheckInDate.getMonth() + 1) +
              "/" +
              actualCheckInDate.getFullYear();
          } else {
            actualCheckIn = actualCheckInDate;
          }
          let actualCheckOutDate = value.actualCheckOut.length > 0 ? new Date(value.actualCheckOut.replace(",", "")) : "No Date";

          var actualCheckOut;
          if (actualCheckOutDate instanceof Date) {
            actualCheckOut =
              actualCheckOutDate.getDate() +
              "/" +
              (actualCheckOutDate.getMonth() + 1) +
              "/" +
              actualCheckOutDate.getFullYear();
          } else {
            actualCheckOut = actualCheckOutDate;
          }


          var cabins = JSON.parse(value.cabins).map((element, i) => {
            var comma = "";
            if (JSON.parse(value.cabins).length > i + 1) {
              comma = ", ";
            }
            return element + comma;
          });
          if (value.paymentStatus === "Not Paid") {
            return null;
          }
          return (
            <tr className={value.isFromAirbnb === "1" ? 'disabled' : ''} key={i}>
              <td>
                <a
                  className="pdf"
                  rel="noopener noreferrer"
                  href={
                    this.settings.protocol +
                    this.settings.domain +
                    "/static/booking/booking-" +
                    value.paymentId +
                    ".pdf"
                  }
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </a>
              </td>
              <td>{value.reg_date}</td>
              <td>{value.paymentId}</td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "cabins");
                  }}
                >
                  {" "}
                  {cabins}
                </div>
              </td>
              <td>
                <div>
                  {checkIn}
                </div>
              </td>
              <td>
                <div>{checkOut}</div>
              </td>
              <td>
                <DatePicker
                  singleDatePicker={true}
                  autoApply={true}
                  startDate={actualCheckInDate instanceof Date ? new Date(value.actualCheckIn.replace(",", "")) : new Date()}
                  locale={{ format: "D.M.YYYY" }}
                  disabledDates={["02/25/2015"]}
                  // isInvalidDate={(date) => {
                  //   this.checkAvailablity(i);
                  //   const d = new Date(date._d);

                  //   if (
                  //     this.invalidDate.includes(
                  //       d.getFullYear() +
                  //       "/" +
                  //       (d.getMonth() + 1) +
                  //       "/" +
                  //       d.getDate()
                  //     )
                  //   ) {
                  //     return true;
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                  onApply={(e, p) => {
                    this.updateBookings(p, i, "actualCheckIn");
                  }}
                >
                  {actualCheckIn}
                </DatePicker>
              </td>
              <td>
                <DatePicker
                  singleDatePicker={true}
                  autoApply={true}
                  startDate={actualCheckOutDate instanceof Date ? new Date(value.actualCheckOut) : new Date()}
                  locale={{ format: "D.M.YYYY" }}
                  // isInvalidDate={(date) => {
                  //   this.checkAvailablity(i);
                  //   const d = new Date(date._d);

                  //   if (
                  //     this.invalidDate.includes(
                  //       d.getFullYear() +
                  //       "/" +
                  //       (d.getMonth() + 1) +
                  //       "/" +
                  //       d.getDate()
                  //     )
                  //   ) {
                  //     return true;
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                  onApply={(e, p) => {
                    this.updateBookings(p, i, "actualCheckOut");
                  }}
                >
                  {actualCheckOut}
                </DatePicker>
              </td>
              <td>
                <div
                // suppressContentEditableWarning={true}
                // contentEditable="true"
                // onInput={e => {
                //   this.updateBookings(e, i, "guests");
                // }}
                >
                  {" "}
                  {value.guests}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "firstName");
                  }}
                >
                  {" "}
                  {value.isFromAirbnb === "1" ? "airbnb" : value.firstName}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "lastName");
                  }}
                >
                  {" "}
                  {value.lastName}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "email");
                  }}
                >
                  {" "}
                  {value.email}
                </div>
              </td>

              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "costs");
                  }}
                >
                  {" "}
                  {value.costs}
                </div>
              </td>
              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "paymentStatus");
                  }}
                >
                  {" "}
                  {value.paymentStatus}
                </div>
              </td>

              <td>
                <div
                  suppressContentEditableWarning={true}
                  contentEditable="true"
                  onInput={(e) => {
                    this.updateBookings(e, i, "comment");
                  }}
                >
                  {" "}
                  {value.comment}
                </div>
              </td>
              <td>
                <div
                  className="action-btn"
                  onClick={() => {
                    this.sendBooking(i);
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                  <p>Send booking</p>
                </div>
                <div
                  onClick={() => {
                    this.openRefund(i);
                  }}
                  className="action-btn"
                >
                  <FontAwesomeIcon icon={faCreditCard} />
                  <p>Refund</p>
                </div>
                <div
                  className="action-btn"
                  onClick={() => {
                    this.deleteBooking(i);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  <p>Delete</p>
                </div>
                {value.paymentStatus === "On request" ? (
                  <div
                    className="action-btn"
                    onClick={() => {
                      this.onRequest(i);
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <p>Approve</p>
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          );
        });
    }
    return (
      <content id="booked">
        <div className="wrap-content">
          <div
            onClick={() => {
              this.save();
            }}
            className="save-btn"
          >
            <div>Save</div>
          </div>
          <div className="front-page-content">
            <div className="headline">
              <h3>Booked</h3>
              <div className="search">
                <FontAwesomeIcon icon={faSearch} />
                <input
                  onChange={(e) => {
                    this.search(e);
                  }}
                />
              </div>
            </div>
            {this.state.refundInfo.open ? (
              <div className="refund-ammount">
                <div>Payment ID {this.state.refundInfo.paymentId}</div>
                <div className="full-amount">
                  <input
                    type="radio"
                    value="fullAmount"
                    checked={this.state.radio === "fullAmount"}
                    onChange={(e) => {
                      this.radio(e);
                    }}
                  />{" "}
                  <div className="text">
                    Full refund (DKK {this.state.refundInfo.fullAmount})
                  </div>
                </div>
                <div className="partial-ammount">
                  <input
                    type="radio"
                    value="partialAmount"
                    checked={this.state.radio === "partialAmount"}
                    onChange={(e) => {
                      this.radio(e);
                    }}
                  />{" "}
                  <div className="text"> Parital refund</div>
                  {this.state.radio === "partialAmount" ? (
                    <span>
                      <div className="text amount">Amount*</div>
                      <input
                        type="text"
                        onPaste={(e) => {
                          this.validNumber(e);
                        }}
                        onKeyPress={(e) => {
                          this.validNumber(e);
                        }}
                        onChange={(e) => {
                          this.onChangeRefund(e);
                        }}
                        placeholder="0"
                      />
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div
                  className="close-btn"
                  onClick={() => {
                    this.closeRefund();
                  }}
                >
                  CLOSE
                </div>
                <div
                  className="refund-btn "
                  onClick={() => {
                    this.refundBooking();
                  }}
                >
                  REFUND
                </div>
              </div>
            ) : (
              ""
            )}
            <table>
              <tbody>
                <tr>
                  <th>PDF</th>
                  <th
                    onClick={() => {
                      this.sort("registerDate");
                    }}
                  >
                    Register date
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "registerDate"
                          ? this.state.registerDate.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("paymentId");
                    }}
                  >
                    Payment id
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "paymentId"
                          ? this.state.paymentId.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("cabins");
                    }}
                  >
                    Cabins
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "cabins"
                          ? this.state.cabins.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("checkIn");
                    }}
                  >
                    Blocked from
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "checkIn"
                          ? this.state.checkIn.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("checkOut");
                    }}
                  >
                    Blocked to
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "checkOut"
                          ? this.state.checkOut.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("actualCheckIn");
                    }}
                  >
                    Check in
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "actualCheckIn"
                          ? this.state.actualCheckIn.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("chactualCheckOut");
                    }}
                  >
                    Check out
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "chactualCheckOut"
                          ? this.state.chactualCheckOut.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("guests");
                    }}
                  >
                    Guests
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "guests"
                          ? this.state.guests.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("firstName");
                    }}
                  >
                    First name
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "firstName"
                          ? this.state.firstName.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("lastName");
                    }}
                  >
                    Last name
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "lastName"
                          ? this.state.lastName.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("email");
                    }}
                  >
                    Email
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "email"
                          ? this.state.email.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>

                  <th
                    onClick={() => {
                      this.sort("costs");
                    }}
                  >
                    Costs
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "costs"
                          ? this.state.costs.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      this.sort("paymentStatus");
                    }}
                  >
                    Payment status
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "paymentStatus"
                          ? this.state.paymentStatus.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>

                  <th
                    onClick={() => {
                      this.sort("comment");
                    }}
                  >
                    Comment
                    <FontAwesomeIcon
                      icon={
                        this.state.define === "comment"
                          ? this.state.comment.type === "asc"
                            ? faSortAmountDownAlt
                            : faSortAmountUpAlt
                          : faFilter
                      }
                    />
                  </th>
                  <th>Action</th>
                </tr>
                {booked}
              </tbody>
            </table>
            <div className={this.state.loaded ? "disableSpinner" : ""}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
            <div
              onClick={() => {
                this.showMore();
              }}
              className="showMore"
            >
              Show more
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default Booked;
