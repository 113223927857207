import React, { Component } from "react";
import settings from "../../settings.json";
import "./message.scss";
import { withRouter } from "react-router-dom";
import translate from "../../translate.json";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.axios = require("axios");
    this.queryString = require("query-string");
    this.urlParameters = this.queryString.parse(this.props.location.search);
  }

  render() {
    var text;
    if (this.urlParameters.approve === "true") {
      text = (
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>{translate.messageAprove.headline[settings.lang]}</h1>
            </div>
            {translate.messageAprove.message[settings.lang]}
            <br />
            <br />
            {translate.bestReg[settings.lang]}, <br />
            {translate.bookLocal[settings.lang]}
          </div>
        </div>
      );
    }
    if (this.urlParameters.approve === "false") {
      text = (
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>{translate.messageDecline.headline[settings.lang]}</h1>
            </div>
            {translate.messageDecline.message[settings.lang]}
            <br />
            <br />
            {translate.bestReg[settings.lang]}, <br />
            {translate.bookLocal[settings.lang]}
          </div>
        </div>
      );
    }
    if (this.urlParameters.onRequest === "true") {
      text = (
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>
                {translate.thanks[settings.lang]} {this.urlParameters.firstName}
              </h1>
            </div>
            {translate.messageRequests.headline[settings.lang]}
            <br />
            <br />
            {translate.messageRequests.message[settings.lang]}
            <br />
            <br />
            {translate.bestReg[settings.lang]}, <br />
            {translate.bookLocal[settings.lang]}
          </div>
        </div>
      );
    }
    if (this.urlParameters.faktura === "true") {
      text = (
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <h1>
                {translate.thanks[settings.lang]} {this.urlParameters.firstName}
              </h1>
            </div>
            {translate.messageFaktura.headline[settings.lang]}
            <br />
            <br />
            {translate.messageFaktura.message[settings.lang]}
          </div>
        </div>
      );
    }
    return <content id="message">{text}</content>;
  }
}

export default withRouter(Message);
