import React, { Component } from "react";
import settings from "../settings.json";
import Header from "./header/header";
import Footer from "./footer/footer";
import Content from "./content/content";
import Cabins from "./cabins/cabins";
import Package from "./package/package";
import Confirmation from "./confirmation/confirmation";
import AdminCabin from "./admin/cabins/cabins";
import AdminCalendar from "./admin/calendar/calendar";
import Admin from "./admin/admin";
import Sidebar from "./sidebar/sidebar";
import Coupons from "./admin/coupons/coupons";
import Overview from "./admin/overview/overview";
import Page from "./admin/page/page";
import Booked from "./admin/booked/booked";
import BookedPackages from "./admin/bookedPackages/bookedPackages";
import Availability from "./availability/availability";
import PackageAvailability from "./packageAvailability/packageAvailability";
import CampingAvailability from "./campingAvailability/campingAvailability";
import { BrowserRouter as Switch, Route, HashRouter } from "react-router-dom";
import Bookingdetails from "./bookingdetails/bookingdetails";
import MetaTags from "react-meta-tags";
import { MobileView } from "react-device-detect";
import PackageOffers from "./admin/packages/packages";
import terms from "./terms/terms";
import Policy from "./policy/policy";
import Settings from "./admin/settings/settings";
import ReactGA from "react-ga";

import CabinOffers from "./cabinOffers/cabinOffers";
import Reports from "./admin/reports/reports";
import AdminCamping from "./admin/camping/camping";
import BookedCamping from "./admin/bookedCamping/bookedCamping";
import Camping from "./camping/camping";
import message from "./message/message";
import setting from "../settings.json";
class Main extends Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("lang")) {
      setting.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "en");
    }
   
    this.parameterPass = {
      home: React.createRef(),
      booking: React.createRef(),
      about: React.createRef(),
      exclusive: React.createRef(),
      camping: React.createRef(),
      contact: React.createRef(),
      click: "",
    };

    this.state = {
      click: "",
      scrollHome: this.parameterPass.home,
      scrollBooking: this.parameterPass.booking,
      scrollAbout: this.parameterPass.about,
      scrollExclusive: this.parameterPass.exclusive,
      scrollCamping: this.parameterPass.camping,
      scrollContact: this.parameterPass.contact,
    };

    this.contentInjection = [
      {
        to: "/",
        component: Content,
      },
      {
        to: "/cabins",
        component: Cabins,
      },
      {
        to: "/package",
        component: Package,
      },
      {
        to: "/camping",
        component: Camping,
      },
      {
        to: "/availability",
        component: Availability,
      },
      {
        to: "/packageAvailability",
        component: PackageAvailability,
      },
      {
        to: "/campingAvailability",
        component: CampingAvailability,
      },
      {
        to: "/cabinOffers",
        component: CabinOffers,
      },
      {
        to: "/bookingdetails",
        component: Bookingdetails,
      },
      {
        to: "/confirmation",
        component: Confirmation,
      },
      {
        to: "/message",
        component: message,
      },
      {
        to: "/terms",
        component: terms,
      },
      {
        to: "/policy",
        component: Policy,
      },
      {
        to: "/admin",
        component: Admin,
      },
      {
        to: "/sidebar/sidebar",
        component: Sidebar,
      },
      {
        to: "/admin/overview",
        component: Overview,
      },
      {
        to: "/admin/page",
        component: Page,
      },
      {
        to: "/admin/settings",
        component: Settings,
      },
      {
        to: "/admin/reports",
        component: Reports,
      },
      {
        to: "/admin/cabins",
        component: AdminCabin,
      },
      {
        to: "/admin/calendar",
        component: AdminCalendar,
      },
      {
        to: "/admin/camping",
        component: AdminCamping,
      },
      {
        to: "/admin/packages",
        component: PackageOffers,
      },
      {
        to: "/admin/booked",
        component: Booked,
      },
      {
        to: "/admin/bookedPackages",
        component: BookedPackages,
      },
      {
        to: "/admin/bookedCamping",
        component: BookedCamping,
      },
      {
        to: "/admin/coupons",
        component: Coupons,
      },
    ];
    this.content = [];
    this.contentInjection.forEach((element) => {
      if (
        element.to.includes("/admin/") &&
        sessionStorage.getItem("userLogin")
      ) {
        this.content.push(element);
      } else if (!element.to.includes("/admin/")) {
        this.content.push(element);
      }
    });
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      customer:
        window.location.hostname !== "localhost" ? "" : settings.customer,
    };
    this.axios = require("axios");
    this.getSettings();
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          ReactGA.initialize(response.data[0].ga);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      });
  }
  render() {
    const routes = this.content.map((route, i) => {
      return (
        <Route
          key={i}
          exact
          path={route.to}
          component={() => (
            <route.component parameterPass={this.parameterPass} />
          )}
        />
      );
    });
    var selectedRegion = window.location.hash === "#/chooseregion";

    return (
      <div className="App">
        <MobileView>
          <MetaTags>
            <meta
              name="viewport"
              content="width=device-width, user-scalable=no"
            />
          </MetaTags>
        </MobileView>

        <Switch>
          <HashRouter>
            {sessionStorage.getItem("userLogin") ? <Sidebar /> : ""}
            {selectedRegion ? "" : <Header state={this.state} />}
            <main>{routes}</main>
            {selectedRegion ? "" : <Footer />}
          </HashRouter>
        </Switch>
      </div>
    );
  }
}

export default Main;
