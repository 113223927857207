import React, { Component } from "react";
import settings from "../../settings.json";
import { comment } from "../../assets/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ReactHtmlParser from "react-html-parser";
import "./cabins.scss";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Helmet } from "react-helmet";
import translate from "../../translate.json";

class Cabins extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
    };
    this.currentDate = new Date();
    this.currentDate.setHours(0,0,0,0);
    this.queryString = require("query-string");
    this.bookedDates = [];
    this.invalidDate = [];
    this.clientInfo = sessionStorage.getItem("clientInfo")
      ? JSON.parse(sessionStorage.getItem("clientInfo"))
      : { startDate: "", endDate: "", guests: 2 };
    this.state = {
      startDate: "",
      textShort: "",
      endDate: "",
      modalIsOpen: false,
      customersWord: [],
      selectedIndex: 0,
      cabinName: this.queryString.parse(this.props.location.search).cabin,
      cabinId: this.queryString.parse(this.props.location.search).id,
      mapedCabins: [],
      mainImage: "",
    };
    this.axios = require("axios");
    this.getCabins();
    this.getFrontPage();
  }
  scrollToFu = () => {
    var widthPerc = window.innerWidth;
    if (widthPerc <= 768) {
      setTimeout(() => {
        window.scrollTo({
          top: widthPerc,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  getBooked() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBooking.php"
      )
      .then(function (response) {
        // handle success
        return response.data;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  }

  getFrontPage() {
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getFrontPage.php"
      )
      .then((response) => {
        this.setState({
          customersWord: JSON.parse(response.data.customersWord),
        });
      });
  }

  getCabins() {
    var dateValueOf = new Date().valueOf();
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php"
      )
      .then((response) => {
        var cabinsImage = [];
        var cabinsLargeImage = [];
        response.data.forEach((cabin, i) => {
          if (cabin.id === this.state.cabinId) {
            this.setState({
              maxGuests: cabin.maxGuests,
              minDates: cabin.minDates,
              mainImage: cabin.mainImage,
              cabinName: cabin.cabinName,
            });
            JSON.parse(cabin.gallery).forEach((value, index) => {
              const img =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/large_" +
                value +
                "?" +
                dateValueOf;
              const imgThumb =
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/medium_" +
                value +
                "?" +
                dateValueOf;
              cabinsLargeImage.push({ src: img });
              cabinsImage.push(
                <div
                  key={index}
                  onClick={() => this.toggleModal(index)}
                  className="cabin-image"
                >
                  <img alt="gallery" src={imgThumb} />
                </div>
              );
            });
          }
        });
        this.setState({
          cabinsImage,
          cabinsLargeImage,
        });

        var placeholderInvalidDates = [
          { cabin: this.state.cabinName, dates: [] },
        ];
        response.data.map((cabin, i) => {
          placeholderInvalidDates.push({
            cabin: cabin.cabinName,
            dates: [],
            id: cabin.id,
          });
          return cabin;
        });
        var cabinInfo = "";
        response.data.forEach((cabin) => {
          if (cabin.id === this.state.cabinId) {
            if (settings.lang === "en") {
              cabinInfo = (
                <span>
                  {cabin.onRequest === "1" ? <i>{translate.requestBook.en}</i> : ""}
                  <h1>{cabin.cabinName}</h1>
                  {ReactHtmlParser(cabin.textLong)}
                </span>
              );
              this.setState({ textShort: cabin.textShort });
            } else {
              cabinInfo = (
                <span>
                  {cabin.onRequest === "1" ? <i>{translate.requestBook.fo}</i> : ""}
                  <h1>{cabin.cabinName}</h1>
                  {ReactHtmlParser(cabin.textLongFo)}
                </span>
              );
              this.setState({ textShort: cabin.textShortFo });

            }
          }
        });

        this.setState({ cabinInfo }, () => {
          this.getBooked().then((value) => {
            this.bookedDates = value === "" ? [""] : value;
            this.bookedDates.forEach((value, i) => {
              if (
                value.paymentStatus === "Paid" ||
                value.paymentStatus === "Faktura"
              ) {
                var checkIn = new Date(value.checkIn.replace(",", ""));
                checkIn.setDate(checkIn.getDate());
                checkIn.setHours(0, 0, 0, 0);
                var checkOut = new Date(value.checkOut.replace(",", ""));
                checkOut.setDate(checkOut.getDate());
                checkOut.setHours(0, 0, 0, 0);
                var dateArray = this.getDates(checkIn, checkOut);

                dateArray.forEach((dateArr) => {

                  placeholderInvalidDates.map((info) => {
                    if (value.cabinId !== "") {
                      if (
                        JSON.parse(value.cabinId).includes(this.state.cabinId)
                      ) {
                        if (!info.dates.includes(dateArr)) {
                          if (dateArr !== checkOut.valueOf()) {
                            console.log()
                            return info.dates.push(dateArr);
                            // }
                          }
                        }
                      }
                    } else {
                      if (
                        JSON.parse(value.cabins).includes(this.state.cabinName)
                      ) {
                        if (!info.dates.includes(dateArr)) {
                          if (dateArr !== checkIn.valueOf()) {
                            if (dateArr !== checkOut.valueOf()) {
                              return info.dates.push(dateArr);
                            }
                          }
                        }
                      }
                    }
                    return null;
                  });

                  placeholderInvalidDates.forEach((element) => {
                    if (element.dates.includes(dateArr)) {
                      var setDateArr = new Date(dateArr);
                      setDateArr.setDate(setDateArr.getDate());
                      setDateArr.setHours(0, 0, 0, 0);

                      var pushDate =
                        setDateArr.getFullYear() +
                        "/" +
                        (setDateArr.getMonth() + 1) +
                        "/" +
                        setDateArr.getDate();

                      if (!this.invalidDate.includes(pushDate)) {
                        this.invalidDate.push(pushDate);
                      }
                    }
                  });
                });
              }
            });
            this.loopeDate = new Date();
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    while (dt < end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  handleEvent = (event, picker) => {
    var startDate = new Date(picker.startDate._d);
    var endDate = new Date(picker.endDate._d);
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    const oneDay = 24 * 60 * 60 * 1000;
    this.days = Math.round(
      Math.abs((new Date(startDate) - new Date(endDate)) / oneDay)
    );
    var error = false;
    this.loopDate = new Date(picker.startDate._d);

    for (var i = 0; i <= this.days; i++) {
      if (this.loopDate <= endDate) {
        if (
          this.invalidDate.includes(
            this.loopDate.getFullYear() +
            "/" +
            (this.loopDate.getMonth() + 1) +
            "/" +
            this.loopDate.getDate()
          )
        ) {
          error = true;
        }
      }

      this.loopDate.setDate(this.loopDate.getDate() + 1);
    }
    if (error) {
      this.setState({
        occupied: "Some or all dates in your selected range are sold out",
        dateRange: "",
        startDate: "",
        endDate: "",
      });
    } else {
      this.setState({
        occupied: false,
      });
      this.setState({
        dateRange:
          "startDate=" +
          startDate.getFullYear() +
          "/" +
          (startDate.getMonth() + 1) +
          "/" +
          startDate.getDate() +
          "&endDate=" +
          endDate.getFullYear() +
          "/" +
          (endDate.getMonth() + 1) +
          "/" +
          endDate.getDate(),
      });
      this.setState({
        startDate:
          startDate.getDate() +
          "." +
          (startDate.getMonth() + 1) +
          "." +
          startDate.getFullYear(),
        endDate:
          endDate.getDate() +
          "." +
          (endDate.getMonth() + 1) +
          "." +
          endDate.getFullYear(),
      });
      this.clientInfo.startDate =
        startDate.getFullYear() +
        "/" +
        (startDate.getMonth() + 1) +
        "/" +
        startDate.getDate();
      this.clientInfo.endDate =
        endDate.getFullYear() +
        "/" +
        (endDate.getMonth() + 1) +
        "/" +
        endDate.getDate();
      sessionStorage.setItem("clientInfo", JSON.stringify(this.clientInfo));
    }
  };

  toggleModal = (index) => {
    this.setState(() => ({
      modalIsOpen: !this.state.modalIsOpen,
      selectedIndex: index,
    }));
  };

  render() {
    var dateValueOf = new Date().valueOf();
    const { modalIsOpen } = this.state;
    var regex = /(<([^>]+)>)/gi,
      body = this.state.textShort,
      result = body.replace(regex, "");
    return (
      <content id="cabins">
        <Helmet>
          <title>{this.state.cabinName}</title>
          <meta id="meta-description" name="description" content={result} />
          <meta property="og:type" content="Rent houses" />
          <meta
            id="og-title"
            property="og:title"
            content={this.state.cabinName}
          />
          <meta
            id="2"
            property="og:image"
            content={
              this.state.mainImage !== ""
                ? "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/full_" +
                this.state.mainImage +
                "?" +
                dateValueOf
                : ""
            }
          />
        </Helmet>
        <div className="carousel">
          <img
            alt="img"
            src={
              this.state.mainImage !== ""
                ? "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/uploads/full_" +
                this.state.mainImage +
                "?" +
                dateValueOf
                : ""
            }
          />
        </div>
        <div className="about">
          <div className="about-content1">
            {this.state.cabinInfo}
            <div className="all-btn">
              {this.state.minDates !== "0" ? (
                <Link
                  className="book-now"
                  to={"availability?selectedHouse=" + this.state.cabinId}
                >
                  <div>{translate.startBooking[settings.lang]}</div>
                </Link>
              ) : (
                <div className="book-now sold-out">
                  <div>{translate.unavailable[settings.lang]}</div>
                </div>
              )}
            </div>
          </div>
          <div className="about-content2">
            <p>{translate.checkAvail[settings.lang]}</p>
            <div className="check-availabilty">
              <FontAwesomeIcon icon={faCalendarAlt} />
              <DateRangePicker
                autoApply={true}
                minDate={this.currentDate}
                locale={{ format: "D.M.YYYY" }}
                disabledDates={[this.currentDate]}
                isInvalidDate={(date) => {
                  const d = new Date(date._d);
                  if (
                    this.invalidDate.includes(
                      d.getFullYear() +
                      "/" +
                      (d.getMonth() + 1) +
                      "/" +
                      d.getDate()
                    ) || d.valueOf() === this.currentDate.valueOf() 
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                onApply={this.handleEvent}
              >
                <input
                  onClick={() => this.scrollToFu()}
                  readOnly
                  className="calendar"
                  placeholder="Pick a date"
                  type="text"
                  value={
                    this.state.startDate.length > 0
                      ? this.state.startDate + " - " + this.state.endDate
                      : ""
                  }
                />
              </DateRangePicker>
              <Link
                to={
                  "availability?" +
                  this.state.dateRange +
                  "&selectedHouse=" +
                  this.state.cabinId
                }
              >
                <input className="go-button" type="button" value="GO" />
              </Link>
              {this.state.occupied ? (
                <div className="occupied">{this.state.occupied}</div>
              ) : (
                ""
              )}
            </div>

            <div>
              <div className="comment">
                <img alt="img" src={comment} />
                <p>{translate.fewWord[settings.lang]}:</p>
                {ReactHtmlParser(this.state.customersWord.comment)}
              </div>
            </div>
          </div>
        </div>
        <div className="cabin-gallery">
          <div className="wrap-cabin-gallery">
            <h1>{translate.gallery[settings.lang]}</h1>
            {this.state.cabinsImage}
            <ModalGateway>
              {modalIsOpen ? (
                <Modal onClose={this.toggleModal}>
                  <Carousel
                    currentIndex={this.state.selectedIndex}
                    views={this.state.cabinsLargeImage}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(Cabins);
