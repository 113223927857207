import React, { Component } from "react";
import settings from "../../settings.json";
import "./policy.scss";
import { withRouter } from "react-router-dom";
class Policy extends Component {
  constructor(props) {
    super(props);
    this.axios = require("axios");
    this.state = {
      email: "",
      name: "",
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.getSettings();
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        this.setState({
          email: response.data[0].email,
          name: response.data[0].name,
        });
      });
  }
  render() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return (
      <content id="confirmation">
        <div className="wrap-detail-content">
          <div className="details-form">
            <div className="headline">
              <p >
                <strong>
                  <span >
                    Generic Privacy Policy Template
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  This privacy policy will help you understand how Booklocal
                  uses and protects the data you provide to us when you visit
                  and use booklocal.fo.
                </span>
              </p>
              <p >
                <span>
                  We reserve the right to change this policy at any given time,
                  of which you will be promptly updated. If you want to make
                  sure that you are up to date with the latest changes, we
                  advise you to frequently visit this page.
                </span>
              </p>
              <p >
                <strong>
                  <span>
                    What User Data We Collect
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  When you visit the website, we may collect the following data:
                </span>
              </p>
              <ul >
                <li>
                  <span>
                    Your IP address.
                  </span>
                </li>
                <li>
                  <span>
                    Your contact information and email address.
                  </span>
                </li>
                <li>
                  <span>
                    Other information such as interests and preferences.
                  </span>
                </li>
                <li>
                  <span>
                    Data profile regarding your online behavior on our website.
                  </span>
                </li>
              </ul>
              <p >
                <strong>
                  <span>
                    Why We Collect Your Data
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  We are collecting your data for several reasons:
                </span>
              </p>
              <ul >
                <li>
                  <span>
                    To better understand your needs.
                  </span>
                </li>
                <li>
                  <span>
                    To improve our services and products.
                  </span>
                </li>
                <li>
                  <span>
                    To send you promotional emails containing the information we
                    think you will find interesting.
                  </span>
                </li>
                <li>
                  <span>
                    To contact you to fill out surveys and participate in other
                    types of market research.
                  </span>
                </li>
                <li>
                  <span>
                    To customize our website according to your online behavior
                    and personal preferences.
                  </span>
                </li>
              </ul>
              <p >
                <strong>
                  <span>
                    Safeguarding and Securing the Data
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  Booklocal is committed to securing your data and keeping it
                  confidential.{" "}
                  <span >
                    Booklocal&nbsp;
                  </span>
                  has done all in its power to prevent data theft, unauthorized
                  access, and disclosure by implementing the latest technologies
                  and software, which help us safeguard all the information we
                  collect online.
                </span>
              </p>
              <p >
                <strong>
                  <span>
                    Our Cookie Policy
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  Once you agree to allow our website to use cookies, you also
                  agree to use the data it collects regarding your online
                  behavior (analyze web traffic, web pages you spend the most
                  time on, and websites you visit).
                </span>
              </p>
              <p >
                <span>
                  The data we collect by using cookies is used to customize our
                  website to your needs. After we use the data for statistical
                  analysis, the data is completely removed from our systems.
                </span>
              </p>
              <p >
                <span>
                  Please note that cookies don&apos;t allow us to gain control
                  of your computer in any way. They are strictly used to monitor
                  which pages you find useful and which you do not so that we
                  can provide a better experience for you.
                </span>
              </p>
              <p >
                <span>
                  If you want to disable cookies, you can do it by accessing the
                  settings of your internet browser. (Provide links for cookie
                  settings for major internet browsers).
                </span>
              </p>
              <p >
                <strong>
                  <span>
                    Links to Other Websites
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  Our website contains links that lead to other websites. If you
                  click on these links{" "}
                  <span >
                    Booklocal
                  </span>{" "}
                  is not held responsible for your data and privacy protection.
                  Visiting those websites is not governed by this privacy policy
                  agreement. Make sure to read the privacy policy documentation
                  of the website you go to from our website.
                </span>
              </p>
              <p >
                <strong>
                  <span>
                    Restricting the Collection of your Personal Data
                  </span>
                </strong>
              </p>
              <p >
                <span>
                  At some point, you might wish to restrict the use and
                  collection of your personal data. You can achieve this by
                  doing the following:
                </span>
              </p>
              <p >
                <span>
                  When you are filling the forms on the website, make sure to
                  check if there is a box which you can leave unchecked, if you
                  don&apos;t want to disclose your personal information.
                </span>
              </p>
              <p >
                <span>
                  If you have already agreed to share your information with us,
                  feel free to contact us via email and we will be more than
                  happy to change this for you.
                </span>
              </p>
              <p >
                <span >
                  Booklocal
                </span>
                <span>
                  &nbsp;will not lease, sell or distribute your personal
                  information to any third parties, unless we have your
                  permission. We might do so if the law forces us. Your personal
                  information will be used when we need to send you promotional
                  materials if you agree to this privacy policy.
                </span>
              </p>
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(Policy);
