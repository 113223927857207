import React, { Component } from "react";
import settings from "../../../settings.json";
import "./settings.scss";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: "",
      new: true,
      domain: "",
      name: "",
      email: "",
      address: "",
      city: "",
      phone: "",
      facebook: "",
      instagram: "",
      airbnb: "",
      booking: "",
      vatnr: "",
      ga: "",
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
    };
    this.axios = require("axios");
    this.getSettings();
  }
  selectedsettings(e, define) {
    if (define === "mailgunApi") {
      this.setState({ mailgunApi: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "mailgunDomain") {
      this.setState({ mailgunDomain: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "quickpayApi") {
      this.setState({ quickpayApi: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "payment") {
      this.setState({ payment: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "domain") {
      this.setState({ domain: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "email") {
      this.setState({ email: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "name") {
      this.setState({ name: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "address") {
      this.setState({ address: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "city") {
      this.setState({ city: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "phone") {
      this.setState({ phone: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "facebook") {
      this.setState({ facebook: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "instagram") {
      this.setState({ instagram: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "airbnb") {
      this.setState({ airbnb: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "booking") {
      this.setState({ booking: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "vatnr") {
      this.setState({ vatnr: e.target.value }, () => {
        this.save();
      });
    }
    if (define === "ga") {
      this.setState({ ga: e.target.value }, () => {
        this.save();
      });
    }
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            quickpayApi: response.data[0].quickpayapi,
            mailgunApi: response.data[0].mailgunapi,
            mailgunDomain: response.data[0].mailgunDomain,
            payment: response.data[0].payment,
            domain: response.data[0].domain,
            name: response.data[0].name,
            email: response.data[0].email,
            address: response.data[0].adress,
            city: response.data[0].city,
            phone: response.data[0].phone,
            facebook: response.data[0].facebook,
            instagram: response.data[0].instagram,
            airbnb: response.data[0].airbnb_com,
            booking: response.data[0].booking_com,
            vatnr: response.data[0].vatnr,
            ga: response.data[0].ga,
            new: false,
          });
        } else {
          this.setState({ new: true });
        }
      });
  }
  save() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateSettings.php",
        this.state
      )
      .then((response) => {
      });
  }
  render() {
    return (
      <content id="settings">
        <div className="wrap-content">
          <div className="front-settings-content">
            <h3 className="headline">Payment Settings</h3>
            <div className="select-settings">
              <div className="setting-input">
                {" "}
                <label htmlFor="Faktura">Faktura</label>
                <input
                  onChange={(e) => {
                    this.selectedsettings(e, "payment");
                  }}
                  checked={this.state.payment === "faktura"}
                  type="radio"
                  id="faktura"
                  name="settings"
                  value="faktura"
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="Creditcard">Quickpay (Creditcard)</label>
                <input
                  onChange={(e) => {
                    this.selectedsettings(e, "payment");
                  }}
                  type="radio"
                  checked={this.state.payment === "creditcard"}
                  id="creditcard"
                  name="settings"
                  value="creditcard"
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="Creditcard">QucikPay Api</label>
                <input
                disabled={this.state.payment !== "creditcard"}
                  onChange={(e) => {
                    this.selectedsettings(e, "quickpayApi");
                  }}
                  type="text"
                  id="creditcard"
                  name="settings"
                  defaultValue={this.state.quickpayApi}
                />
              </div>
            </div>
          </div>
          <div className="front-settings-content">
            <h3 className="headline">Mail Service</h3>
            <div className="select-settings">
              
              
              <div className="setting-input">
                {" "}
                <label htmlFor="Creditcard">Mailgun Api</label>
                <input
                  onChange={(e) => {
                    this.selectedsettings(e, "mailgunApi");
                  }}
                  type="text"
                  id="creditcard"
                  name="settings"
                  defaultValue={this.state.mailgunApi}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="Creditcard">Mailgun Domain</label>
                <input
                  onChange={(e) => {
                    this.selectedsettings(e, "mailgunDomain");
                  }}
                  type="text"
                  id="creditcard"
                  name="settings"
                  defaultValue={this.state.mailgunDomain}
                />
              </div>
            </div>
          </div>
          <div className="front-settings-content">
            <h3 className="headline">Domain</h3>
            <div className="select-settings">
              <div className="setting-input">
                {" "}
                <label htmlFor="domain">Domain: </label>
                {" " + settings.protocol + settings.domain}
              </div>
            </div>
          </div>
          <div className="front-settings-content">
            <h3 className="headline">Company info</h3>
            <div className="select-settings">
              <div className="setting-input">
                {" "}
                <label htmlFor="name">Name: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "name");
                  }}
                  type="text"
                  name="name"
                  defaultValue={this.state.name}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="email">Email: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "email");
                  }}
                  type="email"
                  name="email"
                  defaultValue={this.state.email}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="address">Address: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "address");
                  }}
                  type="address"
                  name="address"
                  defaultValue={this.state.address}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="city">City: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "city");
                  }}
                  type="city"
                  name="city"
                  defaultValue={this.state.city}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="phone">Phone: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "phone");
                  }}
                  type="phone"
                  name="phone"
                  defaultValue={this.state.phone}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="vatnr">Vat number: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "vatnr");
                  }}
                  type="vatnr"
                  name="vatnr"
                  defaultValue={this.state.vatnr}
                />
              </div>
            </div>
          </div>
          <div className="front-settings-content">
            <h3 className="headline">Social medias</h3>
            <div className="select-settings">
              <div className="setting-input">
                {" "}
                <label htmlFor="facebook">Facebook: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "facebook");
                  }}
                  type="facebook"
                  name="facebook"
                  defaultValue={this.state.facebook}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="instagram">Instagram: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "instagram");
                  }}
                  type="instagram"
                  name="instagram"
                  defaultValue={this.state.instagram}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="airbnb">Airbnb: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "airbnb");
                  }}
                  type="airbnb"
                  name="airbnb"
                  defaultValue={this.state.airbnb}
                />
              </div>
              <div className="setting-input">
                {" "}
                <label htmlFor="booking">Booking: </label>
                <input
                  onBlur={(e) => {
                    this.selectedsettings(e, "booking");
                  }}
                  type="booking"
                  name="booking"
                  defaultValue={this.state.booking}
                />
              </div>
            </div>
            </div>
            <div className="front-settings-content">
              <h3 className="headline">Google Analytics</h3>
              <div className="select-settings">
                <div className="setting-input">
                  {" "}
                  <label htmlFor="ga">Tracking-id </label>
                  <input
                    onBlur={(e) => {
                      this.selectedsettings(e, "ga");
                    }}
                    type="ga"
                    name="ga"
                    defaultValue={this.state.ga}
                  />
                </div>
              </div>
            </div>
         
        </div>
      </content>
    );
  }
}

export default Settings;
