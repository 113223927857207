import React, { Component } from "react";
import settings from "../../../settings.json";
import ImageUploader from "react-images-upload";
import "./packages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown,
  faSortUp,
  faTrashAlt,
  faSpinner,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-bootstrap-daterangepicker";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";

class PackageOffers extends Component {
  uuidv4 = uuidv4;
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };

    this.packagesOffers = [];
    this.axios = require("axios");
    this.getBindPackages(false);
    this.state = {
      packagesOffers: [],
      bindPackages: [],
      mainImage: new Date().getTime(),
      activeList: -1,
      imageLoadGallery: false,
      imageLoadMain: false,
      packageOfferImages: [{ src: "", index: -1, imageCount: -1 }],
    };
    this.uploadMainImage = this.uploadMainImage.bind(this);
  }
  uploadMainImage(picture, index, id, tempId) {
    this.setState({ imageLoadMain: true });
    var packagesOffers = this.state.packagesOffers.map((value, s) => {
      if (value.id === id || (value.tempId === tempId && value.tempId)) {
        value.mainImage = index + "_packages_mainImage.png";
        value.change = true;
      }
      return value;
    });
    this.setState({ packagesOffers });
    this.upload(
      picture[picture.length - 1],
      index + "_packages_mainImage.png"
    ).then((res) => {
      this.setState({ mainImage: new Date().getTime() });
    });
  }

  uploadGallery(picture, index, id) {
    this.setState({ imageLoadGallery: true });
    var newPackageOfferImages = -1;
    var packageOfferImage = [];

    this.state.packageOfferImages.forEach((value, i) => {
      if (value.index === index) {
        newPackageOfferImages = JSON.parse(value.src.slice(0, 1));
        packageOfferImage.push({ src: value.src, index: value.index });
      } else {
        packageOfferImage.push({ src: value.src, index: value.index });
      }
    });

    var packagesOffers = this.state.packagesOffers.map((value, s) => {
      if (id === value.id) {
        var gallery = JSON.parse(value.gallery);
        picture.forEach((value, i) => {
          i = i + 1;
          gallery.push(
            newPackageOfferImages + i + "_packages_gallery_" + index + ".png"
          );
        });
        value.gallery = JSON.stringify(gallery);
        value.change = true;
        return value;
      } else {
        return value;
      }
    });
    picture.forEach((value, i) => {
      i = i + 1;
      packageOfferImage.push({
        src: newPackageOfferImages + i + "_packages_gallery_" + index + ".png",
        index,
      });
    });
    this.setState({ packagesOffers });
    this.upload(picture, "packages_gallery", index, newPackageOfferImages).then(
      () => {
        this.setState({ packageOfferImages: packageOfferImage });
      }
    );
  }
  numberOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
  upload(picture, define, index, startNumber) {
    var formData = new FormData();
    if (define === "packages_gallery") {
      picture.forEach((element, i) => {
        formData.append(
          "fileToUpload[]",
          element,
          startNumber + 1 + i + "_" + define + "_" + index + ".png"
        );
      });
    } else {
      formData.append("fileToUpload", picture, define);
    }
    return this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/uploadImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.setState({
          imageLoadGallery: false,
          imageLoadMain: false,
          fileKey: new Date().getTime(),
        });
        this.setState({});
        if (res.data === 1) {
          return res;
        } else {
          alert(res.data);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  getBindPackages(value) {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getBindPackages.php"
      )
      .then((response) => {
        this.state.bindPackages = response.data === "" ? [] : response.data;
        if (!value) { this.getPackageOffers() } else {
          this.setState({})
        }
      })
  }
  getPackageOffers() {
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getPackages.php"
      )
      .then((response) => {
        var newGallery = [];
        response.data = response.data === "" ? [] : response.data;
        response.data.forEach((data, index) => {
          if (data.weekDays[0] === '"') {
            data.weekDays = data.weekDays.substring(1);
          }
          if (data.weekDays[data.weekDays.length - 1] === '"') {
            data.weekDays = data.weekDays.slice(0, -1);
          }
          data.weekDays = JSON.parse(data.weekDays);
          var gallery = JSON.parse(data.gallery);
          gallery.forEach((image) => {
            newGallery.push({ src: image, index });
          });
          data.specificDate = JSON.parse("[" + data.specificDate + "]");
          data.visibleLang = JSON.parse(data.visibleLang);
        });
        this.setState({
          packagesOffers: response.data,
          packageOfferImages: newGallery,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  save() {
    this.state.packagesOffers.map((value) => {
      return (value.weekDays = JSON.stringify(value.weekDays));
    });

    var info = this.state.packagesOffers.filter(x => x.new || x.change);

    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/updatePackages.php",
        info
      )
      .then((response) => {

        if (typeof response.data === 'object') {
          this.state.packagesOffers = this.state.packagesOffers.map((element) => {
            if (element.tempId === response.data.tempId) {
              element.id = response.data.id;
            }
            return element;
          })
        }

        this.state.bindPackages = this.state.bindPackages.filter(x => x.new || x.change);

        this.axios
          .post(
            "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/updateBindPackages.php",
            this.state.bindPackages
          )
          .then((resp) => {
            this.getBindPackages(true)
            this.state.packagesOffers.map((value) => {
              return (value.weekDays = JSON.parse(value.weekDays));
            });
            this.state.packagesOffers.map((value) => {
              return (value.new = false);
            });
            if (response.data) {
              alert("Data saved successfully!");
              this.setState({});
            } else {
              alert("Saved failed" + response.data);
            }
          });
      });
  }
  openList(index) {
    this.state.activeList !== index
      ? this.setState({ activeList: index })
      : this.setState({ activeList: -1 });
  }
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  inputChange(event, define, index, id = null, tempId = null) {

    if (define === "seletctDatePref") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.seletctDatePref = event.target.value;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "specificDate") {

      this.state.bindPackages = this.state.bindPackages.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.dateRange = event;
          value.change = true;
        }
        return value;
      })
      this.setState({})
    }
    if (define === "start") {
      this.state.bindPackages = this.state.bindPackages.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.start = event.target.value;
          value.change = true;
        }
        return value;
      })
    }
    if (define === "end") {
      this.state.bindPackages = this.state.bindPackages.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.end = event.target.value;
          value.change = true;
        }
        return value;
      })
    }
    if (define === "visibleLang") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          let index = value.visibleLang.findIndex(x => x === event);
          if (index !== -1) {
            value.visibleLang.splice(index, 1);
          } else {
            value.visibleLang.push(event);
          }
          value.change = true;
        }
        return value;
      });
    }
    if (define === "hidden") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.hidden = event.target.checked;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "paymentMethod") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.paymentMethod = event.target.checked;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "extraInfo") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          if (settings.lang === "en") {
            value.extraInfo = event;
          } else {
            value.extraInfoFo = event;
          }
          value.change = true;
        }
        return value;
      });
    }
    if (define === "packageOfferName") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          if (settings.lang === "en") {
            value.packageName = event.target.value;
          } else {
            value.packageNameFo = event.target.value;
          }
          value.change = true;
        }
        return value;
      });
    }
    if (define === "price") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.price = event.target.value;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "cPrice") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.cPrice = event.target.value;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "maxGuests") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.maxGuests = event.target.value;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "dateRange") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          value.dateRange = event.startDate + " - " + event.endDate;
          value.change = true;
        }
        return value;
      });
      this.setState({})
    }

    if (define === "days") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          const findIndex = value.weekDays.findIndex((x) => {
            return x.day === event.target.value;
          });
          value.weekDays[findIndex].checked = event.target.checked;
          value.change = true;
        }
        return value;
      });
    }
    if (define === "textShort") {
      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          if (settings.lang === "en") {
            value.textShort = event;
          } else {
            value.textShortFo = event;
          }
          value.change = true;
        }
        return value;
      });
    }

    if (define === "textLong") {

      this.packagesOffers = this.state.packagesOffers.map((value, s) => {
        if (value.id === id || (value.tempId === tempId && value.tempId)) {
          if (settings.lang === "en") {
            value.textLong = event;
          } else {
            value.textLongFo = event;
          }
          value.change = true;
        }
        return value;
      });
    }
  }
  // onBlur() {
  //   if (this.packagesOffers !== null && this.packagesOffers.length !== 0) {

  //       this.setState({ packagesOffers: this.packagesOffers });
  //   }
  // }
  removePackageOfferImage(src, index) {
    var packagesOffers = this.state.packagesOffers.map((value, s) => {
      if (index === s) {
        var gallery = JSON.parse(value.gallery);
        gallery.splice(gallery.indexOf(src), 1);
        value.gallery = JSON.stringify(gallery);
      }
      return value;
    });

    var packageOfferImages = this.state.packageOfferImages.filter((value) => {
      return value.src !== src;
    });
    this.setState({ packagesOffers, packageOfferImages });
  }

  addPackageOffer() {
    var newPackageOffer = {
      tempId: uuidv4(),
      packageName: "New Package",
      packageNameFo: "New Package",
      price: "",
      maxGuests: "",
      dateRange: "",
      weekDays: [
        { day: "0", checked: true },
        { day: "1", checked: true },
        { day: "2", checked: true },
        { day: "3", checked: true },
        { day: "4", checked: true },
        { day: "5", checked: true },
        { day: "6", checked: true },
      ],
      textShort: "",
      textLong: "",
      mainImage: "",
      gallery: "[]",
      specificDate: [],
      visibleLang: [],
      new: true,
    };
    this.state.packagesOffers.push(newPackageOffer);
    this.setState({ packagesOffers: this.state.packagesOffers });
  }
  deletePackageOffer(id) {
    var r = window.confirm("Sure you wan't to delete this packageOffer ");
    if (r === true) {
      const index = this.state.packagesOffers.findIndex((x) => {
        return x.id === id;
      });
      this.state.packagesOffers.splice(index, 1);
      this.setState({
        packagesOffers: this.state.packagesOffers,
        activeList: -1,
      });
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deletePackageOffer.php",
          { id }
        )
        .then((response) => {
          if (response.data) {
            alert("PackageOffer deleted successfully!");
          } else {
            alert("Deleted failed");
          }
        });
    }
  }
  removeSpecificDate(packageIndex, index) {
    if (this.state.bindPackages[index].id) {
      this.axios
        .post(
          "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteBindPackages.php",
          { id: this.state.bindPackages[index].id }
        )
        .then((response) => {
          this.state.bindPackages.splice(index, 1);
          this.setState({});
        })
    } else {
      this.state.bindPackages.splice(index, 1);
    }
  }
  addSpecificDate(id) {
    var specific = { new: true, dateRange: "2021/6/25", packageId: id, start: '', end: '' }
    this.state.bindPackages.unshift(specific)
    // this.state.packagesOffers.map((value, i) => {
    //   if (i === index) {
    //     value.specificDate.push("");
    //   }
    // });
    this.setState({});
  }
  render() {
    var packagesOffers = this.state.packagesOffers.map(
      (packageOffer, index) => {
        return (
          <li key={index}>
            <div
              className="list"
              onClick={() => {
                this.openList(index);
              }}
            >
              {settings.lang === "en"
                ? packageOffer.packageName
                : packageOffer.packageNameFo}
              <div className="list-icon">
                <FontAwesomeIcon
                  icon={this.state.activeList === index ? faSortUp : faSortDown}
                />
              </div>
            </div>
            <div
              className={
                this.state.activeList === index
                  ? "active-list list-content"
                  : "list-content"
              }
            >
              <div className="packageOffer-name">
                <div className="hide-from-page">
                  <div>
                    <label>Hide from EN</label>
                    <input
                      onChange={(e) => {
                        this.inputChange("EN", "visibleLang", index, packageOffer.id, packageOffer.tempId)
                      }}
                      type="checkbox"
                      defaultChecked={packageOffer.visibleLang.some(x => x === "EN")}
                    ></input>
                  </div>
                  <div>
                    <label>Hide from FO</label>
                    <input
                      onChange={(e) => {
                        this.inputChange("FO", "visibleLang", index, packageOffer.id, packageOffer.tempId)
                      }}
                      type="checkbox"
                      defaultChecked={packageOffer.visibleLang.some(x => x === "FO")}
                    ></input>
                  </div>
                  <div>
                    <label>Faktura</label>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "paymentMethod", index, packageOffer.id, packageOffer.tempId)
                      }}
                      type="checkbox"
                      defaultChecked={packageOffer.paymentMethod === "1"}
                    ></input>
                  </div>
                  <div>
                    <label>Hidden</label>
                    <input
                      onChange={(e) => {
                        this.inputChange(e, "hidden", index, packageOffer.id, packageOffer.tempId)
                      }}
                      type="checkbox"
                      defaultChecked={packageOffer.hidden === "1"}
                    ></input>
                  </div>
                </div>
                <div className="packageUrl">
                  <label>Package url</label>
                  <input
                    disabled
                    readOnly
                    type="text"
                    defaultValue={
                      this.settings.protocol +
                      this.settings.domain +
                      "/#/packageAvailability?id=" +
                      packageOffer.id
                    }
                  ></input>
                </div>

                <div>
                  <p>
                    Package <i>{settings.lang}</i>
                  </p>
                  <input
                    defaultValue={
                      settings.lang === "en"
                        ? packageOffer.packageName
                        : packageOffer.packageNameFo
                    }
                    onChange={(e) => {
                      this.inputChange(e, "packageOfferName", index, packageOffer.id, packageOffer.tempId)
                    }}
                  />
                </div>
                <div>
                  <p>Price</p>
                  <input
                    onKeyDown={(event) => {
                      this.numberOnly(event);
                    }}
                    defaultValue={packageOffer.price}
                    onChange={(e) => {
                      this.inputChange(e, "price", index, packageOffer.id, packageOffer.tempId)
                    }}
                  />
                </div>
                <div>
                  <p>Price Children</p>
                  <input
                    onKeyDown={(event) => {
                      this.numberOnly(event);
                    }}
                    defaultValue={packageOffer.cPrice}
                    onChange={(e) => {
                      this.inputChange(e, "cPrice", index, packageOffer.id, packageOffer.tempId)
                    }}
                  />
                </div>
                <div>
                  <p>Max Guests</p>
                  <input
                    onKeyDown={(event) => {
                      this.numberOnly(event);
                    }}
                    defaultValue={packageOffer.maxGuests}
                    onChange={(e) => {
                      this.inputChange(e, "maxGuests", index, packageOffer.id, packageOffer.tempId)
                    }}
                  />
                </div>
                <div className="seletctDatePref">
                  <p>Select date preference</p>
                  <div>
                    <select
                      onChange={(e) => {
                        this.inputChange(e, "seletctDatePref", index, packageOffer.id, packageOffer.tempId)
                        this.setState({})
                      }}
                      value={packageOffer.seletctDatePref}
                    >
                      <option readOnly>-- Select --</option>
                      <option value="0">Specific dates</option>
                      <option value="1">Recurring date</option>
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      packageOffer.seletctDatePref === "0" ? "flex" : "none",
                  }}
                  className="specific-dates"
                >
                  <div className="addHead">
                    <p>Specific dates</p>
                    <div
                      className="add"
                      onClick={() => {
                        this.addSpecificDate(packageOffer.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add date
                    </div>
                  </div>
                  <div className="dates">
                    {this.state.bindPackages.length > 0 ?
                      this.state.bindPackages.filter(x => x.packageId === packageOffer.id).map((value, bi) => {
                        return (
                          <div key={bi}>
                            <div>

                              <label>Date</label>
                              <div
                                onClick={() => {
                                  this.removeSpecificDate(index, bi);
                                }}
                                className="remove"
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </div>
                              <DatePicker
                                autoApply={true}
                                singleDatePicker={true}
                                locale={{ format: "D.M.YYYY" }}
                                onApply={(e, p) => {
                                  const startDate =
                                    p.startDate._d.getFullYear() +
                                    "/" +
                                    (p.startDate._d.getMonth() + 1) +
                                    "/" +
                                    p.startDate._d.getDate();
                                  this.inputChange(
                                    startDate,
                                    "specificDate",
                                    index,
                                    value.id,
                                    value.tempId
                                  )

                                }}
                              >
                                <input
                                  key={new Date()}
                                  defaultValue={value.dateRange}
                                />
                              </DatePicker>
                              <div className="checkInOut">
                                <div>
                                  <label>Start time</label>
                                  <input
                                    defaultValue={value.start}
                                    onChange={(e) => {
                                      this.inputChange(
                                        e,
                                        "start",
                                        index,
                                        value.id,
                                        value.tempId
                                      )
                                    }}
                                  ></input>
                                </div>
                                <div>
                                  <label>End time</label>
                                  <input
                                    defaultValue={value.end}
                                    onChange={(e) => {
                                      this.inputChange(
                                        e,
                                        "end",
                                        index,
                                        value.id,
                                        value.tempId
                                      )
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>

                          </div>
                        );
                      })
                      : ""
                    }
                  </div>
                </div>
                <div
                  style={{
                    display:
                      packageOffer.seletctDatePref === "1" ? "block" : "none",
                  }}
                >
                  <div>
                    <p>Recurring date</p>
                    <DatePicker
                      autoApply={true}
                      startDate={packageOffer.dateRange === "" ? new Date() : new Date(packageOffer.dateRange.split("-")[0])}
                      endDate={packageOffer.dateRange === "" ? new Date() : new Date(packageOffer.dateRange.split("-")[1])}
                      locale={{ format: "D.M.YYYY" }}
                      onApply={(e, p) => {
                        const startDate =
                          p.startDate._d.getFullYear() +
                          "/" +
                          (p.startDate._d.getMonth() + 1) +
                          "/" +
                          p.startDate._d.getDate();
                        const endDate =
                          p.endDate._d.getFullYear() +
                          "/" +
                          (p.endDate._d.getMonth() + 1) +
                          "/" +
                          p.endDate._d.getDate();

                        this.inputChange(
                          { startDate, endDate },
                          "dateRange",
                          index, packageOffer.id, packageOffer.tempId
                        )

                      }}
                    >
                      <input
                        key={new Date()}
                        defaultValue={packageOffer.dateRange}
                      />
                    </DatePicker>
                  </div>

                  <div>
                    <div className="packageOffer-week-days">
                      <div className="days">
                        <p>Sun</p>

                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="0"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "0") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Mon</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="1"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "1") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Tue</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="2"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "2") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Wed</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="3"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "3") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Thu</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="4"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "4") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Fri</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="5"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "5") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="days">
                        <p>Sat</p>
                        <input
                          onChange={(e) => {
                            this.inputChange(e, "days", index, packageOffer.id, packageOffer.tempId)
                          }}
                          value="6"
                          type="checkbox"
                          defaultChecked={
                            packageOffer.weekDays.findIndex((x) => {
                              return (x.day === "6") & (x.checked === true);
                            }) !== -1
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="packageOffer-text">
                <div className="editor">
                  <div className="text-short">
                    <p>
                      Edit short text <i>{settings.lang}</i>
                    </p>
                    <ReactQuill
                      value={
                        settings.lang === "en"
                          ? packageOffer.textShort
                          : packageOffer.textShortFo
                      }
                      onChange={(event) => {
                        this.inputChange(event, "textShort", index, packageOffer.id, packageOffer.tempId)
                      }}
                    />
                  </div>
                  <div className="extra-info">
                    <p>
                      Add extra info to pdf. <i>{settings.lang}</i>
                    </p>
                    <ReactQuill
                      value={
                        settings.lang === "en"
                          ? packageOffer.extraInfo
                          : packageOffer.extraInfoFo
                      }
                      onChange={(event) => {
                        this.inputChange(event, "extraInfo", index, packageOffer.id, packageOffer.tempId)
                      }}
                    />
                  </div>
                  <div className="text-long">
                    <p>
                      Edit long text <i>{settings.lang}</i>
                    </p>
                    <ReactQuill
                      value={
                        settings.lang === "en"
                          ? packageOffer.textLong
                          : packageOffer.textLongFo
                      }
                      onChange={(event) => {
                        this.inputChange(event, "textLong", index, packageOffer.id, packageOffer.tempId)
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="images add-images">
                <p>
                  Main image
                  <span
                    className={
                      this.state.imageLoadMain
                        ? "image-spinner"
                        : "hide-spinner"
                    }
                  >
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </span>
                </p>
                <div className="main-image">
                  <img
                    alt=""
                    src={
                      packageOffer.mainImage.length > 0
                        ? "" +
                        this.settings.protocol +
                        this.settings.domain +
                        "/static/uploads/thumbnail_" +
                        packageOffer.mainImage +
                        "?" +
                        new Date().getTime() +
                        this.state.mainImage
                        : ""
                    }
                  />
                </div>
                <ImageUploader
                  accept="PNG|JPEG|JPG"
                  singleImage={true}
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={(e) => {
                    this.uploadMainImage(e, index, packageOffer.id, packageOffer.tempId);
                  }}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={50000000000000}
                />
                <p>
                  Gallery Images
                  <span
                    className={
                      this.state.imageLoadGallery
                        ? "image-spinner"
                        : "hide-spinner"
                    }
                  >
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </span>
                </p>
                <div className="gallery">
                  {this.state.packageOfferImages.map((value, i) => {
                    if (value.index === index) {
                      return (
                        <div key={i} className="images">
                          <FontAwesomeIcon
                            onClick={() => {
                              this.removePackageOfferImage(value.src, index);
                            }}
                            icon={faTrashAlt}
                          />
                          <img
                            alt=""
                            src={
                              "" +
                              this.settings.protocol +
                              this.settings.domain +
                              "/static/uploads/thumbnail_" +
                              value.src +
                              "?" +
                              new Date().getTime()
                            }
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}

                  <ImageUploader
                    accept="PNG|JPEG|JPG"
                    withIcon={true}
                    buttonText="Choose images"
                    onChange={(file) => {
                      this.uploadGallery(file, index, packageOffer.id);
                    }}
                    key={this.state.fileKey || ""}
                    imgExtension={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={50000000000000}
                  />
                </div>
                <div
                  onClick={() => {
                    this.deletePackageOffer(packageOffer.id);
                  }}
                  className="delete-packageOffer"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </div>
            </div>
          </li>
        );
      }
    );

    return (
      <content id="admin-packagesOffers">
        <div className="wrap-content">
          <div className="front-page-content">
            <div className="wrap-head-lang">
              <h3>Packages</h3>
              <div>
                <div>
                  <p
                    onClick={() => {
                      settings.lang = "fo";
                      this.setState({}, () => {
                        localStorage.setItem("lang", "fo");
                      });
                    }}
                    className={settings.lang === "fo" ? "active" : ""}
                  >
                    FO
                  </p>
                  <p
                    onClick={() => {
                      settings.lang = "en";
                      this.setState({}, () => {
                        localStorage.setItem("lang", "en");
                      });
                    }}
                    className={settings.lang === "en" ? "active" : ""}
                  >
                    EN
                  </p>
                </div>
                <div
                  className="add"
                  onClick={() => {
                    this.addPackageOffer();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Add package</span>
                </div>
                <div
                  onClick={() => {
                    this.save();
                  }}
                  className="save-btn"
                >
                  <div>Save</div>
                </div>
              </div>
            </div>
            <ul key={this.uuidv4()}>{packagesOffers}</ul>
          </div>
        </div>
      </content>
    );
  }
}

export default PackageOffers;
