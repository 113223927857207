import React, { Component } from "react";
import settings from "../../../settings.json";
import DatePicker from "react-bootstrap-daterangepicker";
import { faSpinner, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ReactExport from "react-export-excel";
import "./reports.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Reports extends Component {
  faSpinner = faSpinner;
  faFileExcel = faFileExcel;
  currentDate = new Date();
  calcReport = [];
  subTotal = {
    house: 0,
    offers: 0,
    offersExclude: 0,
    total: 0,
    revenue: 0,
    cleaning: 0,
  };
  constructor(props) {
    super(props);
    this.currentDate.setDate(1);
    this.state = {
      loading: false,
      subTotal: {
        house: 0,
        offers: 0,
        offersExclude: 0,
        total: 0,
        revenue: 0,
        cleaning: 0,
      },
      startDate:
        "1" +
        "-" +
        (this.currentDate.getMonth() + 1) +
        "-" +
        this.currentDate.getFullYear(),
      endDate:
        new Date(
          this.currentDate.getFullYear(),
          this.currentDate.getMonth() + 1,
          0
        ).getDate() +
        "-" +
        (this.currentDate.getMonth() + 1) +
        "-" +
        this.currentDate.getFullYear(),
      startDateFormat: this.currentDate,
      endDateFormat: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      ),
    };
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
    };
    this.axios = require("axios");
    this.getReports();
  }
  handleEvent = (event, picker) => {
    const startDate = new Date(picker.startDate._d);
    const endDate = new Date(picker.endDate._d);
    this.setState(
      {
        startDate:
          startDate.getDate() +
          "-" +
          (startDate.getMonth() + 1) +
          "-" +
          startDate.getFullYear(),
        startDateFormat: startDate,
        minDate: startDate,
        endDate:
          endDate.getDate() +
          "-" +
          (endDate.getMonth() + 1) +
          "-" +
          endDate.getFullYear(),
        endDateFormat: endDate,
      },
      this.checkAvailability
    );
    this.getReports();
  };

  getDates(start, end) {
    var arr = [];
    var dt = new Date(start);
    end = new Date(end);
    while (dt <= end) {
      arr.push(new Date(dt).valueOf());
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
  getReports() {
    this.subTotal = {
      house: 0,
      offers: 0,
      offersExclude: 0,
      total: 0,
      revenue: 0,
      cleaning: 0,
    };
    var cabins = [];
    this.calcReport = [];
    this.axios
      .post(
        "" +
        this.settings.protocol +
        this.settings.domain +
        "/static/php/getCabins.php"
      )
      .then((response) => {
        cabins = response.data;
        this.axios
          .post(
            "" +
            this.settings.protocol +
            this.settings.domain +
            "/static/php/getBooking.php"
          )
          .then((response) => {
            this.axios
              .post(
                "" +
                this.settings.protocol +
                this.settings.domain +
                "/static/php/getCabinOffers.php"
              )
              .then((getCabinOffers) => {
                getCabinOffers = getCabinOffers.data === "" ? [] : getCabinOffers.data;

                this.axios
                  .post(
                    "" +
                    this.settings.protocol +
                    this.settings.domain +
                    "/static/php/getBindOffers.php"
                  )
                  .then((bindOfferData) => {
                    var bindOffer =
                      bindOfferData.data === "" ? [] : bindOfferData.data;
                    var bookings = response.data === "" ? [] : response.data;
                    cabins.forEach((cabinElement) => {
                      var filterBookings = bookings.filter((x) => {
                        return JSON.parse(x.cabinId).includes(cabinElement.id);
                      });

                      // var filterOffers = offers.filter((x) =>
                      //   x.cabinId.includes(cabinElement.id)
                      // );
                      var housePrice = 0;
                      var totalHouseAmount = 0;
                      // var totalBookingAmount = 0;
                      var totalCleaningAmount = 0;
                      var totalNights = 0;
                      var totalOffers = 0;
                      var totalOffersExclude = 0;

                      // var filterOffers = filterBookings.filter((x) => {
                      //   if (x.offersId !== "" && x.offersId !== "[]") {
                      //     var offerArray = JSON.parse(x.offersId);
                      //     offers.forEach((x) => {
                      //       if (x.exclude === "1") {
                      //         var index = offerArray.findIndex(
                      //           (j) => j === x.id
                      //         );

                      //         if (index >= 0) {
                      //           offerArray.splice(index, 1);
                      //         }
                      //       }
                      //     });
                      //     x.offersId = offerArray;
                      //     if (offerArray.length > 0) {
                      //       return x;
                      //     }
                      //   }
                      //   return false;
                      // });

                      filterBookings.forEach((element) => {
                        element.costs = element.costs === "" ? "0" : element.costs;
                        if (
                          element.paymentStatus === "Paid" &&
                          JSON.parse(element.costs) > 0
                        ) {
                          if (element.actualCheckIn !== "") {
                            element.checkIn = new Date(element.actualCheckIn);
                          }
                          else {
                            element.checkIn = new Date(element.checkIn)
                            element.checkIn.setDate(element.checkIn.getDate() + (cabinElement.blockDays === "" ? 0 : JSON.parse(cabinElement.blockDays)))
                          }
                          if (element.actualCheckOut !== "") {
                            element.checkOut = new Date(element.actualCheckOut);
                          } else {
                            element.checkOut = new Date(element.checkOut)

                            element.checkOut.setDate(element.checkOut.getDate() - (cabinElement.blockDays === "" ? 0 : JSON.parse(cabinElement.blockDays)))
                          }

                          if (
                            element.checkOut.valueOf() <
                            new Date(this.state.endDateFormat) &&
                            element.checkOut.valueOf() >
                            new Date(this.state.startDateFormat)
                          ) {

                            if (cabinElement.price.length > 0) {
                              housePrice += JSON.parse(cabinElement.price);
                            } else {
                              housePrice += 0;
                            }
                            totalNights =
                              this.getDates(element.checkIn, element.checkOut)
                                .length - 1;
                            if (cabinElement.price.length > 0) {
                              totalHouseAmount +=
                                JSON.parse(cabinElement.price) * totalNights;
                            } else {
                              totalHouseAmount += 0;
                            }

                            if (cabinElement.cleaning.length > 0) {
                              totalCleaningAmount += JSON.parse(
                                cabinElement.cleaning
                              );
                            }
                            // totalOffers +=
                            //   totalBookingAmount -
                            //   (totalCleaningAmount + totalHouseAmount);
                          }
                        }
                      });

                      filterBookings.forEach((x) => {
                        if (
                          x.paymentStatus === "Paid" &&
                          JSON.parse(x.costs) > 0
                        ) {
                          if (x.actualCheckIn !== "") {
                            x.checkIn = x.actualCheckIn;
                          }
                          if (x.actualCheckOut !== "") {
                            x.checkOut = x.actualCheckOut;
                          }
                          if (
                            new Date(x.checkOut).valueOf() <
                            new Date(this.state.endDateFormat) &&
                            new Date(x.checkOut).valueOf() >
                            new Date(this.state.startDateFormat)
                          ) {

                            bindOffer
                              .filter((o) => o.bookedId === x.id)
                              .forEach((x) => {
                                if (x.offerAmount === "") {
                                  x.offerAmount = "1";
                                }
                                if (
                                  getCabinOffers.find((o) => o.id === x.offerId)
                                    .exclude
                                ) {
                                  totalOffersExclude +=
                                    JSON.parse(x.price) *
                                    JSON.parse(x.offerAmount);
                                } else {
                                  totalOffers +=
                                    JSON.parse(x.price) *
                                    JSON.parse(x.offerAmount);
                                }
                              });
                          }
                        }
                      });

                      this.subTotal.house += totalHouseAmount;
                      this.subTotal.cleaning += totalCleaningAmount;
                      this.subTotal.offers += totalOffers;
                      this.subTotal.offersExclude += totalOffersExclude;
                      this.subTotal.total += totalOffers + totalHouseAmount;

                      this.setState({ subTotal: this.subTotal });
                      var reportInfo = {
                        house: cabinElement.cabinName,
                        housePrice,
                        totalBookingAmount: totalOffers + totalHouseAmount,
                        totalOffers,
                        totalOffersExclude,
                        totalHouseAmount,
                        totalCleaningAmount,
                        totalNights,
                      };

                      this.calcReport.push(reportInfo);
                    });
                    this.setState({});
                    // console.log(offers)
                    // console.log(bookings)
                  });
              });
          });
      });
  }
  render() {
    const dataSet = [];
    var reportRows = this.calcReport.map((element, key) => {
      var array = {
        house: element.house,
        totalhouse: element.totalHouseAmount,
        totaloffers: element.totalOffers,
        totalbooking: element.totalBookingAmount,
        blrevenue: element.totalBookingAmount * 0.1,
        srevenue: element.totalBookingAmount * 0.1,
        oerevenue: element.totalOffersExclude,
        totalcleaning: element.totalCleaningAmount,
        hrevenue:
          element.totalBookingAmount * 0.8,
      };
      dataSet.push(array);
      return (
        <tr key={key}>
          <td>{element.house}</td>
          {/* <td>{element.housePrice}</td> */}
          {/* <td>{element.totalNights}</td> */}
          <td>{element.totalHouseAmount}</td>
          <td>{element.totalOffers}</td>
          <td>{element.totalBookingAmount}</td>
          <td>{element.totalBookingAmount * 0.1}</td>
          <td>{element.totalBookingAmount * 0.1}</td>
          <td>{element.totalOffersExclude}</td>
          <td>{element.totalCleaningAmount}</td>
          <td>
            {element.totalBookingAmount * 0.9}
          </td>
        </tr>
      );
    });
    var sub = {
      house: "SubTotal",
      totalhouse: this.state.subTotal.house,
      totaloffers: this.state.subTotal.offers,
      totalbooking: this.state.subTotal.total,
      blrevenue: this.state.subTotal.total * 0.1,
      srevenue: this.state.subTotal.total * 0.1,
      oerevenue: this.state.subTotal.offersExclude,
      totalcleaning: this.state.subTotal.cleaning,
      hrevenue: this.state.subTotal.total * 0.9,
    };
    dataSet.push(sub)
    return (
      <content id="reports">
        <div className="wrap-content">
          <div className="reports-content">
            <h3 className="headline">Income</h3>
            <div className="select-reports">
              <ExcelFile className="excel" element={<FontAwesomeIcon icon={faFileExcel} ></FontAwesomeIcon>}>
                <ExcelSheet data={dataSet} name="Report">
                  <ExcelColumn label="House" value="house" />
                  <ExcelColumn label="Total House" value="totalhouse" />
                  <ExcelColumn label="Total Offers" value="totaloffers" />
                  <ExcelColumn label="Total Booking" value="totalbooking" />
                  <ExcelColumn
                    label="10% Book Local Revenue"
                    value="blrevenue"
                  />
                  <ExcelColumn label="10% Service Revenue" value="srevenue" />
                  <ExcelColumn
                    label="Offers Exclude Revenue"
                    value="oerevenue"
                  />
                  <ExcelColumn label="Total Cleaning" value="totalcleaning" />
                  <ExcelColumn label="House Revenue" value="hrevenue" />
                </ExcelSheet>
              </ExcelFile>
              <div className="choose-month">
                <DatePicker
                  className="startDate"
                  autoApply={true}
                  locale={{ format: "D.M.YYYY" }}
                  onApply={this.handleEvent}
                >
                  <input
                    readOnly
                    className="calendar"
                    type="text"
                    value={this.state.startDate + " - " + this.state.endDate}
                  />
                </DatePicker>
              </div>
              <table>
                <tbody>
                  <tr>
                    <th>House</th>
                    {/* <th>House Price</th>
                    <th>Total Nights</th> */}
                    <th>Total House</th>
                    <th>Total Offers</th>
                    <th>Total Booking</th>
                    <th>10% Book Local Revenue</th>
                    <th>10% Service Revenue</th>
                    <th>Offers Exlcude Revenue</th>
                    <th>Total Cleaning</th>
                    <th>House Revenue</th>
                  </tr>
                  {reportRows}
                  <tr className="subtotal">
                    <td>SubTotal</td>
                    <td>DKK {this.state.subTotal.house},-</td>
                    <td>DKK {this.state.subTotal.offers},-</td>
                    <td>DKK {this.state.subTotal.total},-</td>
                    <td>DKK {(this.state.subTotal.total * 0.1).toFixed(2)},-</td>
                    <td>DKK {(this.state.subTotal.total * 0.1).toFixed(2)},-</td>
                    <td>DKK {this.state.subTotal.offersExclude},-</td>
                    <td>DKK {this.state.subTotal.cleaning},-</td>
                    <td>DKK {(this.state.subTotal.total * 0.9).toFixed(2)},-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default Reports;
