import React, { Component } from "react";
import settings from "../../../settings.json";
import "./page.scss";

import ImageUploader from "react-images-upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class Page extends Component {
  firstUpload = true;
  firstLength = 0;
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol + "//",
    };
    this.state = {
      loading: false,
      theInputKey: "",
      frontPageImages: [{ src: "" }],
      wildLifeImages: [{ src: "" }],
      pictures: [],
      welcomeText: {
        headline: "",
        text: "",
      },
      wildLifeText: {
        headline: "",
        text: "",
      },
      customersWord: { comment: "" },
      welcomeTextFo: {
        headline: "",
        text: "",
      },
      wildLifeTextFo: {
        headline: "",
        text: "",
      },
      customersWordFo: { comment: "" },
    };
    this.axios = require("axios");
    this.getFrontPage().then((response) => {});
    this.uploadCarousel = this.uploadCarousel.bind(this);
    this.uploadWildLife = this.uploadWildLife.bind(this);
  }

  uploadCarousel(picture) {
    this.setState({ loading: true });
    var carouselLength = this.state.frontPageImages.length + picture.length;
    var newFrontPageImage = [];
    for (var i = 1; i <= carouselLength; i++) {
      newFrontPageImage.push({
        src: i + "_carousel.png",
      });
    }

    this.upload(picture, "carousel").then(() => {
      this.setState({
        frontPageImages: newFrontPageImage,
        theInputKey: new Date().valueOf(),
        loading: false,
      });

      this.firstUpload = false;
    });
  }

  uploadWildLife(picture) {
    this.setState({ loading: true });
    var wildLifeLength = this.state.wildLifeImages.length + picture.length;
    var newWildLifeImages = [];
    for (var i = 1; i <= wildLifeLength; i++) {
      newWildLifeImages.push({
        src: i + "_wildLife.png",
      });
    }

    this.upload(picture, "wildLife").then(() => {
      this.setState({
        wildLifeImages: newWildLifeImages,
        theInputKey: new Date().valueOf(),
        loading: false,
      });
    });
  }

  upload(picture, define) {
    this.setState({ loading: true });
    var formData = new FormData();
    if (define === "wildLife") {
      picture.forEach((element, i) => {
        formData.append(
          "fileToUpload[]",
          element,
          this.state.wildLifeImages.length + 1 + i + "_" + define + ".png"
        );
      });
    }
    if (define === "carousel") {
      picture.forEach((element, i) => {
        formData.append(
          "fileToUpload[]",
          element,
          this.state.frontPageImages.length + 1 + i + "_" + define + ".png"
        );
      });
    }
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/uploadImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.setState({ theInputKey: new Date().valueOf() });
        this.setState({ loading: false });
        return res;
      })
      .catch((error) => {
        return error;
      });
  }
  getFrontPage() {
    return this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getFrontPage.php"
      )
      .then((response) => {
        var frontPageImages = JSON.parse(response.data.frontPageImages).map(
          (value) => {
            const img = value.src;
            value.src = img;
            return value;
          }
        );

        var wildLifeImages = JSON.parse(response.data.wildLifeImages).map(
          (value) => {
            const img = value.src;
            value.src = img;
            return value;
          }
        );
        
        this.setState({
          frontPageImages,
          welcomeText: JSON.parse(response.data.welcomeText),
          customersWord: JSON.parse(response.data.customersWord),
          wildLifeText: JSON.parse(response.data.wildLifeText),
          welcomeTextFo:
            response.data.welcomeTextFo !== ""
              ? JSON.parse(response.data.welcomeTextFo)
              : "",
          customersWordFo:
            response.data.welcomeTextFo !== ""
              ? JSON.parse(response.data.customersWordFo)
              : "",
          wildLifeTextFo:
            response.data.welcomeTextFo !== ""
              ? JSON.parse(response.data.wildLifeTextFo)
              : "",
          wildLifeImages,
          contactInfo: JSON.parse(response.data.contactInfo),
          social: JSON.parse(response.data.social),
        });
        return response.data;
      })
      .catch((error) => {
        console.log(error)
        // handle error
        return error;
      });
  }
  save() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateFrontPage.php",
        this.state
      )
      .then((response) => {
        if (response.data) {
          alert("Data saved successfully!");
        } else {
          alert("Saved failed");
        }
      });
  }
  removeFrontPageImage(index) {
    this.state.frontPageImages.splice(index, 1);
    this.setState({ frontPageImages: this.state.frontPageImages });
  }

  removeWildLifeImage(index) {
    this.state.wildLifeImages.splice(index, 1);
    this.setState({ wildLifeImages: this.state.wildLifeImages });
  }

  inputChange(event, define) {
    if (define === "welcomeTextHeadline") {
      if (settings.lang === "en") {
        this.setState({
          welcomeText: {
            headline: event.replace(/"/g, "'"),
            text: this.state.welcomeText.text,
          },
        });
      } else {
        this.setState({
          welcomeTextFo: {
            headline: event.replace(/"/g, "'"),
            text: this.state.welcomeTextFo.text,
          },
        });
      }
    }
    if (define === "welcomeTextText") {
      if (settings.lang === "en") {
        this.setState({
          welcomeText: {
            headline: this.state.welcomeText.headline,
            text: event.replace(/"/g, "'"),
          },
        });
      } else {
        this.setState({
          welcomeTextFo: {
            headline: this.state.welcomeTextFo.headline,
            text: event.replace(/"/g, "'"),
          },
        });
      }
    }
    if (define === "customerWord") {
      if (settings.lang === "en") {
        this.setState({
          customersWord: {
            comment: event.replace(/"/g, "'"),
          },
        });
      } else {
        this.setState({
          customersWordFo: {
            comment: event.replace(/"/g, "'"),
          },
        });
      }
    }
    if (define === "wildLifeTextHeadline") {
      if (settings.lang === "en") {
        this.setState({
          wildLifeText: {
            headline: event.replace(/"/g, "'"),
            text: this.state.wildLifeText.text,
          },
        });
      } else {
        this.setState({
          wildLifeTextFo: {
            headline: event.replace(/"/g, "'"),
            text: this.state.wildLifeTextFo.text,
          },
        });
      }
    }
    if (define === "wildLifeTextText") {
      if (settings.lang === "en") {
        this.setState({
          wildLifeText: {
            headline: this.state.wildLifeText.headline,
            text: event.replace(/"/g, "'"),
          },
        });
      } else {
        this.setState({
          wildLifeTextFo: {
            headline: this.state.wildLifeTextFo.headline,
            text: event.replace(/"/g, "'"),
          },
        });
      }
    }
  }

  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  render() {
    if (this.state.frontPageImages.length > 0) {
      if (this.state.frontPageImages[0].src.length > 0) {
        var frontPageIamages = this.state.frontPageImages.map((value, i) => {
          return (
            <div key={i} className="images">
              <FontAwesomeIcon
                onClick={() => {
                  this.removeFrontPageImage(i);
                }}
                icon={faTrashAlt}
              />
              <img
                alt=""
                src={
                  "" +
                  this.settings.protocol +
                  this.settings.domain +
                  "/static/uploads/thumbnail_" +
                  value.src
                }
              />
            </div>
          );
        });

        var wildLifeIamages = this.state.wildLifeImages.map((value, i) => {
          return (
            <div key={i} className="images">
              <FontAwesomeIcon
                onClick={() => {
                  this.removeWildLifeImage(i);
                }}
                icon={faTrashAlt}
              />
              <img
                alt=""
                src={
                  "" +
                  this.settings.protocol +
                  this.settings.domain +
                  "/static/uploads/thumbnail_" +
                  value.src
                }
              />
            </div>
          );
        });
      }
    }
    return (
      <content id="page">
        {this.state.loading ? (
          <div className="loading">
            <div className="spinner">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="header">
          <div className="wrap-head-lang">
            <h3>Front page</h3>
            <div>
              <div>
                <p
                  onClick={() => {
                    settings.lang = "fo";
                    this.setState({}, () => {
                      localStorage.setItem("lang", "fo");
                    });
                  }}
                  className={settings.lang === "fo" ? "active" : ""}
                >
                  FO
                </p>
                <p
                  onClick={() => {
                    settings.lang = "en";
                    this.setState({}, () => {
                      localStorage.setItem("lang", "en");
                    });
                  }}
                  className={settings.lang === "en" ? "active" : ""}
                >
                  EN
                </p>
              </div>
              <div
                onClick={() => {
                  this.save();
                }}
                className="save-btn"
              >
                <div>Save</div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-content">
          <div className="front-page-content">
            <h3 className="headline">Page images</h3>
            {frontPageIamages}
            <div className="images add-images">
              <ImageUploader
                key={this.state.theInputKey || ""}
                accept="PNG|JPEG|JPG"
                withIcon={true}
                buttonText="Choose images"
                onChange={this.uploadCarousel}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={50000000000000}
              />
            </div>
          </div>
          <div className="front-page-content">
            <h3 className="headline">
              Customer word <i>{settings.lang}</i>
            </h3>
            <div className="welcome-editor">
              <p>Comment</p>
              <JoditEditor
                value={this.state.customersWord.comment}
                config={this.config}
                onChange={(event) => {
                  this.inputChange(event, "customerWord");
                }}
              />
            </div>
          </div>
          <div className="front-page-content">
            <h3 className="headline">
              Welcome Text <i>{settings.lang}</i>
            </h3>
            <div className="welcome-editor">
              <p>Headline</p>
              <JoditEditor
                value={
                  settings.lang === "en"
                    ? this.state.welcomeText.headline
                    : this.state.welcomeTextFo.headline
                }
                config={this.config}
                onChange={(event) => {
                  this.inputChange(event, "welcomeTextHeadline");
                }}
              />
            </div>
            <div className="welcome-editor">
              <p>Text</p>
              <JoditEditor
                value={
                  settings.lang === "en"
                    ? this.state.welcomeText.text
                    : this.state.welcomeTextFo.text
                }
                config={this.config}
                onChange={(event) => {
                  this.inputChange(event, "welcomeTextText");
                }}
              />
            </div>
          </div>
          <div className="front-page-content">
            <h3 className="headline">
              Wild Life <i>{settings.lang}</i>
            </h3>
            <div className="welcome-editor">
              <p>Headline</p>
              <JoditEditor
                value={
                  settings.lang === "en"
                    ? this.state.wildLifeText.headline
                    : this.state.wildLifeTextFo.headline
                }
                config={this.config}
                onChange={(event) => {
                  this.inputChange(event, "wildLifeTextHeadline");
                }}
              />
            </div>
            <div className="welcome-editor">
              <p>Text</p>
              <JoditEditor
                value={
                  settings.lang === "en"
                    ? this.state.wildLifeText.text
                    : this.state.wildLifeTextFo.text
                }
                config={this.config}
                onChange={(event) => {
                  this.inputChange(event, "wildLifeTextText");
                }}
              />
            </div>
            {wildLifeIamages}
            <div className="images add-images">
              <ImageUploader
                key={this.state.theInputKey || ""}
                accept="PNG|JPEG|JPG"
                withIcon={true}
                buttonText="Choose images"
                onChange={this.uploadWildLife}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={50000000000000}
              />
            </div>
          </div>
        </div>
      </content>
    );
  }
}

export default Page;
