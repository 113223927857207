import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faHome,
    faMapPin,
    faInfo,
    faCalendar,
    faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import settings from "../../settings.json";
import translate from "../../translate.json";

import "./header.scss";
import { faStar } from "@fortawesome/free-regular-svg-icons";

class Header extends Component {
    constructor(props) {
        super(props);
        this.queryString = require("query-string");
        this.urlParameters = this.queryString.parse(this.props.location.search);
        if (this.urlParameters.lang !== undefined) {
            if (localStorage.getItem("lang") === this.urlParameters.lang) {

            } else {
                localStorage.setItem("lang", this.urlParameters.lang);
                window.location.reload();
            }

        }
        this.settings = {
            domain:
                window.location.hostname !== "localhost"
                    ? window.location.hostname
                    : settings.domain,
            protocol: settings.protocol + "//",
        };

        this.menues = settings.houses
            ? [
                {
                    name: translate.home[settings.lang],
                    link: "",
                    icon: faHome,
                },
                {
                    name: translate.ACCOMMODATIONS[settings.lang],
                    link: "",
                    icon: faCalendar,
                },
                // {
                //   name: "ABOUT",
                //   link: "",
                //   icon: faInfo
                // },
                {
                    name: translate.seedo[settings.lang],
                    link: "",
                    icon: faStar,
                },
                {
                    name: translate.contact[settings.lang],
                    link: "",
                    icon: faMapPin,
                },
            ]
            : [
                {
                    name: translate.home[settings.lang],
                    link: "",
                    icon: faHome,
                },
                // {
                //   name: "ABOUT",
                //   link: "",
                //   icon: faInfo,
                // },
                {
                    name: translate.seedo[settings.lang],
                    link: "",
                    icon: faStar,
                },
                {
                    name: translate.contact[settings.lang],
                    link: "",
                    icon: faMapPin,
                },
            ];
        this.scrollHome = React.createRef();
        this.state = {
            openMenu: false,
            activeMenu:
                this.props.location.pathname === "/"
                    ? translate.home[settings.lang]
                    : "",
        };
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener("click", this.handleClick);

    }
    waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
    async componentDidMount() {
        this.queryString = require("query-string");
        this.urlParameters = this.queryString.parse(this.props.location.search);
        if (this.urlParameters.seedo !== undefined) {
            this.waitForElm('.exclusive').then((elm) => {
                setTimeout(() => {
                    this.handleOnClick(translate.seedo[settings.lang])
                }, 500)
            })
        }
    }
    handleClick = () => {
        setTimeout(() => {
            window.location.hash !== "#/"
                ? this.setState({ activeMenu: "" })
                : this.setState({ dummy: "" });
        }, 100);
    };

    handleScroll = () => {
        if (this.props.state.scrollBooking.current !== null) {
            var active = translate.home[settings.lang];

            if (
                window.pageYOffset <
                this.props.state.scrollBooking.current.offsetTop - 200
            ) {
                active = translate.home[settings.lang];
            } else if (this.props.state.scrollAbout.current !== null) {
                if (
                    window.pageYOffset <
                    this.props.state.scrollAbout.current.offsetTop - 200
                ) {
                    active = translate.ACCOMMODATIONS[settings.lang];
                    // }
                    // else if (
                    //   window.pageYOffset <
                    //   this.props.state.scrollExclusive.current.offsetTop - 200
                    // ) {
                    //   active = "ABOUT";
                }
            } else if (
                window.pageYOffset <
                this.props.state.scrollContact.current.offsetTop - 200
            ) {
                active = translate.seedo[settings.lang];
            } else {
                active = translate.contact[settings.lang];
            }

            this.setState({ activeMenu: active, theposition: window.pageYOffset });
        }
    };

    handleOnClick = (event) => {
        this.openMenu(event);
        if (event === translate.home[settings.lang]) {
            this.setState({ activeMenu: translate.home[settings.lang] });
            this.scrollHome.current.scrollIntoView({
                behavior: "smooth",
            });
        }
        if (event === translate.ACCOMMODATIONS[settings.lang]) {
            this.props.state.scrollBooking.current.scrollIntoView({
                behavior: "smooth",
            });
        }
        // if (event === "ABOUT") {
        //   this.props.state.scrollAbout.current.scrollIntoView({
        //     behavior: "smooth"
        //   });
        // }
        if (event === translate.seedo[settings.lang]) {
            this.props.state.scrollExclusive.current.scrollIntoView({
                behavior: "smooth",
            });
        }
        if (event === translate.contact[settings.lang]) {
            this.props.state.scrollContact.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    };
    openMenu(value) {
        if (value === translate.back[settings.lang]) {
            setTimeout(() => {
                window.scrollTo(
                    0,
                    JSON.parse(sessionStorage.getItem("lastScrollPosition")) - 200
                );
            }, 300);
        }
        this.state.openMenu
            ? this.setState({ openMenu: false })
            : this.setState({ openMenu: true });
    }
    render() {
        var menues = null;
        if (this.props.location.pathname === "/") {

            menues = this.menues.map((menu, i) => {

                return <span key={i}>

                    <Link to={menu.link}>
                        <p
                            className={`${this.state.activeMenu === menu.name ? "menu active" : "menu"
                                }
            ${this.props.location.pathname === "/"
                                    ? ""
                                    : i !== 0
                                        ? "disable-menu"
                                        : ""
                                }`}
                            onClick={() => this.handleOnClick(menu.name)}
                        >
                            <span className="font-icon">
                                <FontAwesomeIcon icon={menu.icon} />
                            </span>

                            {menu.name}
                        </p>
                    </Link>
                </span>
            });
        }
        else {
            menues = <span><p
                onClick={() => {
                    this.openMenu(translate.back[settings.lang]);
                    this.props.history.goBack();
                }}
                className="menu"
            >
                <span className="font-icon">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                {translate.back[settings.lang]}
            </p>
                <p
                    onClick={() => {
                        window.location.href = "/"
                    }}
                    className="menu"
                >
                    <span className="font-icon">
                        <FontAwesomeIcon icon={faHome} />
                    </span>
                    {translate.home[settings.lang]}
                </p>
            </span>
        }
        return (
            <header
                className={
                    this.props.location.pathname.includes("/admin") ? "hide-header" : ""
                }
                ref={this.scrollHome}
            >
                <div className={(settings.houses ? "" : "large") + " wrap-header"}>
                    <div
                        onClick={() => {
                            window.location.href = settings.houses
                                ? "https://booklocal.fo"
                                : "/";
                        }}
                        className="logo"
                    >
                        <img
                            alt=""
                            src={
                                this.settings.protocol +
                                this.settings.domain +
                                "/static/media/logo.png"
                            }
                        />
                    </div>
                    <div className="header-menues">
                        <div
                            onClick={() => {
                                this.openMenu();
                            }}
                            className="burger-menu"
                        >
                            <FontAwesomeIcon icon={faBars} />{" "}
                        </div>
                        {menues}
                        <div className="wrap-head-lang">
                            <div>
                                <div>
                                    <p
                                        onClick={() => {
                                            settings.lang = "fo";
                                            localStorage.setItem("lang", "fo");
                                            window.location.reload();
                                        }}
                                        className={settings.lang === "fo" ? "active" : ""}
                                    >
                                        FO
                                    </p>
                                    <p
                                        onClick={() => {
                                            settings.lang = "en";
                                            localStorage.setItem("lang", "en");
                                            window.location.reload();
                                        }}
                                        className={settings.lang === "en" ? "active" : ""}
                                    >
                                        EN
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.openMenu ? "side-menu active" : "side-menu"}>
                    {menues}
                    <div className="wrap-head-lang">
                        <div>
                            <div>
                                <p
                                    onClick={() => {
                                        settings.lang = "fo";
                                        localStorage.setItem("lang", "fo");
                                        window.location.reload();
                                    }}
                                    className={settings.lang === "fo" ? "active" : ""}
                                >
                                    FO
                                </p>
                                <p
                                    onClick={() => {
                                        settings.lang = "en";
                                        localStorage.setItem("lang", "en");
                                        window.location.reload();
                                    }}
                                    className={settings.lang === "en" ? "active" : ""}
                                >
                                    EN
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(Header);
