import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faFileAlt,
  faHome,
  faCampground,
  faTable,
  faSignOutAlt,
  faPercent,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import "./sidebar.scss";
import { faChartBar, faStar } from "@fortawesome/free-regular-svg-icons";
import settings from "../../settings.json";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.userLogin = sessionStorage.getItem("userLogin")
      ? JSON.parse(sessionStorage.getItem("userLogin"))
      : [{ role: "" }];
    if (settings.houses) {
      if (this.userLogin.some((x) => x.role === "user")) {
        this.adminMenues = [
          {
            name: "Overview Houses",
            to: "../../admin/overview",
            icon: faTachometerAlt,
          },
          {
            name: "Houses",
            to: "../../admin/cabins",
            icon: faHome,
          },
          {
            name: "Booked",
            to: "../../admin/booked",
            icon: faTable,
          },
          {
            name: "Coupons",
            to: "../../admin/coupons",
            icon: faPercent,
          },
          {
            name: "Sign Out",
            to: "../../admin/",
            icon: faSignOutAlt,
          },
        ];
      } else {
        this.adminMenues = [
          {
            name: "Overview Houses",
            to: "../../admin/overview",
            icon: faTachometerAlt,
          },
          {
            name: "Front Page",
            to: "../../admin/page",
            icon: faFileAlt,
          },
          {
            name: "Houses",
            to: "../../admin/cabins",
            icon: faHome,
          },
          {
            name: "Booked",
            to: "../../admin/booked",
            icon: faTable,
          },
          {
            name: "Camping",
            to: "../../admin/camping",
            icon: faCampground,
          },
          {
            name: "Booked Camping",
            to: "../../admin/bookedCamping",
            icon: faTable,
          },
          {
            name: "Packages",
            to: "../../admin/packages",
            icon: faStar,
          },
          {
            name: "Booked Packages",
            to: "../../admin/bookedPackages",
            icon: faTable,
          },
          {
            name: "Coupons",
            to: "../../admin/coupons",
            icon: faPercent,
          },
          {
            name: "Settings",
            to: "../../admin/settings",
            icon: faToolbox,
          },
          {
            name: "Reports",
            to: "../../admin/reports",
            icon: faChartBar,
          },
          {
            name: "Sign Out",
            to: "../../admin/",
            icon: faSignOutAlt,
          },
        ];
      }
    } else {
      this.adminMenues = [
        {
          name: "Overview",
          to: "../../admin/overview",
          icon: faTachometerAlt,
        },
        {
          name: "Front Page",
          to: "../../admin/page",
          icon: faFileAlt,
        },
        {
          name: "Packages",
          to: "../../admin/packages",
          icon: faStar,
        },
        {
          name: "Booked",
          to: "../../admin/bookedPackages",
          icon: faTable,
        },
        {
          name: "Coupons",
          to: "../../admin/coupons",
          icon: faPercent,
        },
        {
          name: "Settings",
          to: "../../admin/settings",
          icon: faToolbox,
        },
        {
          name: "Reports",
          to: "../../admin/reports",
          icon: faChartBar,
        },
        {
          name: "Sign Out",
          to: "../../admin/",
          icon: faSignOutAlt,
        },
      ];
    }

    this.state = { addClass: null, signOut: false };
    this.toggleClass = this.toggleClass.bind(this);
  }

  toggleClass(i, name, icon) {
    this.setState({ addClass: i });
    sessionStorage.setItem("selectedMenu", name);
    sessionStorage.setItem("selectedMenuIcon", icon);
  }
  signOut() {
    sessionStorage.removeItem("userLogin");
    sessionStorage.setItem("selectedMenu", "Overview");
    window.location.href = "#/admin";
    this.setState({ signOut: true });
  }

  render() {
    const menues = this.adminMenues.map((menu, i) => {
      return (
        <Link
          onClick={
            menu.name === "Sign Out"
              ? () => {
                  this.signOut();
                }
              : () => {}
          }
          key={i}
          to={menu.to}
        >
          <div
            className={
              this.state.addClass === i ||
              sessionStorage.getItem("selectedMenu") === menu.name
                ? "menu-active menu"
                : "menu"
            }
            index={i}
            onClick={() => this.toggleClass(i, menu.name, menu.icon)}
          >
            <span className="menuIcon">
              <FontAwesomeIcon icon={menu.icon} />
            </span>
            <span className="menuText">{menu.name}</span>
          </div>
        </Link>
      );
    });

    return (
      <div
        id={
          this.props.location.pathname.includes("/admin") && !this.state.signOut
            ? "sidebar"
            : "hide-sidebar"
        }
      >
        <div className="sidebar-content">{menues}</div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
