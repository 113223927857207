import React from "react";
import Main from "./components/main";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
function App() {
  sessionStorage.removeItem('clientInfo');
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;
