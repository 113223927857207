import React, { Component } from "react";
import settings from "../../../settings.json";
import "./coupons.scss";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-bootstrap-daterangepicker";
import {
  faTrashAlt,
  faPaperPlane,
  faSortUp,
  faSortDown,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      domain:
        window.location.hostname !== "localhost"
          ? window.location.hostname
          : settings.domain,
      protocol: settings.protocol,
      companyEmail: settings.fromEmail,
      companyName: settings.customer,
    };
    this.state = {
      updateState: false,
      companyEmail: "",
      companyName: "",
      companyAddress: "",
      companyCity: "",
      companyVat: "",
      companyPhone: "",
      companyFacebook: "",
      companyInstagram: "",
      companyBooking: "",
      companyAirbnb: "",
      cabins: [],
    };
    this.coupons = [];
    this.axios = require("axios");
    this.getCoupons();
    this.getSettings();
    this.getCabins();
  }
  getSettings() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getSettings.php"
      )
      .then((response) => {
        if(response.data.length > 0){
          this.setState({
            companyEmail: response.data[0].email,
            companyName: response.data[0].name,
            companyAddress: response.data[0].adress,
            companyCity: response.data[0].city,
            companyPhone: response.data[0].phone,
            companyVat: response.data[0].vatnr,
            companyBooking: response.data[0].booking_com,
            companyAirbnb: response.data[0].airbnb_com,
          });
        }
      });
  }
  getCoupons() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCoupons.php"
      )
      .then((response) => {
        if(response.data.length > 0){
          this.coupons = response.data;
        }
        this.setState({ updateState: this.state.updateState ? false : true });
      });
  }
  updateCoupons(e, i, define) {
    switch (define) {
      case "company":
        this.coupons[i].company = e.currentTarget.innerText;
        break;
      case "email":
        this.coupons[i].email = e.currentTarget.innerText;
        break;
      case "endDate":
        this.coupons[i].endDate = e;
        this.setState({ updateState: this.state.updateState ? false : true });
        break;
      case "startDate":
        this.coupons[i].startDate = e;
        this.setState({ updateState: this.state.updateState ? false : true });
        break;
      case "maxUse":
        this.coupons[i].maxUse = e.currentTarget.innerText;
        break;
      case "discount":
        this.coupons[i].discount = e.currentTarget.innerText;
        break;
      case "coupon":
        this.coupons[i].coupon = e.currentTarget.innerText;
        break;
      default:
    }
  }

  save() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/updateCoupons.php",
        this.coupons
      )
      .then((response) => {
        if (response.data) {
          this.setState({ updateState: this.state.updateState ? false : true });
          this.getCoupons();
          alert("Coupons was successfully saved!");
        } else {
          alert("Saved failed");
        }
      });
  }

  send(i) {
    this.coupons[i].companyEmail = this.state.companyEmail;
    this.coupons[i].companyName = this.state.companyName;
    this.coupons[i].companyAddress = this.state.companyAddress;
    this.coupons[i].companyCity = this.state.companyCity;
    this.coupons[i].companyVat = this.state.companyVat;
    this.coupons[i].companyPhone = this.state.companyPhone;
    this.coupons[i].companyFacebook = this.state.companyFacebook;
    this.coupons[i].companyInstagram = this.state.companyInstagram;
    this.coupons[i].companyBooking = this.state.companyBooking;
    this.coupons[i].companyAirbnb = this.state.companyAirbnb;
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/sendCoupons.php",
        this.coupons[i]
      )
      .then((response) => {
        if (response.data) {
          alert("Send was successfully!");
        } else {
          alert("Send failed");
        }
      });
  }

  delete(i) {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/deleteCoupons.php",
        this.coupons[i]
      )
      .then((response) => {
        if (response.data) {
          this.coupons.splice(i, 1);
          this.setState({ updateState: this.state.updateState ? false : true });
          alert("Delete was successfull!");
        } else {
          alert("Delete failed");
        }
      });
  }

  // addNewCoupon() {
  //   const d = new Date();
  //   const startDate =
  //     d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();

  //   var newCoupon = {
  //     company: "",
  //     email: "",
  //     endDate: "",
  //     startDate: startDate,
  //     maxUse: "",
  //     discount: "15",
  //     new: true,
  //     coupon: Math.random().toString(36).slice(-8),
  //   };
  //   this.coupons.push(newCoupon);
  //   this.setState({ updateState: this.state.updateState ? false : true });
  // }
  getCabins() {
    this.axios
      .post(
        "" +
          this.settings.protocol +
          this.settings.domain +
          "/static/php/getCabins.php"
      )
      .then((response) => {
        var userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
        var data = [];
      
        if(response.data.length > 0){
          if (userLogin.some((x) => x.role === "user")) {
            data = response.data.filter((a) =>
              userLogin.some((c) => c.cabinId === a.id)
            );
          } else {
            data = response.data;
          }
        }
        this.setState({
          cabins: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  openList(index) {
    this.state.activeList !== index
      ? this.setState({ activeList: index })
      : this.setState({ activeList: -1 });
  }
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  addCoupon(id) {
    const d = new Date();
    const startDate =
      d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();

    var newCoupon = {
      company: "",
      email: "",
      endDate: "",
      startDate: startDate,
      maxUse: "",
      discount: "15",
      new: true,
      cabinId: id,
      coupon: Math.random().toString(36).slice(-8),
    };
    this.coupons.push(newCoupon);

    this.setState({});
  }
  render() {
    var cabins = this.state.cabins.map((cabin, index) => {
      var list = null;
      list = (
        <div
          className={
            this.state.activeList === index
              ? "active-list list-content"
              : "list-content"
          }
        >
          <div
            onClick={() => {
              this.addCoupon(cabin.id);
            }}
            className="add-offer"
          >
            <FontAwesomeIcon icon={faPlus} /> Add coupon
          </div>
          <table>
            <tbody>
              <tr>
                <th>Company Name</th>
                <th>Email</th>
                <th>Discount - max 100 %</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Max Use</th>
                <th>Coupons Used</th>
                <th>Coupon</th>
                <th>Action</th>
              </tr>
              {this.coupons.map((value, i) => {
                if (cabin.id === value.cabinId) {
                  return (
                    <tr key={i}>
                    <td>
                      <div
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onInput={(e) => {
                          this.updateCoupons(e, i, "company");
                        }}
                      >
                        {value.company}
                      </div>
                    </td>
                    <td>
                      <div
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onInput={(e) => {
                          this.updateCoupons(e, i, "email");
                        }}
                      >
                        {value.email}
                      </div>
                    </td>
                    <td>
                      <div
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onInput={(e) => {
                          var maxProcent =
                            e.currentTarget.innerText !== ""
                              ? JSON.parse(e.currentTarget.innerText) <= 100
                              : true;
                          if (maxProcent) {
                            this.updateCoupons(e, i, "discount");
                          } else {
                            alert("Max 100%");
                          }
                        }}
                      >
                        {value.discount}
                      </div>
                    </td>
                    <td>
                      <DatePicker
                        singleDatePicker={true}
                        autoApply={true}
                        startDate={
                          value.startDate ? new Date(value.startDate) : new Date()
                        }
                        locale={{ format: "D.M.YYYY" }}
                        onApply={(e, p) => {
                          const date =
                            p.startDate._d.getFullYear() +
                            "/" +
                            (p.startDate._d.getMonth() + 1) +
                            "/" +
                            p.startDate._d.getDate();
                          this.updateCoupons(date, i, "startDate");
                        }}
                      >
                        <div className="empty-endDate">{value.startDate}</div>
                      </DatePicker>
                    </td>
                    <td>
                      <DatePicker
                        singleDatePicker={true}
                        autoApply={true}
                        startDate={value.endDate ? new Date(value.endDate) : new Date()}
                        locale={{ format: "D.M.YYYY" }}
                        onApply={(e, p) => {
                          const date =
                            p.startDate._d.getFullYear() +
                            "/" +
                            (p.startDate._d.getMonth() + 1) +
                            "/" +
                            p.startDate._d.getDate();
                          this.updateCoupons(date, i, "endDate");
                        }}
                      >
                        <div
                          suppressContentEditableWarning={true}
                          contentEditable="true"
                          onInput={(e) => {
                            var dateFormat = "YYYY/M/D";
                            var valid = moment(
                              e.currentTarget.innerText,
                              dateFormat,
                              true
                            ).isValid();
          
                            if (valid || e.currentTarget.innerText === "") {
                              var date = "";
                              if (e.currentTarget.innerText !== "") {
                                const d = e.currentTarget.innerText
                                  ? new Date(e.currentTarget.innerText)
                                  : "";
                                date =
                                  d.getFullYear() +
                                  "/" +
                                  (d.getMonth() + 1) +
                                  "/" +
                                  d.getDate();
                              }
                              this.updateCoupons(date, i, "endDate");
                            }
                          }}
                          className="empty-endDate"
                        >
                          {value.endDate}
                        </div>
                      </DatePicker>
                    </td>
                    <td>
                      <div
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onInput={(e) => {
                          this.updateCoupons(e, i, "maxUse");
                        }}
                      >
                        {value.maxUse}
                      </div>
                    </td>
                    <td>{value.usedCoupon}</td>
                    <td>
                    <div
                        suppressContentEditableWarning={true}
                        contentEditable="true"
                        onInput={(e) => {
                          this.updateCoupons(e, i, "coupon");
                        }}
                      >
                        {value.coupon}
                      </div></td>
                    <td>
                      <div
                        className="action-btn"
                        onClick={() => {
                          this.send(i);
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        <p>Send coupon</p>
                      </div>
                      <div
                        className="action-btn"
                        onClick={() => {
                          this.delete(i);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <p>Delete</p>
                      </div>
                    </td>
                  </tr>
                  );
                } else {
                  return false;
                }
              })}
            </tbody>
          </table>
        </div>
      );
      return (
        <li key={index}>
          <div
            className="list"
            onClick={() => {
              this.openList(index);
            }}
          >
            {cabin.cabinName}
            <div className="list-icon">
              <FontAwesomeIcon
                icon={this.state.activeList === index ? faSortUp : faSortDown}
              />
            </div>
          </div>
          {list}
        </li>
      );
    });
   

    return (
      <content id="coupons">
        <div className="wrap-content">
          <div
            onClick={() => {
              this.save();
            }}
            className="save-btn"
          >
            <div>Save</div>
          </div>
          <div className="front-page-content">
            <div className="headline">
              <h3>Coupons</h3>
              {/* <div className="add">
                <FontAwesomeIcon
                  onClick={() => {
                    this.addNewCoupon();
                  }}
                  icon={faPlusCircle}
                />
                <span>Add coupons</span>
              </div> */}
            </div>
            {/* <table>
              <tbody>
                <tr>
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Discount - max 100 %</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Max Use</th>
                  <th>Coupons Used</th>
                  <th>Coupon</th>
                  <th>Action</th>
                </tr>
                {coupon}
              </tbody>
            </table> */}
            <ul>{cabins}</ul>
          </div>
        </div>
      </content>
    );
  }
}

export default withRouter(Coupons);
